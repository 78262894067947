
import apiDataStore from '../../store/apiDataStore'
import alertStore from '../../store/AlertStore'

//  Обязательное поле: url
//  Для формирования имени документа в архиве используются(нужно хотя бы одно из полей) cadNum, date, type, name, id

export default async function (docsList) {
  // изменяет оригинальный объект
  // console.log(docsList)
  var JSZip = require('jszip')
  var JSZipUtils = require('jszip-utils')
  var FileSaver = require('file-saver')
  var zip = new JSZip()
  let count = 0
  let errors = []

  // составление имени файла
  docsList.forEach(function (item) {
    let filename = ''
    if (item.cadNum !== '' && item.cadNum !== undefined) {
      filename += item.cadNum.replace(/:/g, '-')
    }
    if (item.name !== '' && item.name !== undefined) {
      filename += '_' + item.name
    }
    if (item.type !== '' && item.type !== undefined) {
      filename += '_' + item.type.replace(/\s/g, '_')
    }
    if (item.date !== '' && item.date !== undefined) {
      filename += '_' + item.date.replace(/\./g, '-')
    }
    if (item.id !== '' && item.id !== undefined) {
      filename += '_' + item.id
    }
    filename += '.zip'
    // loading a file and add it in a zip file
    JSZipUtils.getBinaryContent(item.url.replace('http://', 'https://'), async function (err, data) {
    // JSZipUtils.getBinaryContent(item.url, async function (err, data) {
      if (err) {
        errors.push(err) // or handle the error
      }
      zip.file(filename, data, {binary: true})
      count++
      if (count === docsList.length) {  // если скачаны все файлы(или получили ошибку), то сохранить архив и поменять флаги загрузок
        let content = await zip.generateAsync({type: 'blob'})
        FileSaver.saveAs(content, generateZipName())
        alertStore.dispatch('showAlert', {type: 'success', title: 'Архивация прошла успешно', text: 'Скачивание начнется через несколько секунд'})
        apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageStateLandPlans', value: false})
        apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageStateUploads', value: false})
        apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageStateDocumentList', value: false})
      }
    })
  })
  count = 0
}

// function addSecurity (url) {
//   if (url.indexOf('https') === -1) {
//     url = url.replace('http', 'https')
//   }
//   // console.log(tmp)
//   return url
// }

function generateZipName () {
  let s = new Date()
  s = s.toString()
  let h = 0
  let l = s.length
  let i = 0
  if (l > 0) {
    while (i < l) {
      h = (h << 5) - h + s.charCodeAt(i++) | 0
    }
  }
  return 'RRD_' + h
}
