import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// alertStore.dispatch('showAlert', {type: '', title: '', text: '', code: 123456, buttonAccept: 'YES', buttonAbort: 'No', emitName: 'logOut'})
// type - sting. Может принимать значения error, success, fullscreen по умолчанию error (желательно даже при указании кода)
// title - string. Заголовок ошибки. По умолчанию "Не обработанная ошибка" (не обязательно при указании кода)
// text - string. Само сообщение ошибки. По умолчанию "Что-то пошло не так" (не обязательно при указании кода)

// code - integer. Код ошибки. Если передается, и данный код обработан, то
// поля title и text будут заменены на значения из списка. Если даного кода нет(список ниже),
// то к полям title и text применяются значения по умолчанию.

// buttonAccept - String надпись на кнопке, которая вурнет true
// buttonAbort - String надпись на кнопке, которая вернет false
// showAccept - boolean показывать кнопку подтверждения
// showAbort - boolean показывать кнопку отмены

// emitName - String Имя события, которое вызовется после нажатия кнопки buttonAccept или buttonAbort. В событии как раз и передается true или false. Не забыть слушать событие на
// элементе alert в App.vue
// emitParams - []. Передает в вызванное событие также массив параметров. Если не пусто, то передается объект с полями result=true/false(нажали да или нет) и params - массив с параметрами

// Список обработанных кодов :
// 400, 401, 404, 500, 502, 1(нет прав доступа), 2(это демо)

const store = new Vuex.Store({
  state: {
    alertsQueue: [],
    id: 0,
    delay: 6, // sec
    offer: false,
    maxAlertsCount: 3
    // поля объекта из alertsQueue
    // type: '',
    // alertTitle: '',
    // alertText: '',
    // showAlert: false,
    // statusCode: 0,
    // buttonAccept: '',
    // buttonAbort: '',
    // emitName: '',
    // timerId: null,
    // showAccept: true,
    // showAbort: true,
    // showtext: true,
    // timerWoking: false,
    // secondsConuter: 0
  },
  mutations: {
    addSecondsCounter (state, index) {
      state.alertsQueue[index].secondsConuter++
    },
    changeParamInQueue (state, indexKeyValue) {
      state.alertsQueue[indexKeyValue.index].indexKeyValue.key = indexKeyValue.value
    },
    setFields (state, errObj) {
      let obj = {}
      obj.type = errObj.type || 'error'
      obj.alertTitle = errObj.title || 'Не обработанная ошибка'
      obj.alertText = errObj.text || 'Что-то пошло не так.'
      obj.showAlert = true
      obj.statusCode = errObj.code || 0
      obj.buttonAccept = errObj.buttonAccept || 'Понятно'
      obj.buttonAbort = errObj.buttonAbort || 'Закрыть'
      obj.emitName = errObj.emitName || ''
      obj.emitParams = errObj.emitParams || []
      obj.showAbort = errObj.showAbort === undefined ? true : errObj.showAbort
      obj.showAccept = errObj.showAccept === undefined ? true : errObj.showAccept
      obj.showText = errObj.showText === undefined ? true : errObj.showText
      obj.timerWoking = false
      obj.secondsConuter = 0
      obj.timerId = null
      obj.alertId = ++state.id
      switch (errObj.code) {
        case undefined: {
          break
        }
        case 400: {
          obj.alertTitle = 'Ошибка аутентификации'
          obj.alertText = 'Проверьте правильность введенных почты и пароля, и попробуйте еще раз'
          break
        }
        case 401: {
          obj.alertTitle = 'Не авторизованный пользователь'
          obj.alertText = 'Повторите вход, используя свою почту и пароль'
          break
        }
        case 404: {
          obj.alertTitle = 'Путь не найден'
          obj.alertText = 'Путь до файла не найден. Действие не возможно'
          break
        }
        case 500: {
          obj.alertTitle = 'Неполадки'
          obj.alertText = 'Попробуйте обновить странцу или повторить операцию позже'
          break
        }
        case 502: {
          obj.alertTitle = 'Неполадки'
          obj.alertText = 'Попробуйте обновить странцу или повторить операцию позже'
          break
        }
        case 1: {
          obj.alertTitle = 'Нет прав доступа'
          obj.alertText = 'Сожалеем, но у вас нет доступа к этой функции'
          break
        }
        case 2: {
          obj.alertTitle = 'Необходима регистрация'
          obj.alertText = 'Данное действие доступно только для зарегистрированных пользователей'
          obj.type = 'fullscreen'
          obj.buttonAccept = 'Зарегистрироваться'
          obj.buttonAbort = 'Закрыть'
          obj.emitName = 'toRegistration'
          break
        }
        case 3: {
          obj.alertTitle = 'Уважаемые пользователи!'
          obj.alertText = 'Росреестр проводит технические работы. К сожлению, мы вынуждены приостановить сервис заказа.'
          break
        }
      }
      state.alertsQueue.push(obj)
    },
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    },
    startTimer (state, alert) {
      // let counter = 0
      alert.timerWoking = true
      alert.timerId = setInterval(() => {
        if (alert.timerWoking) { // пока что всегда тру, ибо нет остановки таймера по наведению мышки
          alert.secondsConuter++
        }
        // console.log(alert.secondsConuter)
        if (alert.secondsConuter >= state.delay) {
          clearInterval(alert.timerId)
          store.dispatch('deleteAlert', alert.alertId)
        }
      }, 1000)
    },
    deleteAlert (state, alertId) {
      for (let i = 0; i < state.alertsQueue.length; i++) {
        if (state.alertsQueue[i].alertId === alertId) {
          state.alertsQueue.splice(i, 1)
        }
      }
    },
    deleteAlertByIndex (state, alertIndex) {
      state.alertsQueue.splice(alertIndex, 1)
    }
    // freezeTimer (state, alertId) {
    //   for (let i = 0; i < state.alertsQueue.length; i++) {
    //     if (state.alertsQueue[i].alertId === alertId) {
    //       state.alertsQueue[i].timerWoking = false
    //       clearInterval(state.alertsQueue[i].timerId)
    //     }
    //   }
    // }
    // unFreezeTimer (state, alertId) {
    //   for (let i = 0; i < state.alertsQueue.length; i++) {
    //     if (state.alertsQueue[i].alertId === alertId) {
    //       state.alertsQueue[i].timerWoking = true
    //     }
    //   }
    // }
  },
  actions: {
    clearFields ({commit}) {
      commit('clrFields')
    },
    showAlert ({commit, state}, errObj) {
      if (errObj.type === 'fullscreen') { // если фуллскрин, то сразу в очередь
        commit('setFields', errObj)
      } else {  // если не фуллскрин, то пробовать добавить
        if (state.alertsQueue.length < state.maxAlertsCount) {  // добавление без задержки
          commit('setFields', errObj)
          commit('startTimer', state.alertsQueue[state.alertsQueue.length - 1])
        // } else {  // если не добавилось, то пробовать каждую секунду
        //   let addTimer = setInterval(() => {
        //     if (state.alertsQueue.length < state.maxAlertsCount) {
        //       commit('setFields', errObj)
        //       commit('startTimer', state.alertsQueue[state.alertsQueue.length - 1])
        //       clearInterval(addTimer)
        //     }
        //   }, 1000)
        // }
        } else {
          commit('deleteAlertByIndex', 0)
          setTimeout(() => {
            commit('setFields', errObj)
            commit('startTimer', state.alertsQueue[state.alertsQueue.length - 1])
          }, 200)
        }
      }
    },
    setValue ({commit}, keyValue) {
      commit('setValue', keyValue)
    },
    changeQueue ({commit}, indexKeyValue) {
      commit('changeParamInQueue', indexKeyValue)
    },
    addSecondsCounter ({commit}, index) {
      commit('addSecondsCounter', index)
    },
    deleteAlert ({commit}, alertId) {
      commit('deleteAlert', alertId)
    },
    deleteAlertByIndex ({commit}, alertIndex) {
      commit('deleteAlertByIndex', alertIndex)
    }
    // freezeTimer ({commit}, alertId) {
    //   commit('freezeTimer', alertId)
    // },
    // unFreezeTimer ({commit, store}, alertId) {
    //   for (let i = 0; i < store.alertsQueue.length; i++) {
    //     if (store.alertsQueue[i].alertId === alertId) {
    //       commit('startTimer', store.alertsQueue[i])
    //     }
    //   }
    // }
  },
  getters: {
    queueParam: state => index => key => {
      return state.alertsQueue[index][key]
    },
    value: state => key => {
      return state[key]
    }
  }
})

export default store
