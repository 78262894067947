<template>
  <div class="main-block">
    <h4>Обратная связь</h4>
    <div class="body-block">
      <input type="text" class="feedback-title" required placeholder="Тема сообщения" v-model="title">
      <div class="for-center">
        <textarea name="feedback-text" required v-model="text" id="feedback-text" class="feedback-text" rows="13" placeholder="Текст сообщения"></textarea>
      </div>
    </div>
    <button class="button-close feedback-button-close" @click="closeFeedback()"><img src="../../assets/img/close-form.svg" alt="закрыть форму обратной связи"></button>    
    <button class="button button-primary feedback-button" @click="submit">Отправить</button>
  </div>
</template>

<script>
// storage facilities
import alertStore from '../../store/AlertStore'

export default {
  name: 'feedback',
  data () {
    return {
      showFeedbackFlag: false,
      title: '',
      text: ''
    }
  },
  computed: {

  },
  methods: {
    showFeedback () {
      this.showFeedbackFlag = true
    },
    closeFeedback () {
      this.$emit('closeFeedback')
    },
    submit () {
      if (this.title === '' || this.text === '') {
        alertStore.dispatch('showAlert', {type: 'error', title: 'Пустые поля', text: 'Одно или несколько полей остались пустыми. Пожалуйста, заполните их прежде чем отправить отчет'})
      } else {
        let messageData = {
          'title': this.title,
          'text': this.text
        }
        this.sendFeedbackMix(messageData)
        this.$emit('closeFeedback')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/varibles.scss";
.main-block {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  background-color: white;
  z-index: 105;
  padding: 30px;
  margin-left: -190px; //половина от ширины(минус padding и минус 60рх меню) для выравнивания по центру
  margin-top: -220px;
}
.for-center {
  text-align: center;
}
.feedback-title {
  width: 60%;
  margin: 5px 0 20px 0;
  height: 35px;
  background-color: $color-light;
  padding: 6px;
  outline: none;
  font-size: 12pt;
  border-radius: 2px;
}
.feedback-text {
  width: 100%;
  outline: none;
  background-color: $color-light;
  border-radius: 2px;
  resize: none;
  padding: 5px;
  height: 273px;
}
.for-center textarea {
  min-width: 0;
}
.feedback-button {
  float: right;
  margin-right: 0;
  // margin-bottom: 0;
}
.feedback-button-close {
  position: absolute;
  top: 30px;
  right: 30px;
}
</style>
