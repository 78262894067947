import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    // host: 'http://192.168.2.232:8000',  // dev
    // host: 'http://192.168.2.240:8010',  // dev
    // host: 'https://api.rrdoc.ru',  // prod
    // host: 'http://192.168.2.183:81',  // dev
    // host: 'http://api.rrdoc.itt',  // dev
    host: '',

    api: '/api/v1',
    gkn: '/me/gkn',
    me: '/me',
    login: '/rest-auth/login/',
    billing: '/api/v1/billing',
    porcessDetail: '/api/v1/me/package_processing/package_processes/',
    getPrice: '/billing/price/?operation=',
    resetPassword: '/rest-auth/password/reset/',
    resetPasswordConfirm: '/rest-auth/password/reset/confirm/',
    changePassword: '/rest-auth/password/change/',
    getActionsForCurrentDoc: '/api/v1/me/package_processing/actions/?type=',
    createProces: '/api/v1/me/package_processing/processes/',
    getDocumentsTypes: '/api/v1/me/document-groups/',
    checkHasMoneyForOrder: '/api/v1/billing/check-fullness-balance/order/',
    createOrder: '/api/v1/me/ordering/',
    downloadDoc: '/api/v1/me/documents/',
    downloadSelected: '/api/v1/me/documents/jump_docs/',
    downloadAll: '/api/v1/me/documents/jump_all_docs/',
    uploadDoc: '/api/v1/me/documents/',
    registration: '/rest-auth/registration/',
    fillUpBalance: '/api/v1/billing/fill-up/',
    getProjectsNames: '/api/v1/me/projects/?search=',
    getOrderData: '/api/v1/me/orders/?limit=', // &search=SomeSearch&status_list=statusId
    getDKI: '/api/v1/me/dci/',
    sendFeedback: '/api/v1/feedback/feed/',
    testServer: '/health/',
    commonGkn: '/gkn',
    verifyToken: '/rest-auth/api-token-verify/',
    getUploaded: '/api/v1/me/uploads/',
    auth: '/rest-auth',
    subs: {
      colleagues: '/colleagues',
      purposes: '/purposes',
      requests: '/requests'
    },
    user: '/rest-auth/user/',
    tariffs: '/api/v1/tariffs/',
    updateCompany: '/api/v1/update_company/',
    companyEmployees: '/api/v1/company_employees/',
    companyEmployee: '/api/v1/company_employee/',
    getCurrentPermissions: '/api/v1/get_current_permissions/',
    updateTariff: '/api/v1/update_tariff/',
    unblockTariff: '/api/v1/unblock_tariff/',
    addUserToCompany: '/api/v1/add_user_to_company/',
    deleteUserFromCompany: '/api/v1/delete_user_from_company/',
    getRegularPaymentPrice: '/api/v1/billing/get_regular_payment_price/',
    setEmployeePermissions: '/api/v1/set_employee_permissions/',
    getBonuses: '/api/v1/get_bonuses/',
    exitFromCompany: '/api/v1/exit_from_company/',

    settings: '/api/v1/settings/',

    notificationGetAccessKey: '/api/v1/notification/get-access-token/',
    notificationWebSocket: 'wss://app.blinkcast.xyz/ws/'
  },
  getters: {
    host: (state) => {
      return state.host
    },
    getActionsForCurrentDoc: (state) => {
      return state.getActionsForCurrentDoc
    },
    value: state => key => {
      return state[key]
    }
  }
})

export default store
