import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    feedback: false,
    // authError: false,
    currentRoute: '/',
    currentRouteName: 'documentList',
    currentTitle: 'Мои документы',
    currentHeadTitle: 'Мои документы',
    requestWindow: false,
    authorized: false,
    loginLoading: false,
    showProfileFlag: false,
    signIn: true,
    serverAlive: true,  // устанавливает начальное значение при создании прилоджения в main.js
    activeIcon: { // выбранная иконка в меню
      type: 'documents',
      id: 1
      // documents 1, shop 4, landPlans 6, uploaded 5, profile 8
    },
    disableBillingFlag: false, //  changeing in main.js disableBilling if true
    rememberPrevLogin: '', // введенный пользователем логин. Нужнен, т.к. при неправльных данных входа компонент пересоздается и чистит поля(в т.ч. введенный логин), но при этом хранилище все помнит
    flagRequestData: '', // переменная изменение которой приводит к запросу данных
    userDataLoading: false // флаг загрузки данных о пользователе(при семен страницы, логине, обновлении). есть во всех компонентах(в теории).
  },
  mutations: {
    changeShowProfileFlag (state, value) {
      state.showProfileFlag = value
    },
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    }
  },
  actions: {
    changeShowProfileFlag ({commit}, value) {
      commit('changeShowProfileFlag', value)
    },
  //  установить пару ключ: значение (keyValue - {key: 'key', value: 'value'})
    setValue ({commit}, keyValue) {
      commit('setValue', keyValue)
    }
  },
  getters: {
    value: state => key => {
      return state[key]
    }
  }
})
export default store
