<template>
  <div class="download-selected-component">
    <div @click="getTypesActions">
      <slot></slot>
    </div>
    <transition name="fade-height">
      <div class="download-selected-component__actions-wrapper" v-if="!loadingActions && showActions" v-click-outside="onClickOutside">
        <div class="download-selected-component__action" @click="downloadSelectedSource">
          оригинал
        </div>
        <div class="download-selected-component__action" v-for="(action, actionIndex) in actions" :key="'action_' + actionIndex" @click="downloadAllSelectedDocsByType(action)">
          {{ action.name }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import httpStore from '../../../store/httpStore'
import alertStore from '../../../store/AlertStore'
import apiDataStore from '../../../store/apiDataStore'
import userStore from '../../../store/userStore'
import aca from '../../Utils/autocompillerArchive'

export default {
  name: 'download-selected',
  props: ['selected'],
  data () {
    return {
      loadingActions: false,
      actions: [],
      showActions: false
    }
  },

  computed: {
    demoModeFlag () {
      return userStore.getters.value('demoMode')
    }
  },
  methods: {
    onClickOutside () {
      this.setShowActions(false)
    },

    setShowActions (val) {
      this.showActions = val
    },
  
    async getTypesActions () {
      if (this.showActions) { // если открыто - закрыть
        this.setShowActions(!this.showActions)
        return
      }
      if (this.selected.length > 0) {
        this.setShowActions(!this.showActions)
        this.loadingActions = true
        apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageStateDocumentList', value: true})
  
        let _docTypes = [] // список id типов
        this.selected.forEach(doc => {
          if (!_docTypes.includes(doc.type_id)) {
            _docTypes.push(doc.type_id)
          }
        })
        this.actions = await this.getUniversalActionsForTypesIdListMix(_docTypes)
        this.loadingActions = false
        apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageStateDocumentList', value: false})
      }
    },

    downloadAllSelectedDocsByType (action) { // скачивание выделенных обработанных файлов(печать/mapinfo и т.п.)
      if (this.selected.length === 0) {
        return
      }
      if (this.demoModeFlag) { // если демо мод
        alertStore.dispatch('setValue', {key: 'offer', value: true})
        return
      }
      this.setShowActions(false) // закрыть окно скачивания
      apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageStateDocumentList', value: true})
      let _selected = JSON.parse(JSON.stringify(this.selected)) // копия, чтобы если во время скачивания выберут другие файлы это никак не сказалось на результате
      alertStore.dispatch('showAlert', {type: 'warning', title: 'Предупреждение', text: 'Файлы, обработка которых не была завершена, или закончилась с ошибкой, скачаны не будут'})
      let actionStatuses = [] // массив для создания архива
      this.selected.forEach(item => {
        let _url = httpStore.getters.host + httpStore.getters.value('porcessDetail') + item.id + '?operation_code=' + action.code
        actionStatuses.push(this.$http.get(_url))
      })
      Promise.all(actionStatuses).then(result => {
        let resultArr = result.map((item, index) => {
          return {
            url: item.body.result && item.body.result.status.code === 200 ? item.body.result.file : null,
            cadNum: _selected[index].cadNumber,
            date: _selected[index].date_formation,
            type: _selected[index].type_short_name,
            id: _selected[index].id
          }
        })
        resultArr = resultArr.filter(item => {
          return item.url !== null && item.url !== undefined
        })
        aca(resultArr)
        apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageStateDocumentList', value: false})
      }).catch(err => {
        apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageStateDocumentList', value: false})
        console.error('fail get action statuses', err)
      })
    },

    downloadSelectedSource: async function () { // скачивание выделенных оригинальных файлов
      this.setShowActions(false) // закрыть окно скачивания
      if (this.demoModeFlag) {
        alertStore.dispatch('setValue', {key: 'offer', value: true})
      } else {
        let arr = await this.createListToArhcive()
        if (arr !== null) {
          apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageStateDocumentList', value: true})
          aca(arr)
        }
      }
    },

    // возвращает массив который моэно отправить на архивацию
    createListToArhcive: async function () {
      let urls = []
      for (let i in this.selected) {
        // let doc = this.getDocByIdMix(this.rows, this.selected[i].id)
        let doc = this.selected[i]
        console.log(doc)
        let docUrl = await this.getDocUrlsMix(doc)
        let item = {
          cadNum: doc.cadNumber,
          date: doc.date_formation,
          type: doc.type_short_name,
          id: doc.id,
          url: docUrl['source']
        }
        urls.push(item)
      }
      return (urls.length > 0 ? urls : null)
    },
  }
}
</script>

<style lang="scss">
.download-selected-component {
  &__actions-wrapper {
    overflow: hidden;
    max-height: 700px;
    position: absolute;
    background-color: white;
    box-shadow: 0px 2px 19px 2px rgba(34, 60, 80, 0.2);
  }

  &__action {
    cursor: pointer;
    padding: 10px 20px;
    transition: all 0.3s;
  }
  &__action:hover {
    background-color: gray;
  }
}
.fade-height-enter-active, .fade-height-leave-active {
  transition: max-height .5s;
}
.fade-height-enter, .fade-height-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  max-height: 0;
}
</style>
