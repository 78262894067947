<template>
  <div class="main-block-new">
    <div class="page-block">
      
      <!-- <div class="tarrifs-info-block button-add-balance" @click="goToTariffs()" v-if="isBoss"><p>Тарифы</p></div> -->
      <div class="balance-info button-add-balance" @click="showPopupBlock()" title="Пополнить баланс"><p> <img src="~@/assets/img/wallet.svg" class="wallet-icon" alt=""> Баланс {{amountBalance | money}} &#8381;</p></div>
      <!-- конопка пополнить -->
      <!-- <button v-if="havePermissionFillUpBalanceLocal" class="button button-primary " >Пополнить</button> -->
    </div>
    <!-- end page element -->
    <!-- popup window -->
    <div class="popup-add-balance" v-if="showPopupBalanceAddFlag">
      <h5>Пополнение баланса</h5>
      <!-- Временно закомментил для блокировки оплаты -->
      <!-- <div class="popup-form">
        <p class="balance-fill-price-p">Баланс: <b>{{amountBalance | money}} &#8381;</b></p>
        <p class="balance-fill-price-p" v-if="tariffName !== 'base' && isBoss">Ежедневное списание по тарифу: <b>{{regularPaymentPriceInfo | money}} &#8381;</b></p>

        <p class="balance-fill-price-p" v-if="bonusesInfo.length !== 0">Ваши бонусы:</p>
        <div >
          <div v-for="(bonus, index) in bonusesInfo" :key="index">
            <span><div class="point"></div></span>
            <p class="bunuses-info-item">
              {{bonus.permission.human_name}}: <span>{{bonus.total}}</span>
            </p>
          </div>
        </div>

        <p class="balance-fill-price-p" v-show="appSettings.SHOW_PRISES">Стоимость выписки: <b>{{orderPrice}} &#8381;</b></p>
      </div>
      <div class="user-input-block">
        <input v-bind:value="userInput" v-on:keyup="editInput($event)" v-if="havePermissionFillUpBalanceLocal" placeholder="Введите сумму" name="fillUpBalance" id="fillUpBalance" class="fill-up-balance input">
      </div>
      <div class="fill-up-button-block">
          <button class="button button-primary" @click="submit()" v-if="havePermissionFillUpBalanceLocal" :class="{'newOrderButDisabled': userInput < 1}" :disabled="userInput < 1">Пополнить</button>
      </div>  -->
      <button class="button-close balance-fill-button-close" @click="closePopupBlock"><img src="../../../assets/img/close-form.svg" alt="закрыть окно пополнения баланса"></button>
      <!-- <div class="entity">
        <p class='entity-text'>
          Юридическим лицам: <br>
          Для оформления договора на использование сервиса RRDoc и выставления счета свяжитесь с нами по почте<br>
          <span @click="copyEmail()" style="color: black">inbox@rrdoc.ru <img class="entity-copy-img" src="../../../assets/img/copy.svg" alt="копировать email для связи"></span>
        </p>
      </div> -->

      <!-- временная затычка пока оплата не доступна -->
      <span>
Оплата онлайн временно не доступна.<br>
<br>
<b>Если Вам необходимо внести деньги на счет сейчас, пожалуйста, напишите на почту inbox@rrdoc.ru с указанием:</b> <br><br>
- email адреса, который используете для входа в RRDoc<br>
- ФИО<br>
- Сумма<br>
<br>
Ответным сообщением вы получите QR код для оплаты. <br>
<br>
Спасибо! 
      </span>
    </div>
    <!-- end popup window -->
    <div class="close-popup-overlay" @click="closePopupBlock" v-if="showPopupBalanceAddFlag"></div>
  </div>
</template>

<script>
// storage facilities
import userStore from '../../../store/userStore'
import profileStore from '../../../store/profileStore'
import alertStore from '../../../store/AlertStore'
import balanceStore from '../../../store/BalanceStore'
import settingsStore from '../../../store/settingsStore'

export default {
  name: 'balance',
  data () {
    return {
      // showPopupBalanceAddFlag: false,
      userSummForFillUp: 0,
      userInput: null,  // сумма что вводит пользователь
      moneyPerDay: 0,
      timerAlertMaxSumm: null,
      maxSumm: '10000'
    }
  },
  filters: {
    money (val) {
      let strVal = String(val)
      let afterDot = strVal.substring(strVal.indexOf('.') + 1)  // подстрока после точки
      if (afterDot === '00') {
        return strVal.substring(0, strVal.indexOf('.'))
      } else {
        return strVal
      }
    }
  },
  mounted () {
    this.getRegularPaymentPriceInfo()
  },
  computed: {
    showPopupBalanceAddFlag () {
      return balanceStore.getters.value('showPopupBalanceAddFlag')
    },
    appSettings () {
      return settingsStore.getters.settings
    },
    orderPrice () {
      return balanceStore.getters.price('order')
    },
    // resultPrice () {
    //   return Math.floor(this.userInput / this.orderPrice)
    // },
    regularPaymentPriceInfo () {
      return profileStore.getters.value('regularPaymentPriceInfo')
    },
    resultDays () {
      return Math.floor(this.userInput / this.regularPaymentPriceInfo)  // сумма для пополонения делить на ежедневное списание
    },
    userBalance () {
      return balanceStore.state
    },
    amountBalance () {
      return Number(balanceStore.getters.value('amountBalance')).toFixed(2)
    },
    havePermissionFillUpBalanceLocal () {
      return userStore.getters.permission('fill')
    },
    bonusesInfo () {
      return balanceStore.getters.value('bonusesInfo')
    },
    tariffName () {
      return userStore.getters.value('tariffName')
    },
    isBoss () {
      return userStore.getters.value('isBoss')
    }
  },
  methods: {
    requestFillUpBalanceInfo () {
      // mixins
      // this.getCurrentPermissionsInfo()
      // this.getBonusesInfo()
      // this.getRegularPaymentPriceInfo()
      // this.getUserInfo()
    },
    goToTariffs () {
      this.$router.push('/profile?scrollTo=tariff') // переход с параметром. Отслеживается в personalCabinet.vue
    },
    editInput (e) {
      let val = e.target.value  // значение ввода
      this.userInput = val
      this.userInput = val.replace(/[\D.,]/g, '') // убрать символы кроме цифр
      if (this.userInput.startsWith('0')) { //  если спамят ноль
        this.userInput = null
      }
      if (Number(val) > Number(this.maxSumm)) {  // если сумма ввода тольше положенной
        if (this.timerAlertMaxSumm === null) {  // таймер на алерты
          alertStore.dispatch('showAlert', {title: 'Ограничение суммы', text: 'Пополнение баланса более, чем на 10.000 запрещено'})
          this.timerAlertMaxSumm = setTimeout(() => {
            // clearTimeout(this.timerAlertMaxSumm)
            this.timerAlertMaxSumm = null
          }, 5000)
        }
        this.userInput = this.maxSumm
      }
    },
    closePopupBlock () {
      // this.showPopupBalanceAddFlag = false
      balanceStore.dispatch('setValue', {key: 'showPopupBalanceAddFlag', value: false})
    },
    showPopupBlock () {
      if (this.havePermissionFillUpBalanceLocal) {
        // this.showPopupBalanceAddFlag = true
        balanceStore.dispatch('setValue', {key: 'showPopupBalanceAddFlag', value: true})
      }
    },
    submit () {
      if (this.havePermissionFillUpBalanceLocal) {
        if (this.userInput !== '' && this.userInput !== '0' && this.userInput !== null && (Number)(this.userInput) > 0) {
          this.fillUpBalanceMix(this.userInput)
        } else {
          alertStore.dispatch('showAlert', {type: 'error', title: 'Баланс не пополнен', text: 'Введите сумму, на которую хотите пополнить счет'})
        }
      } else {
        alertStore.dispatch('showAlert', {type: 'error', code: 1})
      }
    },
    copyEmail () {
      navigator.clipboard.writeText('inbox@rrdoc.ru')
      .then(() => {
        alertStore.dispatch('showAlert', {type: 'success', title: 'Успешно', text: 'E-mail inbox@rrdoc.ru скопирован'})
      })
      .catch(err => {
        console.log('Something went wrong', err)
      })
    }
  }
}
</script>

<style lang='scss' scoped>
@import "../../../assets/sass/varibles.scss";
.main-block-new {
  // margin: 20px;
  margin-right: 20px;
  float:left;
  height: 50px;
  width: auto;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
// page element
.page-block {
  border-radius: 3px;
  width: auto;
  height: 50px;
  float:right;
  text-align: center;
  cursor: pointer;
}

// .button-add-balance {
//   // background-color: $color-first;
//   // color: $color-light;
//   // padding: 5px 7px;
//   // outline: none;
//   color: $color-light;
//   font-size: 12pt;
//   margin:0;
//   height: 100%;
//   float:right;
//   // margin-left: 20px;
//   // margin-right: 20px;
//   cursor: pointer;
// }
.button-add-balance:hover {
  // background-color: rgba($color: $color-first, $alpha: .8);
  background-color: rgba($color: $color-light, $alpha: .2);
}
.balance-info {
  min-width: 100px;
  float: left;
  margin-bottom: 0;
  padding: 12.5px 10px 0 10px;
  color: $color-light;
  height: 100%;
  // cursor: default;
}
// end page element
// popup window
.popup-add-balance {
  position: absolute;
  top: 50vh;
  left: 50vw;
  margin-top: -250px;
  margin-left: -230px;
  width: 480px;
  // height: 500px;
  background-color: white;
  z-index: 100;
  padding: 20px;
  padding-top:10px;
}
.close-popup-overlay {
  z-index: 99;
  background-color: $color-overlay;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.button-add-balance-popup {
  position: absolute;
  bottom: 20px;
  right: 120px;
}
.button-add-balance-popup :hover{
background-color: rgba($color: $color-first, $alpha: .8);  
}
.button-close-poopup {
  position: absolute;
  bottom: 20px;
  right: 30px;
}
.popup-form {
  margin-top: 20px;
  text-align: left;
  padding-left: 50px;
}
.popup-tariff-info {
  font-size: 12pt;
  font-weight: 600;
}
.popup-tariff {
  outline: none;
  font-size: 12pt;
  margin-left: 5px;
}
.popup-tariff-p {
  font-size: 12pt;
  float: left;
}
.popup-tariff option {
  padding: 50px;
}
// end popup window
// new style
// .main-block-new {
// }
.page-block-new {
  width: auto;
  height: 50px;
  margin: 0 20px;
  padding: 0 5px;
  border-radius: 3px;
  background-color: $color-second;
}
.page-block-new p {
  padding-top: 15px;
  color: $color-light;
  float: left;
  font-size: 11pt;
}
.button-add-balance-new {
  float: left;
  padding: 0px 5px;
  margin: 0 0 0 10px;
  height: 100%;
  color: $color-light;
  font-size: 12pt;
  cursor: pointer;
}
.button-add-balance-new:hover{
  background-color: rgba($color: $color-light, $alpha: .2);
}
.fill-up-balance {
  text-align: center;
  font-size: 12pt;
  width: 220px;
  // font-weight: 500;
}
.input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.newOrderButDisabled, .newOrderButDisabled:hover{
  background-color: rgba($color: #000000, $alpha: .5);
  cursor: default;
}
.balance-fill-price-p {
  font-size: 12pt;
  font-weight: 300;
}
.balance-fill-result-p {
  margin-top: 10px;
  font-size: 16pt;
}
.balance-fill-button-close {
  position: absolute;
  top:20px;
  right:20px;
}
.bunuses-info-item {
  padding-left: 20px;
}
.user-input-block {
  width: 100%;
  text-align: center;
}
.fill-up-button-block {
  width: 100%;
  text-align: center;
}
.tarrifs-info-block {
  @extend .balance-info;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.point {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid $color-first;
  float: left;
  margin-right: 20px;
  margin-top: 7px;
}
.entity {
  margin-top: 10px;
}
.entity-text {
  font-size: 11pt;
  color: gray;
  text-align: center;
}
.entity-text span {
  cursor: pointer;
}
.entity-copy-img {
  // margin-left: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.wallet-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  margin-top: -5px;
}
</style>