import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    perPage: 10,
    count: 0,
    pagesCount: 0,
    nextPage: '',
    prevPage: '',
    // multiDownloadPackageState: false,
    loadingUploaded: false, // загрузка документов в uploaded.vue
    rows: []
  },

  mutations: {
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    }
  },

  actions: {
    setValue ({commit}, keyValue) {
      commit('setValue', keyValue)
    }
  },
  getters: {
    value: state => key => {
      return state[key]
    }
  }
})

export default store
