import Vue from 'vue'

import httpStore from '../store/httpStore'
// import userStore from '../store/userStore'
import store from '../store/store'
import settingsStore from '../store/settingsStore'

export default {
  methods: {
    getSettingsFromApiAndSetToStore () {
      return new Promise(resolve => {
        if (store.getters.value('authorized')) { // если залогинен и не демо, то запросить настройки(если демо, то должно отработать окно мол зарегистрируйтесь)
          let _url = httpStore.getters.host + httpStore.getters.value('settings')
          Vue.http.get(_url).then(response => {
            let _orderIsActive = response.body.order_rosreestr !== undefined ? response.body.order_rosreestr : false
            if (typeof response.body.order_rosreestr === 'string') {
              _orderIsActive = _orderIsActive === 'yes'
            }
            console.log('_orderIsActive', _orderIsActive)
            settingsStore.dispatch('setValue', {key: 'ORDER_IS_ACTIVE', value: _orderIsActive})
            resolve(true)
          }).catch(err => {
            console.log('can\'t get app settings. Order is blocked', err)
            settingsStore.dispatch('setValue', {key: 'ORDER_IS_ACTIVE', value: false})
            resolve(false)
          })
        }
      })
    }
  }
}
