import alertStore from '../store/AlertStore'
import userStore from '../store/userStore'
import httpStore from '../store/httpStore'
import store from '../store/store'

export default {
  data () {
    return {
      hostURL: httpStore.state.host,
      urlGetAccessKey: httpStore.state.notificationGetAccessKey,
      webSocketUrl: httpStore.state.notificationWebSocket,
      setConnection: false,
      // timerId: null,
      ws: null,
      adBlockEnabled: false,
      adBlockChecked: false
    }
  },
  methods: {
    initNotificationWSConnection () {  // закоментил, ибо не работает и шлет 100500 ошибок
      // context.$http.get(context.hostURL + context.urlGetAccessKey).then((response) => {
      //   context.wsConnection(response.body.accessKey, context)
      // }).catch(err => {
      //   console.log(err)
      //   context.setConnection = false
      // })
    },
    wsConnection (accessKey, context) {
      var userGuid = userStore.getters.value('guid')
      // console.log('userguid', userGuid)
      if (userGuid !== '') {
        context.setConnection = true
        context.ws = new WebSocket(context.webSocketUrl + '?channel=' + userGuid + '&key=' + accessKey)
        context.ws.onerror = function (error) {
          context.setConnection = false
          console.log(error.status)
          // check AdBlock
          // console.log('context.adBlockChecked ' + context.adBlockChecked)
          // if (!context.adBlockChecked) {
          //   context.checkAdBlock(context)
          // }
          console.log('this conn', context.setConnection)
        }
        context.ws.onclose = function () {
          context.setConnection = false
          // context.initNotificationWSConnection(context)
          console.log('WS closed')
        }
        context.ws.addEventListener('message', function (e) {
          var msg = JSON.parse(e.data)
          console.log(msg)
          if (msg.code === 200) {
            alertStore.dispatch('showAlert', {type: 'success', title: msg.title, text: msg.text})
          } else {
            alertStore.dispatch('showAlert', {type: 'fail', title: msg.title, text: msg.text})
          }
        })
      }
    },
    runNotificationUpdateConnect () {
      var self = this
      setInterval(function () {
        // console.log('self conn', self.setConnection)
        if (self.setConnection === false && store.getters.value('authorized')) {
          self.initNotificationWSConnection(self)
        }
      }, 10000)
    },
    closeWSMix () {
      if (this.ws !== null) {
        this.ws.close()
      }
      this.ws = null
    }
    // checkAdBlock (context) {
    //   let testAd = document.createElement('div')
    //   testAd.innerHTML = '&nbsp;'
    //   testAd.className = 'adsbox'
    //   document.body.appendChild(testAd)
    //   window.setTimeout(function () {
    //     if (testAd.offsetHeight === 0) {
    //       // context.adBlockEnabled = true // scoope timeout не позволяет изменить флаг
    //       alertStore.dispatch('showAlert', {type: 'error', title: 'Блокировщик рекламы', text: 'RRDoc не размещает рекламу. Пожалуйста, отключите рекламу и обновите страницу для работы уведомлений'})
    //     }
    //     testAd.remove()
    //   }, 100)
    //   context.adBlockChecked = true
    // }
  }
}
