import documentStore from '../store/documentStore'
import alertStore from '../store/AlertStore'

export default {
  methods: {
    getTableData: function (url, contextQuery = '', exQueryString = '', onlyActual = '', onlyMy = '', limit = '10', offset = '0', linkOnPage) {
      let _url = ''
      if (linkOnPage === false) {
        _url = url
        _url += '?limit=' + limit
        _url += '&search=' + encodeURIComponent(contextQuery)
        _url += exQueryString
        _url += '&only_actual=' + onlyActual
        _url += '&only_my=' + onlyMy
        _url += '&offset=' + offset
      } else {
        _url = linkOnPage
      }

      documentStore.state.loading = true
      documentStore.state.error = false

      this.$http.get(_url).then(response => {
        documentStore.state.loading = false
        documentStore.state.rows = response.data.results
        documentStore.state.count = response.data.count
        documentStore.state.resultSearchCounter = response.data.result_search_counter
        documentStore.dispatch('setValue', {key: 'nextPage', value: response.data.next})
        documentStore.dispatch('setValue', {key: 'prevPage', value: response.data.previous})
        documentStore.state.pagesCount = Math.floor(response.data.count / parseInt(limit))
        if (response.data.count % limit !== 0) {
          documentStore.state.pagesCount++
        }
      }).catch(error => {
        this.handleError(error.status)
        documentStore.state.loading = false
        documentStore.dispatch('setValue', {key: 'rows', value: []})
        alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка', text: 'Не удалось получить список документов. Обновите страницу, или попробуйте позже.'})
      })
    },

    computePagesCount: function (count, perPage) {
      let _pages
      _pages = Math.floor(count / perPage)
      if (count % perPage !== 0) { _pages++ }

      return _pages
    },

    shareDocMix (item, place) {
      let _url = location.host
      switch (place) {
        case 'documentList': {
          _url += '/?number=' + encodeURIComponent(item.cadNumber)
          break
        }
        case 'landPlans': {
          _url += '/landPlans?number=' + encodeURIComponent(item.cadNumber)
          break
        }
        default:
          break
      }
      navigator.clipboard.writeText(_url)
      .then(() => {
        alertStore.dispatch('showAlert', {type: 'success', title: item.cadNumber, text: 'Ссылка на документ скопирована в буфер обмена'})
      })
      .catch(err => {
        console.log('Something went wrong', err)
      })
    }

  }
}
