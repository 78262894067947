<template>
  <div class="wrap">
    <doc-context-search v-if="!error"></doc-context-search>
    <doc-ex-search v-if="!error"></doc-ex-search>
    <h2 class="good-class">документы КПТ, выписки ЕГРН(кадастровый паспорт), выписки ЕГРН(кадастровая выписка)</h2>
    <div class="table-controllers">
        <div class="only-actual-checkbox-wrapp" title="Некоторые документы заказываются повторно для получения более актуальных данных. Выключите эту функицию, если хотите видеть устаревшие версии документов">
          <div class="checkbox only-actual-checkbox" @click="onlyActualChange(!onlyActual)">
            <span class="only-actual-span-checkbox">
              <div class="fill-checkbox" v-if="onlyActual"></div>
            </span>
            Только актуальные
          </div>
        </div>
      <div class="only-my-checkbox-wrapp" title="Включите эту функию, если хотите видеть только загруженные Вами документы(для тех, кто состоит в компании)">
        <div class="checkbox only-my-checkbox" @click="onlyMyChange(!onlyMy)">
          <span class="only-my-span-checkbox">
              <div class="fill-checkbox" v-if="onlyMy"></div>
          </span>
          Только мои
        </div>  
      </div>
      <button class="button button-primary view-unsearched" v-if="resultSearchCheck" v-on:click="openResultSearchWindow()">ненайденные ({{resultSearchCount}})</button>
      <document-upload></document-upload>
      <div class="download-button-wrap">
        <!-- v-on:click="downloadSelected()" -->
        <DownloadSelectedVue :selected="selected">
          <button class="button button-primary" title="Скачать выбранные документы" :class="{'download-button-disabled':this.selected.length == 0}" :disabled="this.selected.length == 0">
            <img v-if="multiDownloadPackageState" src="../../assets/img/spinner-white.svg" class="wait-download">
            <img v-else src="../../assets/img/list.svg" class="wait-download">
            Скачать
          </button>
        </DownloadSelectedVue>
      </div>
      <div class="actions-for-selected-button-wrapper">
        <actions-for-selected :selected="selected">
          <button class="button" title="Общие действия для выбанных документов"
            :class="{'button-disabled':this.selected.length == 0, 'button-primary': this.selected.length > 0}"
            :disabled="this.selected.length == 0">
            <img src="../../assets/img/spinner-white.svg" class="wait-download-actions-for-selected" v-if="loadingActionForSelected || loadingSendToProcessesListMix">
            <img src="../../assets/img/list.svg" class="wait-download-actions-for-selected" v-else>
            Действия
          </button>
        </actions-for-selected>
      </div>
      <!-- <div class="overlay-download overlay-popup" v-if="downloadPopupCheck" @click="downloadPopupCheck = false"></div> -->
    </div>
    <div class="spinner" v-if="loading || userDataLoading"><img src="../../assets/img/rrd_logo_test.svg" alt="загрузка таблицы документов росреестра"></div>
    <div class="table-wrapper" style="overflow-x:auto;" v-else-if="rows.length > 0">
      <table class="table doc-table">
        <tr class="table-row doc-table-row table-row-header">
          <th class="table-col doc-table-col">
            <span class="table-checkbox" @click="selectAllItems()">
              <div class="fill-checkbox" v-if="allItemsSelected"></div>
            </span>
          </th>
          <th class="table-col doc-table-col">
            Наименование
          </th>
          <th class="table-col doc-table-col">
           Тип
          </th>
          <th class="table-col doc-table-col">
            Реквизиты
          </th>
          <th class="table-col doc-table-col">
            Регион
          </th>
          <th class="table-col doc-table-col">
            Район
          </th>
          <th class="table-col doc-table-col">
            Примечание
          </th>
          <th class="table-col doc-table-col">
            Действия
          </th>
        </tr>
        <tr class="table-row doc-table-row" v-for="item in rows" :key="item.id" @click="checkingItem(item)" v-bind:class="{'table-row-public': !item.my}">
          <td class="table-col doc-table-col">
            <span class="table-checkbox">
              <div class="fill-checkbox" v-show="checkSelect(item)==true"></div>
            </span>
            <input type="checkbox" :value="item.id" v-model="selected" class="hidecheckbox">
          </td>
          <td class="table-col doc-table-col">
            {{ item.cadNumber }}
          </td>
          <td class="table-col doc-table-col" v-if="item.type !== null">
            {{ item.type_name}}
          </td>
          <td class="table-col doc-table-col" v-if="item.type === null">
            -
          </td>
          <td class="table-col doc-table-col">
            {{ item.registration_number }} от {{ item.date_formation }}
          </td>
          <td class="table-col doc-table-col">
            {{ item.region_code }}
          </td>
          <td class="table-col doc-table-col">
            {{ item.district_code }}
          </td>
          <td class="table-col doc-table-col">
            {{ item.tag }}
          </td>
          <td class="table-col doc-table-col table-col-actions">
            <button title="Скачать документ" class="button-round document-download-button" @click.stop="downloadDoc(item.id)">
              <img src="../../assets/img/download.svg">
            </button>
            <dialogWindow v-bind:item=item @downLoad="downloadDoc" @delDoc="deleteDoc" @setCurrentDoc="setCurrentDoc(item.id, item.cadNumber)"></dialogWindow>
            <button title="Поделиться ссылкой на документ(получатель ссылки должен состоять в одной группе в Вами)" class="button-round document-download-button" @click.stop="shareDoc(item)">
              <img src="../../assets/img/share.svg" alt="поделиться обработанным документом">
            </button>
          </td>
        </tr>
      </table>
    </div>
    <div class="table-no-results" v-else>
      <p>ДОКУМЕНТЫ НЕ НАЙДЕНЫ</p>
    </div>
    <div class="paginator-wrap">
      <div class="paginator-info-count" v-if="!error && count !== 0 && !loading"><p>Документов: <b>{{count | more_count}}</b></p></div>
      <div class="paginator-body" v-if="!error && count !== 0 && !loading && rightPageNumber > 1">
        <div id="paginatorNavigation">
          <button class="paginator-button" v-if="currentPage !== 1" v-on:click="changeCurrentPage('first')"><img src="../../assets/img/double-arrow-left.svg"></button>
          <button class="paginator-button" v-if="currentPage !== 1" v-on:click="changeCurrentPage('prev')"><img src="../../assets/img/arrow-left.svg"></button>

          <div class="page-selector">
            <span class="page-selector-item" @click="changeCurrentPage(item)" v-for="(item, index) in pagesList" :key="index" v-bind:class="{'selected-page': checkPage(item)}"> {{item}} </span>
          </div>

          <button class="paginator-button paginator-right" v-if="currentPage !== pages" v-on:click="changeCurrentPage('next')"><img src="../../assets/img/arrow-right.svg"></button>
          <!-- <button class="paginator-button paginator-double-right" v-if="currentPage !== pages" v-on:click="changeCurrentPage('last')"><img src="../../assets/img/double-arrow-right.svg"></button> -->
        </div>

        <div class="select-count-document-on-page">
          <p>Отобразить на странице</p>
          <select @change="changeLimit()" v-model="limitOnPage" class="input selector-count-documents">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
    </div>
    <order-component @closeOrder="closeOrder()" :listFlag="'unsearched'" v-if="resultSearchFlag" @refreshUnserched="refreshUnserched()"></order-component>
    <div class="overlay-result-search" v-if="resultSearchFlag" @click="closeOverlay()" ></div>
  </div>
</template>

<script>
    import moment from 'moment'

    // storage facilities
    import store from '../../store/store'
    import documentStore from '../../store/documentStore'
    import alertStore from '../../store/AlertStore'
    import orderStore from '../../store/orderStore'
    import apiDataStore from '../../store/apiDataStore'
    import httpStore from '../../store/httpStore'
    import settingsStore from '../../store/settingsStore'

    // components
    import DocContextSearch from './TableUtils/DocumentContextSearch'
    import DocExSearch from './TableUtils/DocumentExtendedSearch'
    import DocumentUpload from './DocumentUpload'
    import orderComponent from '../Ordering/OrderingUtils/Order'
    import dialogWindow from './TableUtils/DialogWindow'
    import ActionsForSelected from './TableUtils/ActionsForSelected'
    import DownloadSelectedVue from './TableUtils/DownloadSelectedVue'

    export default {
      name: 'DocumentList',
      components: {
        'doc-context-search': DocContextSearch,
        'doc-ex-search': DocExSearch,
        'order-component': orderComponent,
        'document-upload': DocumentUpload,
        'dialogWindow': dialogWindow,
        'actions-for-selected': ActionsForSelected,
        'DownloadSelectedVue': DownloadSelectedVue
      },
      data () {
        return {
          url: '/documents/',
          statusUrl: '/document_statuses/',
          urlDelete: 'delete_doc/',
          currentPage: 1,
          timeout: null,
          docData: null,
          statusList: [],
          uploadDoc: false,
          resultSearchFlag: false,
          currentDocId: null,
          currentDocName: null,
          columnsMenu: false,
          downloadMenu: false,
          contextMenu: [],
          selected: [],
          errDetails: '',
          moment: moment,
          resultSearchCheck: false,
          resultSearchCount: 0,
          allItemsSelected: false,
          // downloadPopupCheck: false,
          limitOnPage: 10,
          unsearchedList: [],
          showErrorMultiDownloadPackage: false
        }
      },
      watch: {
        resultSearchCounter: function (newState) {
          this.resultSearchCount = 0
          this.resultSearchCheck = false
          if (newState != null) {
            this.unsearchedList = []
            for (let key in newState) {
              if (newState[key] === 0 && key !== '') {
                this.resultSearchCount += 1
                this.resultSearchCheck = true
                if (!this.unsearchedList.includes(key)) {
                  this.unsearchedList.push(key)
                }
                orderStore.dispatch('setList', {name: 'unsearched', value: this.unsearchedList})
              }
            }
          } else {
            this.resultSearchCheck = false
          }
        },
        upldDoc: function (newState) {
          this.selected.splice(0, this.selected.length) // очистить список выбранных
          this.uploadDoc = newState
          if (newState === false) {
            this.currentPage = 1
            this.getTableData(this.baseUrl() + httpStore.state.me + this.url, this.query, this.exQueryString, this.onlyActual, this.onlyMy, this.limitOnPage, this.offset, false)
          }
        },
        resSearch: function (newState) {
          this.selected.splice(0, this.selected.length) // очистить список выбранных
          this.resultSearchFlag = newState
        },

        contextQuery: function () {
          this.selected.splice(0, this.selected.length) // очистить список выбранных
          this.currentPage = 1
          this.getTableData(this.baseUrl() + httpStore.state.me + this.url, this.query, this.exQueryString, this.onlyActual, this.onlyMy, this.limitOnPage, this.offset, false)
        },
        onlyActual () {
          this.selected.splice(0, this.selected.length) // очистить список выбранных
          this.currentPage = 1
          this.getTableData(this.baseUrl() + httpStore.state.me + this.url, this.query, this.exQueryString, this.onlyActual, this.onlyMy, this.limitOnPage, this.offset, false)
        },
        onlyMy () {
          this.selected.splice(0, this.selected.length) // очистить список выбранных
          this.currentPage = 1
          this.getTableData(this.baseUrl() + httpStore.state.me + this.url, this.query, this.exQueryString, this.onlyActual, this.onlyMy, this.limitOnPage, this.offset, false)
        },

        exQuery: function (newQuery) {
          let _queryString = ''

          if ((newQuery !== null) && (newQuery !== undefined)) {
            if ((newQuery.region !== null) && (newQuery.region !== undefined) && (newQuery.region !== '')) {
              _queryString += '&region_code=' + newQuery.region
            }

            if ((newQuery.status !== null) && (newQuery.status !== undefined) && (newQuery.status.length !== 0)) {
              _queryString += '&status='
              newQuery.status.forEach(item => {
                _queryString += item + ','
              })
              _queryString = _queryString.slice(0, -1)
            }

            if ((newQuery.type !== null) && (newQuery.type !== undefined) && (newQuery.type.length !== 0)) {
              _queryString += '&group='
              newQuery.type.forEach(item => {
                _queryString += item + ','
              })
              _queryString = _queryString.slice(0, -1)
            }

            if (newQuery.startDate !== null) {
              _queryString += '&start_date=' + newQuery.startDate
            }
            if (newQuery.endDate !== null) {
              _queryString += '&end_date=' + newQuery.endDate
            } else {
              let _endDate = new Date()
              _queryString += '&end_date=' + this.moment(_endDate).format('DD.MM.YYYY')
            }
          }

          this.selected.splice(0, this.selected.length) // очистить список выбранных
          this.currentPage = 1
          documentStore.dispatch('setValue', {key: 'exQueryString', value: _queryString})
          this.getTableData(this.baseUrl() + httpStore.state.me + this.url, this.query, this.exQueryString, this.onlyActual, this.onlyMy, this.limitOnPage, this.offset, false)
        }
      },
      filters: {
        more_count: function (val) {
          return (val >= 1000 ? 'более 1000' : val)
        }
      },
      computed: {
        loadingSendToProcessesListMix () {
          return apiDataStore.getters.value('loadingSendToProcessesListMix')
        },
        loadingActionForSelected () {
          return apiDataStore.getters.value('loadingActionForSelected')
        },
        appSettings () {
          return settingsStore.getters.settings
        },
        resultSearchCounter: function () {
          return documentStore.state.resultSearchCounter
        },
        contextQuery: function () {
          return documentStore.state.contextQuery
        },
        exQueryString () {
          return documentStore.getters.value('exQueryString')
        },
        exQuery: function () {  // объект при его изменении конфигурируется строка exQueryString которая отправляется на сервер
          return documentStore.state.exQuery
        },
        upldDoc: function () {
          return documentStore.state.upldDoc
        },
        resSearch: function () {
          return documentStore.state.resSearch
        },
        rows: function () {
          return documentStore.getters.value('rows')
        },
        columns: function () {
          return documentStore.state.columns
        },
        loading: function () {
          return documentStore.state.loading
        },
        error: function () {
          return documentStore.state.error
        },
        perPage: function () {
          return documentStore.state.perPage
        },
        pages: function () {
          return documentStore.state.pagesCount
        },
        count: function () {
          return documentStore.state.count
        },
        onlyActual () {
          return documentStore.state.onlyActual
        },
        onlyMy () {
          return documentStore.state.onlyMy
        },
        nextPage () {
          return documentStore.getters.value('nextPage')
        },
        prevPage () {
          return documentStore.getters.value('prevPage')
        },
        computeOffsetLastPage () {
          return this.count - (this.count % this.limitOnPage)
        },
        offset () {
          return (this.currentPage - 1) * this.limitOnPage
        },
        multiDownloadPackageState () {
          return apiDataStore.getters.value('multiDownloadPackageStateDocumentList')
        },
        query () {
          return documentStore.getters.value('contextQuery')
        },
        leftPageNumber () {
          let lp = (this.offset - 4 * this.limitOnPage) / this.limitOnPage
          return (lp > 0 ? lp : 1)
        },
        rightPageNumber () {
          let tmp1 = (this.offset + 5 * this.limitOnPage) / this.limitOnPage
          let tmp2 = this.count / this.limitOnPage
          let min = Math.min(tmp1, tmp2)
          if (tmp2 >= 10 && tmp1 < 10) {
            min = 10
          }
          return min
        },
        pagesList () {
          let pages = []
          for (let i = this.leftPageNumber; i <= Math.ceil(this.rightPageNumber); i++) {
            if (i < this.rightPageNumber) {
              pages.push(i + '')
            } else {
              pages.push(String(i))
            }
          }
          return pages
        },
        userDataLoading () {
          return store.getters.value('userDataLoading')
        }
      },
      async beforeMount () {
        await this.getSettingsFromApiAndSetToStore() // получение флага на заказ выписок(жив ли РР)
      },
      mounted: function () {
        // this.getUserInfo()
        // this.getCurrentPermissionsInfo()
        // this.getAllUserBillingInfoMix()
        store.state.activeIcon = {type: 'documents', id: 1}
      },

      methods: {
        shareDoc (item) {
          this.shareDocMix(item, 'documentList')
        },
        closeOverlay () {
          this.resultSearchFlag = false
          this.refreshUnserched()
        },
        refreshUnserched () { // при закрытии обновить хранилище, чтобы при открытии всегда были не найденные в элементе Order.vue
          orderStore.dispatch('setList', {name: 'unsearched', value: this.unsearchedList})
        },
        closeOrder () {
          this.resultSearchFlag = false
        },
        onlyActualChange (value) {
          documentStore.state.onlyActual = value
        },
        onlyMyChange (value) {
          documentStore.state.onlyMy = value
        },
        // close (val) {
        //   val = false
        // },
        checkSelect (item) {
          // проверяет наличие элемента в selected, если есть возвращает тру, если нет фолс(нужно для отображения галочек чекбокса)
          if (this.selected.indexOf(item) !== -1) {
            return true
          } else {
            return false
          }
        },

        selectAllItems () {
          // добавляет все документы с страницы в selected если allItemsSelected-фолс, иначе, "опустошает" массив selected
          if (!this.allItemsSelected) {
            this.allItemsSelected = !this.allItemsSelected
            this.rows.forEach(item => this.selected.push(item))
          } else {
            this.allItemsSelected = !this.allItemsSelected
            this.selected.splice(0, this.selected.length)
          }
        },
        checkingItem (item) {
          let pos = this.selected.indexOf(item)
          if (pos === -1) {
            this.selected.push(item)
          } else {
            this.selected.splice(pos, 1)
          }
        },

        onSelect: function (items) {
          this.selected = []
          items.forEach(item => {
            this.selected.push(item.id)
          })
        },

        setCurrentDoc: function (id, packageName) {
          this.currentDocId = id
          this.currentDocName = packageName
        },

        clearCurrentVals: function () {
          this.currentDocId = null
          this.currentDocName = null
        },

        // перед этим происходит setCurrentDoc (из DialogWindow.vue)
        downloadDoc: function (itemId) {
          this.downloadDocMix(itemId, 'private')
          this.clearCurrentVals()
        },

        downloadAll: function () {
          // this.downloadPopupCheck = false
          let _query = '?search=' + this.query + this.exQueryString + '&only_actual=' + this.onlyActual
          this.downloadAllMix(_query)
        },

        deleteDoc: function () {
          this.clearCurrentVals()
        },

        deleteDocs () {
          this.deleteDocsMix()
        },

        openUploadWindow: function () {
          this.uploadDoc = !this.uploadDoc
        },
        openResultSearchWindow: function () {
          if (this.appSettings.ORDER_IS_ACTIVE) {
            this.resultSearchFlag = true
            documentStore.dispatch('setResSearch', true)
          } else {
            alertStore.dispatch('showAlert', {type: 'error', code: 3})
          }
        },

        changeCurrentPage: function (page) {
          this.allItemsSelected = false
          this.selected.splice(0, this.selected.length)
          switch (page) {
            case 'next': {
              this.currentPage++
              this.getTableData(this.baseUrl() + httpStore.state.me + this.url, this.query, this.exQueryString, this.onlyActual, this.onlyMy, this.limitOnPage, this.offset, this.nextPage)
              break
            }
            case 'prev': {
              this.currentPage--
              this.getTableData(this.baseUrl() + httpStore.state.me + this.url, this.query, this.exQueryString, this.onlyActual, this.onlyMy, this.limitOnPage, this.offset, this.prevPage)
              break
            }
            case 'last': {
              this.currentPage = this.pages
              this.getTableData(this.baseUrl() + httpStore.state.me + this.url, this.query, this.exQueryString, this.onlyActual, this.onlyMy, this.limitOnPage, this.offset, false)
              break
            }
            case 'first': {
              this.currentPage = 1
              this.getTableData(this.baseUrl() + httpStore.state.me + this.url, this.query, this.exQueryString, this.onlyActual, this.onlyMy, this.limitOnPage, this.offset, false)
              break
            }
            default: {
              this.currentPage = Number(page.replace(',', ''))
              this.getTableData(this.baseUrl() + httpStore.state.me + this.url, this.query, this.exQueryString, this.onlyActual, this.onlyMy, this.limitOnPage, this.offset, false)
              break
            }
          }
        },

        getData: function () {
          this.getTableData(this.baseUrl() + httpStore.state.me + this.url, this.query, this.exQueryString, this.onlyActual, this.onlyMy, this.limitOnPage, this.offset, false)
        },

        changeLimit () {
          this.allItemsSelected = false // поменять флаг "выделить все"
          this.selected.splice(0, this.selected.length) // очистить список выбранных
          this.currentPage = 1
          this.getTableData(this.baseUrl() + httpStore.state.me + this.url, this.query, this.exQueryString, this.onlyActual, this.onlyMy, this.limitOnPage, this.offset, false)
        },
        checkPage (page) {  // проверка на совпадение страницы в списке с данной страницой
          page = Number(page.replace(',', ''))
          if (this.currentPage === page) {
            return true
          } else {
            return false
          }
        }
      }
    }
</script>
<style lang="scss" scoped>
@import "../../assets/sass/varibles.scss";
.wrap{
  position: relative;
  margin-bottom: 150px;
}
.icon-list-result{
  margin-top: -2px;
  margin-right: 4px;
}
.result-but-wrap{
  float: left;
}
.table-row {
  width: 100%;
}
.table{
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  margin: auto;
}
.table-row{
  border-bottom: 1px solid rgba($color: gray, $alpha: 0.3);
}
td {
  cursor: pointer;
}
th, td {
    text-align: left;
    padding: 10px;
}
.table-col-actions {
  min-width: 110px;
  text-align: left;
}
.paginator-body img {
  width: 12px;
  height: 12px;
}
.paginator-button{
  float: left;
  margin-right: 5px;
  margin-left: 5px;
  outline: none;
}
.paginator-text{
  margin-left: 5px;
  margin-right: 5px;
  float: left;
}
.paginator-info-count{
  float:left;
  margin-right: 20px;
}
.overlay-popup{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.download-popup{
  position: absolute;
  width: 210px;
  height: auto;
  top: 60px;
  right: 10px;
  background-color: white;
  box-shadow: 1px 1px 7px 1px rgba($color: #000000, $alpha: .2);
  z-index: 100;
}
.download-popup-elem{
  width: 100%;
  height: 50px;
  background-color: white;
  margin: 0;
  padding: 0;
  color: #333333;
  cursor: pointer;
  text-align: left;
  padding-left: 25px;
  padding-right: 25px;
}
.download-popup-elem:hover{
  background-color: $color-light;
}
.download-button-disabled, .download-button-disabled:hover {
  background-color: rgba($color: gray, $alpha: 0.5);
  color: white;
  cursor: default;
}
.download-popup-elem.disabled, .download-popup-elem.disabled:hover{
  // background-color: rgba($color: #000000, $alpha: 0.2);
  color: rgba($color: gray, $alpha: 0.5);
  background-color: white;
  cursor: default;
}
.download-button-wrap{
  float: right;
  width: auto;
  height: auto;
  position: relative;
}
.actions-for-selected-button-wrapper {
  float: right;
  width: auto;
  height: auto;
  position: relative;
}
.table-controllers{
  width: 96%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}
.view-unsearched{
  float: left;
}
.table-wrapper{
  width: 96%;
  margin: auto;
}
.table-no-results{
  width: 96%;
  margin: auto;
  height: auto;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
}
.table-no-results p {
  color: #333333;
  font-size: 24pt;
}
.table-checkbox {
  display: block;
  float: left;
  width: 15px;
  height: 15px;
  margin-top: 0px;
  margin-right: 15px;
  border: 1px solid rgba($color: $color-second, $alpha: 1);
  border-radius: 2px;
  cursor: pointer;
}
.fill-checkbox{
  width: 100%;
  height: 100%;
  background-color: $color-second;
}
.paginator-wrap{
  width: 96%;
  margin:auto;
  margin-top: 20px;
}
.overlay-result-search{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: $color-overlay;
}
.doc-table-row {
  background-color: rgba($color: $color-second, $alpha: .06);
}
.doc-table-row:hover {
  background-color: rgba($color: $color-second, $alpha: .1);
}
.table-row-public{
  background-color: white;
}
.table-row-public:hover {
  background-color: rgba($color: $color-light, $alpha: 1.0);
}
.table-row-header, .table-row-header:hover{
  background-color: white;
}
.only-my-checkbox {
  margin-left: 15px;
  margin-top: 16px;
  margin-right: 15px;
  margin-bottom: 0;
  float: left;
  height: auto;
  cursor: pointer;
}
.only-my-span-checkbox{
  display: block;
  float: left;
  width: 15px;
  height: 15px;
  margin-top: 2px;
  margin-right: 10px;
  border-radius: 2px;
  border: 1px solid rgba($color: $color-second, $alpha: 1);
}
.fill-checkbox{
  width: 100%;
  height: 100%;
  background-color: $color-second;
}
.only-my-checkbox-wrapp{
  float: left;
  margin: auto;
}
.only-actual-checkbox {
  margin-left: 11px;
  margin-top: 16px;
  margin-bottom: 0;
  float: left;
  height: auto;
  cursor: pointer;
}
.only-actual-span-checkbox{
  display: block;
  float: left;
  width: 15px;
  height: 15px;
  margin-top: 2px;
  margin-right: 10px;
  border-radius: 2px;
  border: 1px solid rgba($color: $color-second, $alpha: 1);
}
.fill-checkbox{
  width: 100%;
  height: 100%;
  background-color: $color-second;
}
.only-actual-checkbox-wrapp{
  float: left;
  margin: auto;
}
.download-button{
  height: 15px;
  width: 15px;
  margin-top: 7px;
  outline: none;
}
.status-item-block {
  height: 30px;
  width: 30px;
  outline: none;
  margin-left: 10px;
  border-radius: 49%;
  text-align: center;
}
.status-item-block-download:hover {
  border-radius: 49%;
  background-color: rgba($color: $color-first, $alpha: .1);
}
.spinner {
  width: 96%;
  margin: auto;
  height: auto;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
}
.spinner img {
  width: 400px;
  height: 400px;
}
.select-count-document-on-page {
  float: right;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 60px;
}
.selector-count-documents {
  width: 50px;
  // display: inline-block;
  margin-top: 0!important;
  font-weight: bolder;
}
.document-download-button {
  width: 30px;
  height: 30px;
  float: left;
  text-align: center;
}
.document-download-button:hover {
  background-color: rgba(39, 174, 96, .1);
}
.document-download-button img {
  padding: 7px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-selector {
  max-width: 500px;
  float: left;
}
.page-selector-item {
  cursor: pointer;
  padding-right: 5px;
}
.selected-page {
  font-weight: bold;
}
.wait-download-actions-for-selected {
  width: 20px;
  height: 20px;
}
</style>

