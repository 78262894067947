import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    username: '',
    email: '',
    isBoss: false,
    tariffName: '',
    tariffHumanName: '',
    tariffCompanyAllow: '',
    tariffIsActive: true,
    guid: '',
    company: '',
    currentPermissions: [],
    // havePermissionFillUpBalance: false,
    demoMode: false,
    secondTime: true
  },
  mutations: {
    clearStore (state) {
      // console.log('clearStore')
      state.username = ''
      state.email = ''
      state.isBoss = false
      state.tariffName = ''
      state.tariffHumanName = ''
      state.tariffCompanyAllow = ''
      state.tariffIsActive = true
      state.guid = ''
      state.company = ''
      state.currentPermissions = []
      // state.havePermissionFillUpBalance = false
      state.demoMode = false
      state.secondTime = true
    },
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    },
    setDemoMode (state, value) {
      state.demoMode = value
    }
  },
  actions: {
    clearStore ({commit}) {
      commit('clearStore')
    },
    setValue ({commit}, keyValue) {
      commit('setValue', keyValue)
    },
    setDemoMode ({commit}, value) {
      commit('setDemoMode', value)
    }
  },
  getters: {
    value: state => key => {
      return state[key]
    },
    permission: state => permissionName => {  // print, order, fill, owner, mapinfo, shape, geojson, kml
      for (let i in state.currentPermissions) {
        if (state.currentPermissions[i].name === permissionName) {
          return true
        }
      }
      return false
    }
  }
})

export default store
