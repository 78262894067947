<template>
  <div class="term-of-use-local">
    <h3>Пользовательское соглашение сервиса RRDoc</h3>
<p>
    Пользовательское соглашение сервиса RRDoc
</p>
<p>
    1. Общие положения
</p>
<p>
    1.1. ООО «АЙТИ-ТЕМАТИК» (далее — RRDoc) предлагает пользователю сети
    Интернет (далее – Пользователь) - использовать данный сервис на условиях,
    изложенных в настоящем Пользовательском соглашении (далее — «Соглашение»,
    «ПС»). Соглашение вступает в силу с момента выражения Пользователем
    согласия с его условиями в порядке, предусмотренном п. 1.3 Соглашения.
    <br/>
    1.2. Использование сервиса RRDoc регулируется настоящим Соглашением.
    Соглашение может быть изменено RRDoc без какого-либо специального
    уведомления, новая редакция Соглашения вступает в силу с момента ее
    размещения в сети Интернет по указанному в настоящем абзаце адресу, если
    иное не предусмотрено новой редакцией Соглашения. Действующая редакция ПС
    всегда находится на странице по адресу
    <a href="https://rrdoc.ru/terms-of-use" target="_blank">
        https://rrdoc.ru/terms-of-use
    </a>
</p>
<p>
    1.3. Пройдя процедуру регистрации, Пользователь считается принявшим условия
    Соглашения в полном объеме, без всяких оговорок и исключений. В случае
    несогласия Пользователя с какими-либо из положений Соглашения, Пользователь
    не в праве использовать сервис RRDoc. В случае если RRDoc были внесены
    какие-либо изменения в Соглашение в порядке, предусмотренном пунктом 1.2
    Соглашения, с которыми Пользователь не согласен, он обязан прекратить
    использование сервиса RRDoc.
    <br/>
    <br/>
</p>
<p>
    2. Регистрация Пользователя. Учетная запись Пользователя
</p>
<p>
    2.1. Для того чтобы воспользоваться некоторыми сервисами RRDoc или
    некоторыми отдельными функциями сервисов, Пользователю необходимо пройти
    процедуру регистрации, в результате которой для Пользователя будет создана
    уникальная учетная запись.
    <br/>
    2.2. Для регистрации Пользователь обязуется предоставить достоверную и
    полную информацию о себе по вопросам, предлагаемым в форме регистрации, и
    поддерживать эту информацию в актуальном состоянии. Если Пользователь
    предоставляет неверную информацию или у RRDoc есть основания полагать, что
    предоставленная Пользователем информация неполна или недостоверна, RRDoc
    имеет право по своему усмотрению заблокировать либо удалить учетную запись
    Пользователя и отказать Пользователю в использовании своих сервиса (либо
    его отдельных функций).
    <br/>
    2.3. Почта и пароль для доступа к учетной записи Пользователя. При
    регистрации Пользователь указывает адрес своей электронной почты и пароль
    для доступа к учетной записи. RRDoc вправе устанавливать требования к
    паролю (длина, допустимые символы и т.д.).
    <br/>
    2.4. Пользователь самостоятельно несет ответственность за безопасность
    (устойчивость к угадыванию) выбранного им пароля, а также самостоятельно
    обеспечивает конфиденциальность своего пароля. Пользователь самостоятельно
    несет ответственность за все действия (а также их последствия) в рамках или
    с использованием сервиса RRDoc под учетной записью Пользователя, включая
    случаи добровольной передачи Пользователем данных для доступа к учетной
    записи Пользователя третьим лицам на любых условиях (в том числе по
    договорам или соглашениям). При этом все действия в рамках или с
    использованием сервиса RRDoc под учетной записью Пользователя считаются
    произведенными самим Пользователем, за исключением случаев, когда
    Пользователь, в порядке, предусмотренном п. 2.6., уведомил RRDoc о
    несанкционированном доступе к сервису RRDoc с использованием учетной записи
    Пользователя и/или о любом нарушении (подозрениях о нарушении)
    конфиденциальности своего пароля.
    <br/>
    2.5. Пользователь обязан немедленно уведомить RRDoc о любом случае
    несанкционированного (не разрешенного Пользователем) доступа к сервису
    RRDoc с использованием учетной записи Пользователя и/или о любом нарушении
    (подозрениях о нарушении) конфиденциальности своего пароля. В целях
    безопасности, Пользователь обязан самостоятельно осуществлять безопасное
    завершение работы под своей учетной записью (кнопка «Выход») по окончании
    каждой сессии работы с сервисами RRDoc. RRDoc не отвечает за возможную
    потерю или порчу данных, а также другие последствия любого характера,
    которые могут произойти из-за нарушения Пользователем положений этой части
    Соглашения.
    <br/>
    2.6. Использование Пользователем своей учетной записи. Пользователь не в
    праве воспроизводить, повторять и копировать, продавать и перепродавать, а
    также использовать для каких-либо коммерческих целей какие-либо части
    сервиса RRDoc (включая контент, доступный Пользователю посредством
    сервиса), или доступ к ним, кроме тех случаев, когда Пользователь получил
    такое разрешение от RRDoc, либо, когда это прямо предусмотрено
    пользовательским соглашением сервиса.
    <br/>
    2.7. Прекращение регистрации. RRDoc вправе заблокировать или удалить
    учетную запись Пользователя, а также запретить доступ с использованием
    какой-либо учетной записи к своему сервису, и удалить любой контент без
    объяснения причин, в том числе в случае нарушения Пользователем условий
    Соглашения или условий иных документов, предусмотренных п. 1.2. Соглашения.
    <br/>
    2.8. Удаление учетной записи Пользователя.
    <br/>
    2.8.1. Пользователь вправе в любой момент удалить свою учетную запись на
    сервисе RRDoc отправив сообщение электронной почты на адрес inbox@rrdoc.ru
    в свободной форме.
    <br/>
    2.8.2. Удаление учетной записи RRDoc осуществляется в следующем порядке:
    <br/>
    2.8.2.1. Учетная запись пользователя удаляется в течение 1 календарной
    недели после получения соответствующего сообщения от пользователя. Данные
    удаляются без возможности дальнейшего восстановления.
    <br/>
    2.8.2.2. Данные пользователя, за исключением информации о персональных
    данных, предусмотренных в п.2.8.2.3, становятся собственностью RRDoc, и в
    дальнейшем могут быть использованы по усмотрению RRDoc. В том числе:
    загруженные файлы и средства на личном счете пользователя.
    <br/>
    2.8.2.3. Персональные данные пользователя - электронная почта - удаляется
    сразу и не может быть использована RRDoc в дальнейшем.
    <br/>
    2.8.2.4. Все договора с Пользователем, владельцем учетной записи, считаются
    выполненными в полном объеме. Удаляя учетную запись, Пользователь
    подтверждает, что претензий по выполненным работаем не имеет.
    <br/>
    <br/>
</p>
<p>
    3. Общие положения об использовании и хранении
</p>
<p>
    3.1. RRDoc вправе устанавливать ограничения в использовании сервиса для
    всех Пользователей, либо для отдельных категорий Пользователей (в
    зависимости от места пребывания Пользователя, активности использования
    пользователем сервиса и т.д.), в том числе: наличие/отсутствие отдельных
    функций сервиса, срок хранения данных в сервисе RRDoc, любого другого
    контента, максимальное количество загружаемых файлов, максимальный размер
    загружаемых данных, максимальное количество обращений к сервису за
    указанный период времени, максимальный срок хранения контента, специальные
    параметры загружаемого контента и т.д. а также прекратить прием любой
    информации.
    <br/>
    3.2. RRDoc запрещает автоматическое обращение к своему сервису.
    <br/>
    3.3. RRDoc вправе посылать своим пользователям информационные сообщения.
    <br/>
    3.4. RRDoc не несет ответственность за потерю данных, хранящихся в Сервисе.
    <br/>
    <br/>
</p>
<p>
    4. Контент Пользователя
</p>
<p>
    4.1. Пользователь самостоятельно несет ответственность за соответствие
    содержания размещаемого Пользователем контента требованиям действующего
    законодательства, включая ответственность перед третьим лицами в случаях,
    когда размещение Пользователем того или иного контента или содержание
    контента нарушает права и законные интересы третьих лиц, в том числе личные
    неимущественные права авторов, иные интеллектуальные права третьих лиц,
    и/или посягает на принадлежащие им нематериальные блага.
    <br/>
    4.2. Пользователь признает и соглашается с тем, что RRDoc не обязан
    просматривать контент любого вида, размещаемый и/или распространяемый
    Пользователем посредством сервиса RRDoc, а также то, что RRDoc имеет право
    (но не обязанность) по своему усмотрению отказать Пользователю в размещении
    и/или распространении им контента или удалить любой контент, который
    доступен посредством сервиса RRDoc. Пользователь осознает и согласен с тем,
    что он должен самостоятельно оценивать все риски, связанные с
    использованием контента, включая оценку соответствия законодательству РФ,
    надежности, полноты или полезности этого контента.
    <br/>
    4.3. Пользователь осознает и соглашается с тем, что технология работы
    сервиса может потребовать копирование (воспроизведение) контента
    Пользователя RRDoc, а также переработки его RRDoc для соответствия
    техническим требованиям сервиса.
    <br/>
    <br/>
</p>
<p>
    5. Условия использования сервиса RRDoc
</p>
<p>
    5.1. Пользователь самостоятельно несет ответственность перед третьими
    лицами за свои действия, связанные с использованием Сервиса, в том числе,
    если такие действия приведут к нарушению прав и законных интересов третьих
    лиц, а также за соблюдение законодательства при использовании Сервиса.
    <br/>
    5.2. При использовании сервиса RRDoc Пользователь не вправе:
    <br/>
    5.2.1. загружать, посылать, передавать или любым другим способом размещать
    и/или распространять контент, который является незаконным, вредоносным,
    клеветническим, оскорбляет нравственность, демонстрирует (или является
    пропагандой) насилия и жестокости, нарушает права интеллектуальной
    собственности, пропагандирует ненависть и/или дискриминацию людей по
    расовому, этническому, половому, религиозному, социальному признакам,
    содержит оскорбления в адрес каких-либо лиц или организаций, содержит
    элементы (или является пропагандой) порнографии, детской эротики,
    представляет собой рекламу (или является пропагандой) услуг сексуального
    характера (в том числе под видом иных услуг), разъясняет порядок
    изготовления, применения или иного использования наркотических веществ или
    их аналогов, взрывчатых веществ или иного оружия;
    <br/>
    5.2.2. нарушать права третьих лиц, в том числе несовершеннолетних лиц и/или
    причинять им вред в любой форме;
    <br/>
    5.2.3. выдавать себя за другого человека или представителя организации
    и/или сообщества без достаточных на то прав, в том числе за сотрудников
    RRDoc, за владельца сайта, а также применять любые другие формы и способы
    незаконного представительства других лиц в сети, а также вводить
    пользователей или RRDoc в заблуждение относительно свойств и характеристик
    каких-либо субъектов или объектов;
    <br/>
    5.2.4. загружать, посылать, передавать или любым другим способом размещать
    и/или распространять контент, при отсутствии прав на такие действия
    согласно законодательству или каким-либо договорным отношениям;
    <br/>
    5.2.5. загружать, посылать, передавать или любым другим способом размещать
    и/или распространять не разрешенную специальным образом рекламную
    информацию, спам (в том числе и поисковый), списки чужих адресов
    электронной почты, схемы «пирамид», многоуровневого (сетевого) маркетинга
    (MLM), системы интернет-заработка и e-mail-бизнесов, «письма счастья», а
    также использовать сервис RRDoc для участия в этих мероприятиях, или
    использовать сервис RRDoc, исключительно для перенаправления пользователей
    на страницы других доменов;
    <br/>
    5.2.6. загружать, посылать, передавать или любым другим способом размещать
    и/или распространять какие-либо материалы, содержащие вирусы или другие
    компьютерные коды, файлы или программы, предназначенные для нарушения,
    уничтожения либо ограничения функциональности любого компьютерного или
    телекоммуникационного оборудования или программ, для осуществления
    несанкционированного доступа, а также серийные номера к коммерческим
    программным продуктам и программы для их генерации, логины, пароли и прочие
    средства для получения несанкционированного доступа к платным ресурсам в
    Интернете, а также размещения ссылок на вышеуказанную информацию;
    <br/>
    5.2.7. не санкционированно собирать и хранить персональные данные других
    лиц;
    <br/>
    5.2.8. нарушать нормальную работу веб-сайтов и сервиса RRDoc;
    <br/>
    5.2.9. размещать ссылки на ресурсы сети, содержание которых противоречит
    действующему законодательству РФ;
    <br/>
    5.2.10. содействовать действиям, направленным на нарушение ограничений и
    запретов, налагаемых Соглашением;
    <br/>
    5.2.11. другим образом нарушать нормы законодательства, в том числе нормы
    международного права.
    <br/>
    <br/>
</p>
<p>
    6. Исключительные права на содержание сервисов и контент
</p>
<p>
    6.1. Все объекты, доступные при помощи сервиса RRDoc, в том числе элементы
    дизайна, текст, графические изображения, иллюстрации, видео, программы для
    ЭВМ, базы данных, музыка, звуки и другие объекты (далее – содержание
    сервиса), а также любой контент, размещенный на сервисе RRDoc, являются
    объектами исключительных прав RRDoc.
    <br/>
    6.2. Использование контента, а также каких-либо иных элементов сервиса
    возможно только в рамках функционала, предлагаемого сервисом. Никакие
    элементы содержания сервиса RRDoc, а также любой контент, размещенный на
    сервисе RRDoc, не могут быть использованы иным образом без предварительного
    разрешения правообладателя. Под использованием подразумеваются, в том
    числе: воспроизведение, копирование, переработка, распространение на любой
    основе, отображение во фрейме и т.д. Исключение составляют случаи, прямо
    предусмотренные законодательством РФ или условиями использования сервиса
    RRDoc.
    <br/>
    Использование Пользователем элементов содержания сервиса, а также любого
    контента для личного некоммерческого использования, допускается при условии
    сохранения всех знаков охраны авторского права, смежных прав, товарных
    знаков, других уведомлений об авторстве, сохранения имени (или псевдонима)
    автора/наименования правообладателя в неизменном виде, сохранении
    соответствующего объекта в неизменном виде. Исключение составляют случаи,
    прямо предусмотренные законодательством РФ или пользовательскими
    соглашениями того или иного сервиса RRDoc.
    <br/>
    <br/>
</p>
<p>
    7. Сайты и контент третьих лиц
</p>
<p>
    7.1. Сервис RRDoc может содержать ссылки на другие сайты в сети Интернет
    (сайты третьих лиц). Указанные третьи лица и их контент не проверяются
    RRDoc на соответствие тем или иным требованиям (достоверности, полноты,
    законности и т.п.). RRDoc не несет ответственность за любую информацию,
    материалы, размещенные на сайтах третьих лиц, к которым Пользователь
    получает доступ с использованием сервиса, в том числе, за любые мнения или
    утверждения, выраженные на сайтах третьих лиц, рекламу и т.п., а также за
    доступность таких сайтов или контента и последствия их использования
    Пользователем.
    <br/>
    7.2. Ссылка (в любой форме) на любой сайт, продукт, услугу, любую
    информацию коммерческого или некоммерческого характера, размещенная на
    Сайте, не является одобрением или рекомендацией данных продуктов (услуг,
    деятельности) со стороны RRDoc, за исключением случаев, когда на это прямо
    указывается на ресурсах RRDoc.
    <br/>
    <br/>
</p>
<p>
    8. Отсутствие гарантий, ограничение ответственности
</p>
<p>
    8.1. Пользователь использует сервис RRDoc на свой собственный риск. Сервис
    предоставляется «как есть». RRDoc не принимает на себя никакой
    ответственности, в том числе за соответствие сервиса целям Пользователя;
    <br/>
    8.2. RRDoc не гарантирует, что: сервис соответствует/будет соответствовать
    требованиям Пользователя; сервис будет предоставляться непрерывно, быстро,
    надежно и без ошибок; результаты, которые могут быть получены с
    использованием сервиса, будут точными и надежными и могут использоваться
    для каких-либо целей или в каком-либо качестве (например, для установления
    и/или подтверждения каких-либо фактов); качество какого-либо продукта,
    услуги, информации и пр., полученных с использованием сервиса, будет
    соответствовать ожиданиям Пользователя;
    <br/>
    8.3. Любые информация и/или материалы (в том числе загружаемое ПО, данные
    кадастрового учета и государственного реестра прав, какие-либо инструкции и
    руководства к действию и т.д.), доступ к которым Пользователь получает с
    использованием сервиса RRDoc, Пользователь может использовать на свой
    собственный страх и риск и самостоятельно несет ответственность за
    возможные последствия использования указанных информации и/или материалов,
    в том числе за ущерб, который это может причинить компьютеру Пользователя
    или третьим лицам, за потерю данных или любой другой вред;
    <br/>
    8.4. RRDoc не несет ответственности за любые виды убытков, произошедшие
    вследствие использования Пользователем сервиса RRDoc или отдельных
    частей/функций сервиса;
    <br/>
    8.5. При любых обстоятельствах ответственность RRDoc в соответствии со
    статьей 15 Гражданского кодекса России ограничена 5000 (пятью тысячами)
    рублей РФ и возлагается на него при наличии в его действиях вины.
    <br/>
    8.6. Все услуги считаются выполненными в полном объеме, непосредственно
    после их оплаты.
    <br/>
    <br/>
</p>
<p>
    9. Иные положения
</p>
<p>
    9.1. Настоящее Соглашение представляет собой договор между Пользователем и
    RRDoc относительно порядка использования сервиса и заменяет собой все
    предыдущие соглашения между Пользователем и RRDoc.
    <br/>
    9.2. Настоящее Соглашение регулируется и толкуется в соответствии с
    законодательством Российской Федерации. Вопросы, не урегулированные
    настоящим Соглашением, подлежат разрешению в соответствии с
    законодательством Российской Федерации. Все возможные споры, вытекающие из
    отношений, регулируемых настоящим Соглашением, разрешаются в порядке,
    установленном действующим законодательством Российской Федерации, по нормам
    российского права. Везде по тексту настоящего Соглашения, если явно не
    указано иное, под термином «законодательство» понимается как
    законодательство Российской Федерации, так и законодательство места
    пребывания Пользователя.
    <br/>
    9.3. Все безвозмездные услуги, оказываемых в рамках настоящего Соглашения,
    нормы о защите прав потребителей, предусмотренные законодательством
    Российской Федерации, не могут быть применимыми к отношениям между
    Пользователем и RRDoc.
    <br/>
    9.4. Ничто в Соглашении не может пониматься как установление между
    Пользователем и RRDoc агентских отношений, отношений товарищества,
    отношений по совместной деятельности, отношений личного найма, либо
    каких-то иных отношений, прямо не предусмотренных Соглашением.
    <br/>
    9.5. Если по тем или иным причинам одно или несколько положений настоящего
    Соглашения будут признаны недействительными или не имеющими юридической
    силы, это не оказывает влияния на действительность или применимость
    остальных положений Соглашения.
    <br/>
    9.6. Бездействие со стороны RRDoc в случае нарушения Пользователем либо
    иными пользователями положений Соглашений не лишает RRDoc права предпринять
    соответствующие действия в защиту своих интересов позднее, а также не
    означает отказа RRDoc от своих прав в случае совершения в последующем
    подобных либо сходных нарушений.
</p>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>


<style scoped>
.term-of-use-local {
  padding: 30px;
}
</style>

