<template>
  <div class="search-wrapper">
    <form>
      <textarea rows='1' class="input search-input" type="text" v-model="query" id="doc-search" v-on:input="setContextQuery()" maxlength="1501" placeholder="Введите кадастровые номера через запятую"/>
    </form>
    <button class="button-round clear-field" v-show="showClearFieldIcon" @click="clearDocumentContextSearch()" id="clr-context-search-button">
      <img src="../../../assets/img/close-white.svg" alt="">
    </button>
  </div>
</template>

<script>
// storage facilities
import documentStore from '../../../store/documentStore'
import alertStore from '../../../store/AlertStore'

export default {
  name: 'DocTableHeader',
  data () {
    return {
      timeout: null,
      query: ''
    }
  },
  computed: {
    showClearFieldIcon () {
      return this.query.length > 0
    },
    contextQuery: function () {
      return documentStore.state.contextQuery
    }
  },
  watch: {
  },
  mounted: function () {
    this.onlyActual = documentStore.state.onlyActual
    if (documentStore.getters.value('ordersForSearch') !== '') {
      documentStore.dispatch('setContextQuery', documentStore.getters.value('ordersForSearch'))
      this.query = this.contextQuery
      documentStore.state.ordersForSearch = '' // очищаем состояние перехода к документам из заказа
    } else {
      if (this.$route.query.number !== '' && this.$route.query.number !== 'undefined' && this.$route.query.number !== undefined) {  // если в адресной строке есть параметр с номером документа(из поделиться)
        documentStore.dispatch('setContextQuery', this.$route.query.number)
        this.query = this.contextQuery
      } else {
        this.query = this.contextQuery
      }
    }
  },
  methods: {
    clearDocumentContextSearch () {
      this.query = ''
      this.autosize()
      this.setContextQuery()
    },
    autosize () {
      var el = document.querySelector('textarea') // textarea
      var clrBut = document.getElementById('clr-context-search-button')  //  кнопка очистить
      setTimeout(function () {
        el.style.cssText = 'height: 25px' // сделать высоты дефолтными
        clrBut.style.cssText = 'top: 25px'
        let _height = el.scrollHeight // вычислить высоту
        // console.log(_height)
        el.style.cssText = 'height:' + (_height + 3) + 'px' // изменить на новые
        clrBut.style.cssText = 'top:' + (_height > 78 ? 78 : _height) + 'px'
      }, 0)
    },
    onPaste () {
      this.autosize()
      let temStr = this.query
      if (temStr[this.query.length - 1] === '\n') {
        temStr = temStr.substring(0, temStr.length - 1) // убираем последний символ(при вставке из экселя это переход на новую строку)
      }
      temStr = temStr.replace(/\n/g, ', ')
      this.query = temStr
      this.orderContextQuery()
    },
    setContextQuery: function () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (this.query.length > 1500) {
          alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка вставки', text: 'Вы пытаетесь найти слишком много номеров (более, чем 1500 символов)'})
          return
        }
        this.onPaste()
      }, 500)
    },
    orderContextQuery () {
      // console.log(this.query)
      // console.log(this.contextQuery)
      documentStore.dispatch('setContextQuery', this.query)
    },

    replaceSpaces: function (query) {
      query = query.replace(';', ',')
      let i = 0
      while (i < query.length - 1) {
        if (query[i] === ' ' && query[i + 1] === ' ') {
          query = query.substr(0, i) + query.substr(i + 1)
        } else {
          i++
        }
      }
      for (let i = 1; i < query.length; i++) {
        if (query[i] === ' ' && query[i - 1] !== ',') {
          query = query.substr(0, i) + ':' + query.substr(i + 1)
        }
      }
      i = 0
      while (i < query.length - 1) {
        if (query[i] === ',' && query[i + 1] === ',') {
          query = query.substr(0, i) + query.substr(i + 1)
        } else {
          i++
        }
      }
      return query
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/sass/varibles.scss";
.search-input{
  margin: auto;
  margin-top: 25px;
  margin-bottom: 10px;
  width: 60%;
  overflow-y: auto;
  max-height: 78px;
  resize: none;
}
.search-wrapper {
  width: inherit;
  text-align: center !important;
}
.clear-field {
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: rgba($color: gray, $alpha: 0.5);
  position: absolute;
  right: 22%;
  top: 25px;
}
.clear-field img {
  width: 20px;
  height: 20px;
  padding: 5px;
  margin-top: -6.5px;
}
</style>

