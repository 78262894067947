<template>
  <div id="offer">
      <div class="offer-wrap">
        <!-- <div></div> -->
        <p class="offer-title">Авторизуйтесь</p>
        <p>и вы сможете:</p>
        <p class="bul">&#8226;&nbsp;загружать документы в базу</p>
        <p class="bul">&#8226;&nbsp;скачивать сразу несколько документов</p>
        <p class="bul">&#8226;&nbsp;заказывать выписки ЕГРН (бесплатно 50 в месяц)</p>
        <p class="bul">&#8226;&nbsp;печатать документы в форматы .pdf и .docx</p>
        <div class="button-line">
          <button class="button button-primary" @click="signIn()">вход</button>
          <button class="button button-trust" @click="signUp()">регистрация</button>
        </div>
        <button class="button-close offer-button-close" @click="closeOffer()"><img src="../../assets/img/close-form.svg" alt="закрытие приветственного окна"></button>
      </div>
  </div>
</template>

<script>
// storage facilities
import alertStore from '../../store/AlertStore'

export default {
  name: 'Offer',
  methods: {
    closeOffer () {
      alertStore.dispatch('setValue', {key: 'offer', vaue: false})
    },
    signIn () {
      this.closeOffer()
      this.$emit('toSignIn')
    },
    signUp () {
      this.closeOffer()
      this.$emit('toSignUp')
    }
  }
}
</script>
<style lang="scss" scoped>
#offer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top:0;
  left:0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999999999;
}
.offer-wrap{
  width: 500px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -280px;
  margin-left: -250px;
  background-color: white;
  padding: 20px;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
}
.offer-title {
  font-size: 14pt;
  font-weight: bold;
}
.bul {
  margin-left: 30px;
}
.button-line {
  margin-top: 40px;
  margin-bottom: 30px;
  width: 100%;
  height: 40px;
  text-align: center;
}
.offer-button-close {
  position: absolute;
  top: 20px;
  right: 25px;
}
</style>
