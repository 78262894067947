import httpStore from '../store/httpStore'
import userStore from '../store/userStore'
import profileSore from '../store/profileStore'
import alertStore from '../store/AlertStore'
import store from '../store/store'
import apiDataStore from '../store/apiDataStore'

export default {
  data () {
    return {
      // urls to endpoints API
      hostURL: httpStore.state.host,
      userURL: httpStore.state.user,
      tariffsURL: httpStore.state.tariffs,
      updateCompanyURL: httpStore.state.updateCompany,
      companyEmployeesURL: httpStore.state.companyEmployees,
      companyEmployeeURL: httpStore.state.companyEmployee,
      getCurrentPermissionsURL: httpStore.state.getCurrentPermissions,
      updateTariffURL: httpStore.state.updateTariff,
      addUserToCompanyURL: httpStore.state.addUserToCompany,
      deleteUserFromCompanyURL: httpStore.state.deleteUserFromCompany,
      getRegularPaymentPriceURL: httpStore.state.getRegularPaymentPrice,
      setEmployeePermissionsURL: httpStore.state.setEmployeePermissions,
      exitFromCompanyURL: httpStore.state.exitFromCompany,
      unblockTariffURL: httpStore.state.unblockTariff,

      errors: [],
      load: true,

      // data
      updateCompanyResult: null,
      updateTariffResult: null,
      addUserToCompanyResult: null,
      deleteUserFromCompanyResult: null,
      setEmployeePermissionsResult: null,
      unblockTariffResult: null,
      profileAllDataLoading: false,
      profileLoadingEmploeeTable: false,
      loadingUserCompanyAction: false
    }
  },
  methods: {
    getAllProfileData: async function () {
      this.profileAllDataLoading = true
      let promises = []
      promises.push(await this.getUserInfo())
      promises.push(this.getTariffsInfo())
      // this.getAllUserBillingInfoMix()
      promises.push(this.getCurrentPermissionsInfo())
      promises.push(this.getRegularPaymentPriceInfo()) //  оставить последним (меняется флаг загрузки в профиле)
      return Promise.all(promises).then(() => {
        // console.log('all load finish')
        this.profileAllDataLoading = false
        this.getCompanyEmployeesInfo()
      }).catch(err => {
        console.log(err)
        this.profileAllDataLoading = false
      })
    },

    // запрашивает username, isBoss, email, tariff, guid
    getUserInfo () {
      return new Promise((resolve) => {
        this.$http.get(this.hostURL + this.userURL).then(response => {
          // user info set to state
          userStore.state.username = response.data.username
          userStore.state.isBoss = response.data.is_boss
          userStore.state.email = response.data.email
          if (response.data.tariff !== null) {
            userStore.state.tariffName = response.data.tariff.name
            userStore.state.tariffHumanName = response.data.tariff.human_name
            userStore.state.tariffCompanyAllow = response.data.tariff.company_allow
            userStore.state.tariffIsActive = response.data.tariff_is_active
          }
          userStore.state.guid = response.data.guid
          if (response.data.company !== null) {
            userStore.state.company = response.data.company.name
          }
          store.dispatch('setValue', {key: 'userDataLoading', value: false})
          resolve(true)
        }).catch(error => {
          this.errors.push(error)
          store.dispatch('setValue', {key: 'userDataLoading', value: false})
          resolve(false)
        })
      })
    },

    // запрашивает тарифы для отображения в ЛК
    getTariffsInfo () {
      return new Promise(resolve => {
        this.$http.get(this.hostURL + this.tariffsURL).then(response => {
          profileSore.state.tariffsInfo = response.data
          resolve(response.data)
        }).catch(error => {
          this.errors.push(error)
          resolve(error)
        })
      })
    },
    updateCompany (name) {
      return new Promise(resolve => {
        this.updateCompanyResult = null
        this.$http.get(this.hostURL + this.updateCompanyURL, {params: {name: name}}).then(response => {
          this.updateCompanyResult = response.data.result
          resolve(response.data.result)
        }).catch(error => {
          this.errors.push(error)
          resolve(null)
        })
      })
    },
    getCompanyEmployeeInfo (username, index = 0) {
      return new Promise(resolve => {
        this.$http.get(this.hostURL + this.companyEmployeeURL, {params: {username: username}}).then(response => {
          profileSore.state.companyEmployeesInfo.splice(index, 1, response.data)
          this.profileLoadingEmploeeTable = false
          resolve()
        }).catch(error => {
          this.errors.push(error)
          this.profileLoadingEmploeeTable = false
          resolve()
        })
      })
    },
    getCompanyEmployeesInfo () {
      if (userStore.getters.value('isBoss') && userStore.getters.value('tariffCompanyAllow')) {
        this.profileLoadingEmploeeTable = true
        this.$http.get(this.hostURL + this.companyEmployeesURL).then(response => {
          profileSore.state.companyEmployeesInfo = response.data
          this.profileLoadingEmploeeTable = false
        }).catch(error => {
          this.profileLoadingEmploeeTable = false
          this.errors.push(error)
        })
      }
    },
    getCurrentPermissionsInfo () {
      return new Promise(resolve => {
        this.$http.get(this.hostURL + this.getCurrentPermissionsURL).then(response => {
          userStore.state.currentPermissions = response.data
          resolve(response.data)
        }).catch(error => {
          this.errors.push(error)
          resolve(error)
        })
      })
    },
    updateTariffMix (name) {
      this.load = true
      apiDataStore.dispatch('setValue', {key: 'loadingUpdateTariffMix', value: true})
      this.updateTariffResult = null
      this.$http.get(this.hostURL + this.updateTariffURL, {params: {name: name}}).then(response => {
        this.updateTariffResult = response.data.result
        alertStore.dispatch('showAlert', {type: 'success', title: 'Тариф изменён', text: 'Данные о тарифе обновятся через несколько секунд'})
        this.getAllProfileData()
        apiDataStore.dispatch('setValue', {key: 'loadingUpdateTariffMix', value: false})
        this.load = false
      }).catch(error => {
        this.errors.push(error)
        apiDataStore.dispatch('setValue', {key: 'loadingUpdateTariffMix', value: false})
        this.load = false
        if (error.body.detail !== undefined && error.body.detail === 'balance is empty') {
          alertStore.dispatch('showAlert', {type: 'error', title: 'Пополните баланс', text: 'Не удалось изменить тариф. У Вас недостаточно средств для смены тарифа.'})
        } else {
          alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка', text: 'Не удалось изменить тариф. Обновите страницу и попробуйте еще раз.'})
        }
      })
    },
    addUserToCompany (guid, update = false) {
      this.load = true
      this.loadingUserCompanyAction = true
      this.addUserToCompanyResult = null
      this.$http.get(this.hostURL + this.addUserToCompanyURL, {params: {guid: guid}}).then(response => {
        this.addUserToCompanyResult = response.data.result
        if (update) {
          this.getCompanyEmployeesInfo()
          alertStore.dispatch('showAlert', {type: 'success', title: 'Добавлен', text: 'Пользователь успешно добавлен в компанию. Ежедневное списание изменилось.'})
          this.getRegularPaymentPriceInfo()
          this.load = false
          this.loadingUserCompanyAction = false
        }
      }).catch(error => {
        this.loadingUserCompanyAction = false
        this.errors.push(error)
        alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка', text: 'Не удалось добавить пользователя в компанию. Обновите страницу и попробуйте еще раз.'})
      })
    },
    deleteUserFromCompany (username, updateIndex = null) {
      this.loadingUserCompanyAction = true
      this.load = true
      this.deleteUserFromCompanyResult = null
      this.$http.get(this.hostURL + this.deleteUserFromCompanyURL, {params: {username: username}}).then(response => {
        this.deleteUserFromCompanyResult = response.data.result
        if (updateIndex !== null) {
          profileSore.state.companyEmployeesInfo.splice(updateIndex, 1)
          alertStore.dispatch('showAlert', {type: 'success', title: 'Успешно', text: 'Пользователь успешно удален из компании. Ежедневное списание изменилось'})
          this.loadingUserCompanyAction = false
          this.getRegularPaymentPriceInfo()
          this.load = false
        }
      }).catch(error => {
        this.errors.push(error)
        this.loadingUserCompanyAction = false
        alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка', text: 'Не удалось удалить пользователя из компании. Обновите страницу и попробуйте еще раз.'})
      })
    },
    deleteSelectedFromCompanyMix: async function (selected) {
      this.loadingUserCompanyAction = true
      this.load = true
      let allSuccess = true
      for await (const item of selected) {
        await this.$http.get(this.hostURL + this.deleteUserFromCompanyURL, {params: {username: item}}).then(response => {
          this.deleteUserFromCompanyResult = response.data.result
          // console.log(profileSore.state.companyEmployeesInfo.indexOf(item))
          // profileSore.state.companyEmployeesInfo.splice(profileSore.state.companyEmployeesInfo.indexOf(item), 1)
          // this.loadingUserCompanyAction = false
        }).catch(error => {
          this.errors.push(error)
          allSuccess = false
        })
      }
      this.getRegularPaymentPriceInfo()
      await this.getCompanyEmployeesInfo()
      this.load = false
      this.loadingUserCompanyAction = false
      return allSuccess
    },
    getRegularPaymentPriceInfo () {
      return new Promise(resolve => {
        this.$http.get(this.hostURL + this.getRegularPaymentPriceURL).then(response => {
          profileSore.state.regularPaymentPriceInfo = response.data.result
          this.profileAllDataLoading = false
          resolve(response.data)
        }).catch(error => {
          // this.profileAllDataLoading = false
          this.errors.push(error)
          resolve(error)
        })
      })
    },
    setEmployeePermissions (username, permissionCodes, updateIndex = null) {
      return new Promise(resolve => {
        this.profileLoadingEmploeeTable = true
        this.setEmployeePermissionsResult = null
        this.$http.get(this.hostURL + this.setEmployeePermissionsURL, {params: {
          username: username,
          permission_codes: permissionCodes
        }}).then(response => {
          this.setEmployeePermissionsResult = response.data.result
          if (updateIndex !== null) {
            this.getCompanyEmployeeInfo(username, updateIndex).then(() => {
              resolve() // вернуть из промиса, когда закончится выполнение ф-ции getCompanyEmployeeInfo
            })
          }
        }).catch(error => {
          this.errors.push(error)
          resolve()
        })
      })
    },
    getExitFromCompanyMix () {
      this.$http.get(this.hostURL + this.exitFromCompanyURL).then(() => {
        alertStore.dispatch('showAlert', {type: 'success', title: 'Успешно', text: 'Вы покинули компанию'})
        this.getAllProfileData()
      }).catch(error => {
        this.errors.push(error)
        alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка', text: 'Не удалось покинуть компанию. Обновите страницу и попробуйте еще раз.'})
      })
    },
    unblockTariff () {
      this.$http.get(this.hostURL + this.unblockTariffURL).then(response => {
        this.unblockTariffResult = response.data.result
        this.getAllProfileData()
      }).catch(error => {
        this.errors.push(error)
      })
    }
  }
}
