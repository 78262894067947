<template>
  <div class="wrap">
    <div class="context-search">
      <!-- <input type="text" class="input context-input" v-on:input="contextSearch()" v-model="query" placeholder="Введите кадастровые номера через запятую или примечание"> -->
      <textarea rows='1' class="input context-input" type="text" v-model="query" id="doc-search" v-on:input="contextSearch()" maxlength="1501" placeholder="Введите кадастровые номера через запятую или примечание"/>
      <button class="button-round clear-field" v-show="showClearField" @click="clearInput()" id="clr-button">
        <img src="../../assets/img/close-white.svg" alt="">
      </button>
    </div>
    <h2 class="good-class">межевые планы, документы кадастрового инженера</h2>
    <div class="table-controllers">
      <div class="download-button-wrap">
        <!-- <noindex> -->
        <document-upload></document-upload>
        <!-- </noindex> -->
        <button class="button button-primary" @click="downloadPopupCheck = true">
          <img v-if="multiDownloadPackageStateLandPlans" src="../../assets/img/spinner-white.svg" class="wait-download">
          <img v-else src="../../assets/img/list.svg" class="wait-download">   
          Скачать выбранные
        </button>
        <div class="download-popup" v-if="downloadPopupCheck">
          <button title="Скачать документы в изначальном виде" class="download-popup-elem" v-on:click="downloadSelected('source')" :class="{'disabled':this.selected.length == 0}" :disabled="this.selected.length == 0">xml документы</button>
          <button title="Скачать версии для печати" class="download-popup-elem" v-on:click="downloadSelected('print')" :class="{'disabled':this.selected.length == 0}" :disabled="this.selected.length == 0">docx документы</button>
        </div>
        <!-- <button class="button button-primary" v-on:click="downloadSelected()" :class="{'download-button-disabled':this.selected.length == 0}" :disabled="this.selected.length == 0">
          <img v-if="multiDownloadPackageStateLandPlans" src="../../assets/img/spinner-white.svg" class="wait-download">
          <img v-else src="../../assets/img/list.svg" class="wait-download">          
          Скачать выбранные
        </button> -->
      </div>
      <div class="select-date">

        <p class="input date-selected" @click="showDates()"> {{ selectedDateName || "Дата актуальности"}}</p>
        <div class="date-selector" v-if="showDatesFlag">
          <p class="date-item" v-for="item in dateList" :key="item.id" @click="selectDate(item)">{{ item.name }}</p>
        </div>

      </div>

      <div class="date-picker" v-if="showDatePicker">
        <flat-pickr v-model="startDate" :config="flatpicrConfigFrom" placeholder="от"></flat-pickr>
        <flat-pickr v-model="endDate" :config="flatpicrConfigTo" placeholder="до"></flat-pickr>            
      </div>

      <div class="overlay-download overlay-popup" v-if="downloadPopupCheck" @click="downloadPopupCheck = false"></div>
    </div>
    <div class="spinner" v-if="loading || userDataLoading"><img src="../../assets/img/rrd_logo_test.svg" alt="загрузка таблицы межевых планов"></div>
    <div class="table-wrapper" style="overflow-x:auto;" v-else-if="rows.length > 0">
      <table class="table doc-table">
        <tr class="table-row doc-table-row table-row-header">
          <th class="table-col doc-table-col">
            <span class="table-checkbox" @click="selectAllItems">
              <div class="fill-checkbox" v-if="allItemsSelected"></div>
            </span>
          </th>
          <th class="table-col doc-table-col">
            Наименование
          </th>
          <th class="table-col doc-table-col">
           Тип
          </th>
          <th class="table-col doc-table-col">
            Реквизиты
          </th>
          <th class="table-col doc-table-col">
            Регион
          </th>
          <th class="table-col doc-table-col">
            Район
          </th>
          <th class="table-col doc-table-col">
            Примечание
          </th>
          <th class="table-col doc-table-col">
            Действия
          </th>
        </tr>
        <tr class="table-row doc-table-row" v-for="item in rows" :key="item.id" @click="checkingItem(item.id)" v-bind:class="{'table-row-public': !item.my}">
          <td class="table-col doc-table-col">
            <span class="table-checkbox">
              <div class="fill-checkbox" v-show="checkSelect(item.id)==true"></div>
            </span>
            <input type="checkbox" :value="item.id" v-model="selected" class="hidecheckbox">
          </td>
          <td class="table-col doc-table-col">
            {{ item.cadNumber }}
          </td>
          <td class="table-col doc-table-col" v-if="item.type !== null">
            {{ item.type_name}}
          </td>
          <td class="table-col doc-table-col" v-if="item.type === null">
            -
          </td>
          <td class="table-col doc-table-col">
            {{ item.registration_number }} от {{ item.date_formation }}
          </td>
          <td class="table-col doc-table-col">
            {{ item.region_code }}
          </td>
          <td class="table-col doc-table-col">
            {{ item.district_code }}
          </td>
          <td class="table-col doc-table-col">
            {{ item.tag }}
          </td>
          <td class="table-col doc-table-col table-col-actions">
            <div>
              <div v-if="getStatus(item.id) === 200" title="Скачать версию для печати" @click.stop="downloadPrinted(item.id)" class="status-item-block">
                <img src="../../assets/img/printer.svg" alt="скачать напечатанный межевой план" class="download-button">
              </div>

              <div v-else-if="getStatus(item.id) === 202" title="Документ в обработке" @click.stop="downloadPrinted(item.id)" class="status-item-block">
                <img src="../../assets/img/gears.svg" alt="скачать напечатанный межевой план" class="download-button">
              </div>

              <div v-else-if="getStatus(item.id) === 500" title="Ошибка" @click.stop="downloadPrinted(item.id)" class="status-item-block">
                <img src="../../assets/img/warning.svg" alt="скачать напечатанный межевой план" class="download-button">
              </div>

              <!-- если нет "скачать", то пустой блок -->
              <div v-else class="status-item-block-stub">
              </div>

              <div @click.stop="downloadDoc(item.id, item.cadNumber)" title="Скачать докумет в исходном виде" :id="item.id" class="status-item-block">
                <img src="../../assets/img/download.svg" alt="скачать исходный межевой план" class="download-button">
              </div>
              
              <div class="status-item-block" @click.stop="shareDoc(item)" title="поделиться документом(получатель должен состоять одной с Вами группе)">
                <img src="../../assets/img/share.svg" alt="скопировать ссылку на межевой план" class="download-button">
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="table-no-results" v-else>
      <p>ДОКУМЕНТЫ НЕ НАЙДЕНЫ</p>
    </div>
    <div class="paginator-wrap">
      <div class="paginator-info-count" v-if="!error && count !== 0 && !loading"><p>Документов: <b>{{count | count}}</b></p></div>
      <div class="paginator-body" v-if="!error && count !== 0 && !loading && rightPageNumber > 1">
        <div>
          <button class="paginator-button paginator-double-left" v-if="currentPage !== 1" v-on:click="changeCurrentPage('first')"><img src="../../assets/img/double-arrow-left.svg"></button>
          <button class="paginator-button paginator-left" v-if="currentPage !== 1" v-on:click="changeCurrentPage('prev')"><img src="../../assets/img/arrow-left.svg"></button>

          <div class="page-selector">
            <span class="page-selector-item" @click="changeCurrentPage(item)" v-for="(item, index) in pagesList" :key="index" v-bind:class="{'selected-page': checkPage(item)}"> {{item}} </span>
          </div>

          <button class="paginator-button paginator-right" v-if="currentPage !== pages" v-on:click="changeCurrentPage('next')"><img src="../../assets/img/arrow-right.svg"></button>
          <!-- <button class="paginator-button paginator-double-right" v-if="currentPage !== pages" v-on:click="changeCurrentPage('last')"><img src="../../assets/img/double-arrow-right.svg"></button> -->
        </div>

        <div class="select-count-document-on-page">
          <p>Документов на странице</p>
          <select @change="changeLimit()" v-model="limitOnPage" class="input selector-count-documents">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
    </div>
    <!-- <div class="date-picker-overlay" v-if="showDatePicker"></div> -->
    <div class="date-selector-overlay" v-if="showDatesFlag" @click="closePopup()"></div>
  </div>
</template>

<script>
import aca from '../Utils/autocompillerArchive.js'
import moment from 'moment'

// storege facilities
import apiDataStore from '../../store/apiDataStore'
import landPlansStore from '../../store/landPlansStore'
import store from '../../store/store'
import alertStore from '../../store/AlertStore'

// components
import DocumentUpload from '../DocumentList/DocumentUpload'

export default {
  name: 'UploadList',
  components: {
    'document-upload': DocumentUpload
  },
  data () {
    return {
      query: '',
      currentPage: 1,
      limitOnPage: 10,
      downloadPopupCheck: false,
      selected: [],
      allItemsSelected: false,
      error: false,
      dateList: [
        {
          id: 0,
          name: '-'
        },
        {
          id: 1,
          name: 'Сегодня'
        },
        {
          id: 2,
          name: 'Неделя'
        },
        {
          id: 3,
          name: 'Месяц'
        },
        {
          id: 4,
          name: 'Год'
        },
        {
          id: 5,
          name: 'Задать промежуток'
        }
      ],
      showDatePicker: false,
      startDate: null,
      endDate: null,
      showDatesFlag: false,
      moment: moment,
      selectedDate: {
        id: 0,
        name: ''
      },
      timeout: null
    }
  },
  computed: {
    showClearField () {
      return this.query.length > 0
    },
    perPage: function () {
      return landPlansStore.getters.value('perPage')
    },
    pages: function () {
      return landPlansStore.getters.value('pagesCount')
    },
    count: function () {
      return landPlansStore.getters.value('count')
    },
    nextPage () {
      return landPlansStore.getters.value('nextPage')
    },
    prevPage () {
      return landPlansStore.getters.value('prevPage')
    },
    computeOffsetLastPage () {
      return this.count - (this.count % this.limitOnPage)
    },
    offset () {
      return (this.currentPage - 1) * this.limitOnPage
    },
    multiDownloadPackageStateLandPlans () {
      return apiDataStore.getters.value('multiDownloadPackageStateLandPlans')
    },
    loading: function () {
      return landPlansStore.getters.value('loadingUploaded')
    },
    rows () {
      return landPlansStore.getters.value('rows')
    },
    flatpicrConfigFrom () {
      return {
        maxDate: 'today'
      }
    },
    flatpicrConfigTo () {
      return {
        minDate: this.startDate,
        maxDate: 'today'
      }
    },
    selectedDateName () { // для отображения надписи даты, если она не выбрана
      if (this.selectedDate.id !== 0) {
        return this.selectedDate['name']
      } else {
        return ''
      }
    },
    exQueryString () {
      let exSrt = ''
      if (this.startDate !== null) {
        exSrt = '&start_date=' + this.startDate
      }
      if (this.endDate !== null) {
        exSrt += '&end_date=' + this.endDate
      }
      return exSrt
    },
    printStatuses () {
      return apiDataStore.getters.value('printStatuses')
    },
    leftPageNumber () {
      let lp = (this.offset - 4 * this.limitOnPage) / this.limitOnPage
      return (lp > 0 ? lp : 1)
    },
    rightPageNumber () {
      let tmp1 = (this.offset + 5 * this.limitOnPage) / this.limitOnPage
      let tmp2 = this.count / this.limitOnPage
      let min = Math.min(tmp1, tmp2)
      if (tmp2 >= 10 && tmp1 < 10) {
        min = 10
      }
      return min
    },
    pagesList () {
      let pages = []
      for (let i = this.leftPageNumber; i <= Math.ceil(this.rightPageNumber); i++) {
        if (i < this.rightPageNumber) {
          pages.push(i + '')
        } else {
          pages.push(String(i))
        }
      }
      return pages
    },
    userDataLoading () {
      return store.getters.value('userDataLoading')
    }
  },
  filters: {
    count: function (val) {
      return (val >= 1000 ? 'более 1000' : val)
    }
  },
  watch: {
    startDate (newVal) {
      if (this.selectedDate.id === 5) {
        this.startDate = newVal
        this.requestData()
      }
    },
    endDate (newVal) {
      if (this.selectedDate.id === 5) {
        this.endDate = newVal
        this.requestData()
      }
    }
  },

  created () {
    apiDataStore.dispatch('clearPrintStatuses')
    if (this.$route.query.number !== '' && this.$route.query.number !== 'undefined' && this.$route.query.number !== undefined) {  // номер при шаринге
      this.query = this.$route.query.number
    }
    this.getLandPlansMix(this.query, this.exQueryString, this.limitOnPage, this.offset, false)
  },

  mounted: function () {
    store.state.activeIcon = {type: 'landPlans', id: 6}
  },

  beforeDestroy () {
    apiDataStore.dispatch('clearPrintStatuses')
  },

  methods: {
    clearInput () {
      this.query = ''
      this.requestData()
      this.autosize()
    },
    shareDoc (item) {
      this.shareDocMix(item, 'landPlans')
    },
    downloadPrinted (id) {
      let url = this.getPrintedUrl(id)
      if (url !== null) {
        // console.log(url)
        window.location.href = url
      } else {
        return false
      }
      // })
    },
    getStatus (itemId) {
      for (let i = 0; i < this.printStatuses.length; i++) {
        if (this.printStatuses[i].id === itemId) {
          return this.printStatuses[i].status
        }
      }
      return null
    },
    getPrintedUrl (docId) {
      for (let i = 0; i < this.printStatuses.length; i++) {
        if (this.printStatuses[i].id === docId) {
          return this.printStatuses[i].url
        }
      }
      return null
    },
    contextSearch () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let temStr = this.query //  замена переходов на новую строку
        if (temStr[this.query.length - 1] === '\n') {
          temStr = temStr.substring(0, temStr.length - 1) // убираем последний символ(при вставке из экселя это переход на новую строку)
        }
        temStr = temStr.replace(/\n/g, ', ')
        this.query = temStr
        this.requestData()
        this.autosize()
      }, 500)
    },
    autosize () {
      var el = document.querySelector('textarea') // textarea
      var clrBut = document.getElementById('clr-button')  //  кнопка очистить
      setTimeout(function () {
        el.style.cssText = 'height: 25px' // сделать высоты дефолтными
        clrBut.style.cssText = 'top: 25px'
        let _height = el.scrollHeight // вычислить высоту
        // console.log(_height)
        el.style.cssText = 'height:' + (_height + 3) + 'px' // изменить на новые
        clrBut.style.cssText = 'top:' + (_height > 78 ? 78 : _height) + 'px'
      }, 0)
    },
    selectDate (item) {
      if (item.id === 0) {
        this.startDate = null
        this.endDate = null
        this.showDatePicker = false
      }
      if (item.id === 1) {
        this.startDate = this.moment().format('DD.MM.YYYY')
        this.endDate = this.moment().format('DD.MM.YYYY')
        this.showDatePicker = false
      }
      if (item.id === 2) {
        this.startDate = this.moment().date(this.moment().date() - 7).format('DD.MM.YYYY')
        this.endDate = this.moment().format('DD.MM.YYYY')
        this.showDatePicker = false
      }
      if (item.id === 3) {
        this.startDate = this.moment().month(this.moment().month() - 1).format('DD.MM.YYYY')
        this.endDate = this.moment().format('DD.MM.YYYY')
        this.showDatePicker = false
      }
      if (item.id === 4) {
        this.startDate = this.moment().year(this.moment().year() - 1).format('DD.MM.YYYY')
        this.endDate = this.moment().format('DD.MM.YYYY')
        this.showDatePicker = false
      }
      if (item.id === 5) {
        this.endDate = this.moment().format('DD.MM.YYYY')
        this.showDatePicker = true
      }
      this.selectedDate = item
      this.closePopup()
      this.requestData()
    },
    closePopup () {
      this.showDatesFlag = false
    },
    showDates () {
      this.showDatesFlag = !this.showDatesFlag
    },
    checkSelect (itemId) {
      // проверяет наличие элемента в selected, если есть возвращает тру, если нет фолс(нужно для отображения галочек чекбокса)
      if (this.selected.indexOf(itemId) !== -1) {
        return true
      } else {
        return false
      }
    },

    selectAllItems () {
      // добавляет все документы с страницы в selected если allItemsSelected-фолс, иначе, "опустошает" массив selected
      if (!this.allItemsSelected) {
        this.allItemsSelected = !this.allItemsSelected
        this.rows.forEach(item => !this.selected.includes(item.id) ? this.selected.push(item.id) : null)
      } else {
        this.allItemsSelected = !this.allItemsSelected
        this.selected.splice(0, this.selected.length)
      }
    },
    checkingItem (itemId) {
      let pos = this.selected.indexOf(itemId)
      if (pos === -1) {
        this.selected.push(itemId)
      } else {
        this.selected.splice(pos, 1)
      }
    },

    setCurrentDoc: function (id, packageName) {
      this.currentDocId = id
      this.currentDocName = packageName
      // console.log(id)
    },

    clearCurrentVals: function () {
      this.currentDocId = null
      this.currentDocName = null
    },

    downloadDoc: function (id, cadNumber) {
      this.setCurrentDoc(id, cadNumber)
      this.downloadDocMix(this.currentDocId, 'source')
      this.clearCurrentVals()
    },

    downloadSelected: async function (type) {
      this.downloadPopupCheck = false
      if (this.demoModeFlag) {
        alertStore.dispatch('setValue', {key: 'offer', value: true})
      } else {
        let arr = await this.createListToArhcive(type)
        if (arr !== null) {
          alertStore.dispatch('showAlert', {type: 'success', title: 'Архивация', text: 'Начался процесс архивации скачанных документов. Пожалуйста, дождитесь его окончания'})
          apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageStateLandPlans', value: true})  // меняется после загрузки в aca()
          await aca(arr)
        } else {
          alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка', text: 'Вы не выбрали ни одного документа готового к печати'})
        }
      }
    },

    // возвращает массив который моэно отправить на архивацию
    createListToArhcive: async function (type) {
      let urls = []
      for (let i in this.selected) {
        let doc = this.getDocByIdMix(this.rows, this.selected[i])
        if (type === 'source') {
          let docUrl = await this.getDocUrlsMix(doc)
          let item = {
            cadNum: doc.cadNumber,
            date: doc.date_formation,
            type: doc.type_name,
            id: doc.id,
            url: docUrl[type]
          }
          urls.push(item)
        } else {
          if (type === 'print') {
            for (let j in this.printStatuses) { // проход по распечатаным(они получены при прогрузке страницы)
              if (this.selected[i] === this.printStatuses[j].id) {
                if (this.printStatuses[j].status === 200) {
                  this.printStatuses[j]['type'] = this.printStatuses[j].shortName
                  // console.log(this.printStatuses[j])
                  urls.push(this.printStatuses[j])
                }
              }
            }
          }
        }
      }
      return (urls.length > 0 ? urls : null)
    },

    // downloadAll: function () {
    //   this.downloadPopupCheck = false
    //   // this.multiDownloadPackageStateLandPlans = true
    //   // let _url = this.baseUrl() + httpStore.state.me + this.url + 'jump_all_docs/'
    //   let _query = '?search=' + this.query + this.exQueryString + '&only_actual=' + this.onlyActual
    //   this.downloadAllMix(_query)
    // },

    deleteDoc: function () {
      this.clearCurrentVals()
    },

    deleteDocs () {
      this.deleteDocsMix()
    },

    requestData (link) {
      apiDataStore.dispatch('clearPrintStatuses')
      this.currentPage = 1
      this.allItemsSelected = false
      this.selected.splice(0, this.selected.length)
      if (link !== false && link !== undefined) {
        this.getLandPlansMix(this.query, this.exQueryString, this.limitOnPage, this.offset, link)
      } else {
        this.getLandPlansMix(this.query, this.exQueryString, this.limitOnPage, this.offset, false)
      }
    },

    changeCurrentPage: function (page) {
      apiDataStore.dispatch('clearPrintStatuses')
      this.allItemsSelected = false
      this.selected.splice(0, this.selected.length)
      switch (page) {
        case 'next': {
          this.currentPage++
          this.getLandPlansMix(this.query, this.exQueryString, this.limitOnPage, this.offset, this.nextPage)
          break
        }
        case 'prev': {
          this.currentPage--
          this.getLandPlansMix(this.query, this.exQueryString, this.limitOnPage, this.offset, this.prevPage)
          break
        }
        case 'last': {
          this.currentPage = this.pages
          this.getLandPlansMix(this.query, this.exQueryString, this.limitOnPage, this.offset, false)
          break
        }
        case 'first': {
          this.currentPage = 1
          this.getLandPlansMix(this.query, this.exQueryString, this.limitOnPage, this.offset, false)
          break
        }
        default: {
          this.currentPage = Number(page.replace(',', ''))
          this.getLandPlansMix(this.query, this.exQueryString, this.limitOnPage, this.offset, false)
          break
        }
      }
    },

    changeLimit () {
      this.requestData()
    },
    checkPage (page) {  // проверка на совпадение страницы в списке с данной страницой
      page = Number(page.replace(',', ''))
      if (this.currentPage === page) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/sass/varibles.scss";
.wrap{
  position: relative;
  margin-bottom: 150px;
}
.icon-list-result{
  margin-top: -2px;
  margin-right: 4px;
}
.result-but-wrap{
  float: left;
}
.table-row {
  width: 100%;
}
.table{
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  margin: auto;
}
.table-row{
  border-bottom: 1px solid rgba($color: gray, $alpha: 0.3);
}
td {
  cursor: pointer;
}
th, td {
    text-align: left;
    padding: 10px;
}
.table-col-actions {
  min-width: 110px;
  // float: right;
}
.paginator-body img {
  width: 12px;
  height: 12px;
}
.paginator-button{
  float: left;
  margin-right: 5px;
  margin-left: 5px;
  outline: none;
}
.paginator-text{
  margin-left: 5px;
  margin-right: 5px;
  float: left;
}
.paginator-info-count{
  float:left;
  margin-right: 20px;
}
.overlay-popup{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.download-popup{
  position: absolute;
  width: 190px;
  height: auto;
  background-color: white;
  box-shadow: $shadow;
  z-index: 100;
}
.download-popup-elem{
  width: 100%;
  height: 50px;
  background-color: white;
  margin: 0;
  padding: 0;
  color: #333333;
  cursor: pointer;
  text-align: left;
  padding-left: 25px;
  padding-right: 25px;
}
.download-popup-elem:hover{
  background-color: $color-light;
}
.download-popup-elem.disabled, .download-popup-elem.disabled:hover{
  // background-color: rgba($color: #000000, $alpha: 0.2);
  color: rgba($color: gray, $alpha: 0.5);
  background-color: white;
  cursor: default;
}
.table-controllers {
  width: 96%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}
.table-wrapper{
  width: 96%;
  margin: auto;
}
.table-no-results{
  width: 96%;
  margin: auto;
  height: auto;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
}
.table-no-results p {
  color: #333333;
  font-size: 24pt;
}
.table-checkbox {
  display: block;
  float: left;
  width: 15px;
  height: 15px;
  margin-top: 0px;
  margin-right: 15px;
  border: 1px solid rgba($color: $color-second, $alpha: 1);
  border-radius: 2px;
  cursor: pointer;
}
.fill-checkbox{
  width: 100%;
  height: 100%;
  background-color: $color-second;
}
.paginator-wrap{
  width: 96%;
  margin:auto;
  margin-top: 20px;
}
.doc-table-row {
  background-color: rgba($color: $color-second, $alpha: .06);
}
.doc-table-row:hover {
  background-color: rgba($color: $color-second, $alpha: .1);
}
.table-row-public{
  background-color: white;
}
.table-row-public:hover {
  background-color: rgba($color: $color-light, $alpha: 1.0);
}
.table-row-header, .table-row-header:hover{
  background-color: white;
}

.download-button{
  height: 17px;
  width: 17px;
  margin-top: 3px;
  margin-left: 6px;
  outline: none;
  // float: left;
}
.status-item-block-stub {
  height: 30px;
  width: 30px;
  outline: none;
  border-radius: 49%;
  float: left;
}
.status-item-block {
  height: 30px;
  width: 30px;
  outline: none;
  border-radius: 49%;
  float: left;
}
.status-item-block:hover {
  border-radius: 49%;
  background-color: rgba($color: $color-first, $alpha: .1);
}
.spinner {
  width: 96%;
  margin: auto;
  height: auto;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
}
.spinner img {
  width: 400px;
  height: 400px;
}
.select-count-document-on-page {
  float: right;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 60px;
}
.selector-count-documents {
  width: 50px;
  // display: inline-block;
  margin-top: 0!important;
  font-weight: bolder;
}
.context-search {
  width: inherit;
  text-align: center !important;
  height: 60px;
  margin-bottom: 90px;
}
.context-input {
    margin: auto;
    margin-top: 25px;
    margin-bottom: 10px;
    width: 60%;
    overflow-y: auto;
    max-height: 78px;
    resize: none;
}
.download-button-wrap {
  float: right;
  // position: relative;
  // margin-right: 50px;
}
.date-selector {
  position: absolute;
  background-color: white;
  box-shadow: $shadow;
  z-index: 105;
}
.download-button-disabled, .download-button-disabled:hover {
  background-color: rgba($color: gray, $alpha: 0.5);
  color: white;
  cursor: default;
}
.date-selected {
  width: auto;
  color: rgba($color: #333333, $alpha: 0.7);
}
.date-item {
  margin: 0;
  padding: 12px;
  cursor: pointer;
}
.date-item:hover {
  background-color: $color-light;
}
.date-picker {
  display: flex;
  // width: calc(100% + 20px);
  width: 350px;
  // margin-top: 50px;
  // z-index: 110;
  background-color: white;
  padding: 10px;
}
.select-date {
  background-color: white;
  cursor: pointer;
  float: left;
}
.date-selector-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
}
.page-selector {
  max-width: 500px;
  float: left;
}
.page-selector-item {
  cursor: pointer;
  padding-right: 5px;
}
.selected-page {
  font-weight: bold;
}
.clear-field {
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: rgba($color: gray, $alpha: 0.5);
  position: absolute;
  right: 22%;
  top: 25px;
}
.clear-field img {
  width: 20px;
  height: 20px;
  padding: 5px;
  margin-top: -6.5px;
}
</style>

