import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// файл настройки прилодения
const store = new Vuex.Store({
  state: {
    ORDER_IS_ACTIVE: true, // true - все ок, false - росреестр упал при нажатии на заказ выведется сообщение
    SHOW_PRISES: false, // показывать цены (на данный момент это только цена выписки)
    DEMO_IS_ACTIVE: true  //  true - демо режим есть. В него входит, когда не залогиен. flase - демо режим выкл. Если что-то не так, то вместо логина за демо пользователя бросает на логин
  },
  mutations: {
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    }
  },
  actions: {
    setValue ({commit}, keyValue) {
      commit('setValue', keyValue)
    }
  },
  getters: {
    value: state => key => {
      return state[key]
    },
    settings: state => {
      return state
    }
  }
})

export default store
