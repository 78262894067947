<template>
    <div class="login-wrapper">
        <h3>Вход</h3>
          <div v-if="loginLoading || loadingSendEmail" class="loading-send-email">
            <img src="../../assets/img/rrd_logo_test.svg" alt="загрузка окна входа">
          </div>
          <form @submit="submit($event)">
          <div class="login-form" v-if="!loginLoading && !loadingSendEmail">

            <input class="input" type="text" v-model="logIn" required placeholder="Email" autofocus>
            <input id="passwordInput" class="input" type="password" v-model="password" required placeholder="Пароль">

            <div class="but-wrap">
              <button class="button button-primary button-enter" type="submit" @click="submit($event)">Вход</button>
            </div>
          </div>
          <!-- <div v-else class="login-loading">
            <img src="../../assets/img/rrd_logo_test.svg" alt="spinner">
          </div> -->
          </form>
          <div class="reset-password-main-block" v-if="!loadingSendEmail && !loginLoading">
            <button class="button button-accent" @click="showResetPassword">Забыли пароль?</button>
            <div class="password-reser-popup-block" v-if="showResetPasswordFlag">
              <input type="text" v-model="mailReset" @input="testEmail()" class="input" placeholder="Введите свою почту">
              <button class="button" @click="resetPassword()" v-bind:class="{'button-disabled': !emailOk , 'button-primary': emailOk}" :disabled="!emailOk">Восстановить</button>
            </div>
          </div>
        </div>
</template>

<script>
// storage facilities
import store from '../../store/store'
import apiDataStore from '../../store/apiDataStore'
import alertStore from '../../store/AlertStore'

let rexExpEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/

export default {
  name: 'SignIn',
  data () {
    return {
      logIn: '',
      password: '',
      emptyAlert: false,
      errorAlert: false,
      loginResult: true,
      showResetPasswordFlag: false,
      mailReset: '',
      emailOk: false
    }
  },
  computed: {
    loginLoading () {
      return store.state.loginLoading
    },
    loadingSendEmail () {
      return apiDataStore.getters.value('loadingSendEmail')
    }
  },
  mounted: function () {
    if (this.$router.history.current.path === '/confirm_email_success') {
      alertStore.dispatch('showAlert', {type: 'success', title: 'Email успешно подтвержден', text: 'Вы успешно подтвердили почту, теперь ипользуйте её и указанный пароль, чтобы войти.', showAbort: false})
      this.$router.push('/login')
    }
    if (store.getters.value('rememberPrevLogin') !== '') {  // если был предыдущий логин, то внести его, чтобы не вводить заного после пересоздания элемента при неправильном логине или пароле
      this.logIn = store.getters.value('rememberPrevLogin')
      document.getElementById('passwordInput').focus()  // фокус на поле пароля
    }
  },
  methods: {
    testEmail () {
      this.emailOk = rexExpEmail.test((this.mailReset).toLowerCase())
    },
    resetPassword () {  //  делает запрос на восстановление, "/rest-auth/password/reset/" data = {"email": str}
      if (this.emailOk) {
        this.resetPasswordMix()
        this.showResetPasswordFlag = false
      }
    },
    showResetPassword () {
      this.showResetPasswordFlag = !this.showResetPasswordFlag
    },
    onConfirm: function () {
      this.errAlert = false
    },
    submit: function (e) {
      // store.state.authError = false
      store.dispatch('setValue', {key: 'rememberPrevLogin', value: this.logIn})
      if (this.logIn === '' || this.password === '') {
        console.log('empty fields')
        this.emptyAlert = true
      } else {
        let _data
        _data = '{"password": "' + this.password + '", "email": "' + this.logIn + '"}'

        // request
        this.login(JSON.parse(_data))
        e.preventDefault()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/sass/varibles.scss";
.login-wrapper{
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 20vh;
}
.login-wrapper h3 {
  color: rgba($color: #000000, $alpha: .5);
  margin-left: 10px;
}
.but-wrap{
  width: 100%;
  height: auto;
  text-align: center
}
.errorResult{
  position: fixed;
  padding: 25px 20px;
  width: 550px;
  height: auto;
  box-shadow: 1px 1px 7px 1px rgba($color: #000000, $alpha: .2);
  top: 50%;
  left: 50%;
  margin-left: -275px;
  margin-top: -150px;
  background-color: white;
  z-index: 99;
}
.errorResultText {
  font-size: 12pt;
}
.overlay-error-result{
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left:0;
  background-color: $color-overlay;
  z-index: 9;
}
.button-close-error-result{
  margin-bottom: 0;
  float: right;
}
.errorResultTitle{
  font-size: 16pt;
}
//pass reset syle
.reset-password-main-block {
  text-align: center;
  // margin-top: 50px;
}
.loading-send-email {
  width: 100%;
  height: 194px;
  text-align: center;
}
.loading-send-email img {
  width: 100%;
  height: 100%;
}
.login-loading {
  width: 500px;
  height: 142px;
  margin-left: 148px;
}
.login-loading img {
  width: 200px;
  height: 200px;
}
</style>
