import httpStore from '../store/httpStore'
import userStore from '../store/userStore'
import balanceStore from '../store/BalanceStore'

export default {
  data () {
    return {
      balanceUrl: null,
      _urlPrice: null,
      oprationsNames: null,
      _canOrder: null
    }
  },

  methods: {
    // запрашивает баланс, цены на операции, бонусы
    getAllUserBillingInfoMix: async function () {
      await this.getUserBalance()
      await this.getPrices()
      await this.getBonusesInfo()
    },
    // запрашивает баланс пользователя
    getUserBalance () {
      return new Promise(resolve => {
        if (!userStore.getters.value('demoMode')) {
          this.balanceUrl = httpStore.state.host + httpStore.state.api + '/billing/balance/' //  http://host:port/api/v1/billing/balance/
          this.$http.get(this.balanceUrl).then(response => {
            // console.log(response.body)
            balanceStore.state.dateUpdated = response.body.date_updated
            balanceStore.state.amountBalance = response.body.amount_balance
            resolve(true)
          }).catch(error => {
            console.log(error.status)
            balanceStore.state.dateUpdated = 'error'
            balanceStore.state.amountBalance = 'error'
            resolve(false)
          })
        }
      })
    },
    getPrices () {  // заполняет balanceStore ценниками
      return new Promise((resolve) => {
        this.oprationsNames = balanceStore.getters.operations
        this._urlPrice = httpStore.getters.host + httpStore.getters.value('api') + httpStore.getters.value('getPrice')
        for (let key in this.oprationsNames) {
          this.$http.get(this._urlPrice + key).then(response => {
            balanceStore.dispatch('setPrice', {'name': key, 'value': response.body.result})
            resolve(true)
          }).catch(err => {
            console.log(err.status)
            resolve(false)
          })
        }
      })
    },
    // запрашивает бонусы
    getBonusesInfo () {
      return new Promise((resolve) => {
        this.$http.get(httpStore.getters.host + httpStore.getters.value('getBonuses')).then(response => {
          balanceStore.state.bonusesInfo = response.data
          resolve(true)
        }).catch(error => {
          this.errors.push(error)
          console.log(error)
          resolve(false)
        })
      })
    }
  }
}
