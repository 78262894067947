<template>
  <div id="notfound">
    <h1 class="md-display-4">404</h1>
    <h2 class="md-display-3">Запрашиваемая страница не найдена</h2>
    <!--<router-link to="/">-->
    <router-link to="/">
      Вернуться на главную
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'Page404Component'
  }
</script>

<style lang="scss">
  #notfound {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
