<template>
  <div class="password-reset-popup">
    <h4>Изменение пароля</h4>
    <div class="password-reset-content">
      <div>
        <input type="password" class="input password-reset-input" @keyup="checkInput()" v-model="newPassword" minlength="8" placeholder="Новый пароль">
        <img src="../../assets/img/select.svg" alt="пароли совпадают" class="password-reset-requirements-succeess" v-if="requirements[1].ok === true">
      </div>
      <div>
        <input type="password" class="input password-reset-input" @keyup="checkInput()" v-model="newPasswordRepeat" minlength="8" placeholder="Повторите новый пароль">
        <img src="../../assets/img/select.svg" alt="пароли совпадают" class="password-reset-requirements-succeess" v-if="requirements[1].ok === true">        
      </div>
    </div>
    <div class="password-requirements">
      <div class="password-reset-requirements-item">Длина пароля должна быть больше 8 символов </div>
      <div class="password-reset-requirements-item">Проль не должен состоять только из цифр </div>
      <!-- <div class="password-requirements-item" v-for="item in requirements" v-bind:key="item.id" v-if="!item.ok">{{ item.text }}</div> -->
    </div>
    <div class="password-reset-buttons">
      <button class="button"  v-bind:class="{'button-disabled': !passwordsOK,  'button-primary': passwordsOK}" :disabled="!passwordsOK" @click="resetPssword()">Изменить</button><br>
      <router-link to="/login">
        <button class="button button-primary">На страницу входа</button>
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'resetPasswordComponent',
  data () {
    return {
      requirements: [
        {id: 'length', ok: false, text: 'Длина пароля должна быть больше 8 символов'},
        {id: 'equals', ok: false, text: 'Пароли не совпадают'}
      ],
      newPassword: '',
      newPasswordRepeat: '',
      passwordsOK: false,
      uID: null,
      token: ''
    }
  },
  mounted () {  //  вычленяет из урла токен и юид
    let url = this.$route.params.privateLink
    let posTkn = url.lastIndexOf('-', url.lastIndexOf('-') - 1)  //  позиция предпоследнего символа "-"
    this.token = url.substring(posTkn + 1)
    let id = url.substring(0, posTkn)
    id = parseInt(id, 36)
    id = btoa(id)
    if (id.indexOf('=') !== -1) {
      this.uID = id.substring(0, id.indexOf('='))
    } else {
      this.uID = id
    }
  },
  methods: {
    // goLogin () {
    //   this.$router
    // },
    checkInput () {
      if (this.newPassword.length < 8) {
        this.requirements[0].ok = false
      } else {
        this.requirements[0].ok = true
      }
      if ((this.newPassword !== this.newPasswordRepeat) || (this.newPassword === '')) {
        this.requirements[1].ok = false
      } else {
        this.requirements[1].ok = true
      }
      this.passwordsOK = true
      for (let i = 0; i < this.requirements.length; i++) {
        if (this.requirements[i].ok === false) {
          this.passwordsOK = false
        }
      }
    },
    resetPssword () { //  /rest-auth/password/reset/confirm/
      let data = {
        'new_password1': this.newPassword,
        'new_password2': this.newPasswordRepeat,
        'token': this.token,
        'uid': this.uID
      }
      // this.$http.post(_urlReset, JSON.stringify(data)).then(response => {
      //   if (response.status === 200) {
      //     alertStore.dispatch('showAlert', {type: 'success', title: 'Успешно', text: response.body.detail})
      //     this.$router.push('/login')
      //   }
      // }).catch(err => {
      //   console.log(err)
      //   if (err.body.token !== undefined) {
      //     alertStore.dispatch('showAlert', {title: 'Не удалось сменить пароль', text: 'Токен не действителен, попробуйте выслать себе еще одно письмо'})
      //     this.$router.push('/login')
      //   } else {
      //     if (err.body.new_password2 !== undefined) {
      //       alertStore.dispatch('showAlert', {title: 'Не удалось сменить пароль', text: err.body.new_password2[0]})
      //     }
      //   }
      // })
      this.resetPsswordMix(data)
    }
  }
}
</script>

<style scoped lang="scss">
.password-reset-popup {
  width: calc(50% - 50px);
  margin-left: calc(30% - 50px);
  height: calc(50% - 60px);
  margin-top: 10%;
}
.password-reset-content {
  width: 80%;
  padding-bottom: 20px;
  padding-top: 40px;
  text-align: center;
  vertical-align: middle;
}
.password-reset-buttons {
  width: 80%; // как и у password-reset-content
  text-align: center;
}
.password-reset-requirements-item {
  font-weight: 600;
  // padding-left: 20px;
  padding-right: 20px;
  margin: 10px 0;
  // float: left;
}
.password-reset-requirements-succeess {
  width: 16px;
  height: 16px;
  float: right;
  margin-top: -30px;
}

</style>

