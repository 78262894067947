import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    actionsForDoc: [], //  список действий над документом dialogWindow.vue
    documentActionComplete: [],
    // documentActionCompleteMix: { // статус действий (печатали, не печатали) и ссылка на скачивание, если успешно
    //  его структура
    //   [
    //     name: action code
    //     status: 0,
    //     url: null
    //   ]
    // },
    typeList: [],  // тип документов для расширенного поиска  DocumentExtendedSearch.vue
    totalPrice: 0,  //  ResultSearch.vue, Ordering.vue
    hasEnoughMoney: true,  //  ResultSearch.vue, Ordering.vue
    multiDownloadPackageState: false, // крутилка-перделка для "скачать выбранные"
    multiDownloadPackageStateLandPlans: false, // крутилка-перделка для "скачать выбранные"
    loadingSendEmail: false, //  крутилка при восстановлении пароля с страницы логина
    loadingRegister: false, //  крутилка при регистрации
    filtredProjects: [], // список проектов при заказе документов
    ordered: [], // список заказанных документов
    loadingOrderData: false, //  загрузка при получении списка заказанных документов  Ordering.vue
    countOrdered: 0,  //  количество заказанных документов удвлетворяющих фильтру (внизу страницы надпись "Всего заказов: 999") Ordering.vue
    pageCountOrdered: 0, // сколько страниц нужно для отображения всех заказанных доументов (страница 3 из 100500) Ordering.vue
    orderLoading: false, // загрузка при заказе докуентов(order.vue)
    printStatuses: [],
    uploadedRows: [],
    multiDownloadPackageStateUploads: false, // крутилка на мультизагрузку компонента uploaded.vue
    multiDownloadPackageStateDocumentList: false, // крутилка на мультизагрузку компонента DocumentList.vue
    loadingActionsForDocMix: false,  // загрузка действий в documentWindow.vue
    loadingUpdateTariffMix: false,
    loadingActionForSelected: false, // загрузка действий для выбранных
    loadingSendToProcessesListMix: false // загрузка отправки запросов на отправление в процесс выбранных документов
  },
  mutations: {
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    },
    clearDocumentActionComplete (state) {
      let len = state.documentActionComplete.length
      state.documentActionComplete.splice(0, len)
    },
    clearActionsForDoc (state) {
      state.actionsForDoc = []
    },
    addPrintStatus (state, status) {
      if (!state.printStatuses.includes(status)) {
        state.printStatuses.push(status)
      }
    },
    clearPrintStatuses (state) {
      state.printStatuses.splice(0, state.printStatuses.length)
      // console.log(state.printStatuses)
    }
  },
  actions: {
    //  установить пару ключ: значение (keyValue - {key: 'key', value: 'value'})
    setValue ({commit}, keyValue) {
      commit('setValue', keyValue)
    },
    clearDocumentActionComplete ({commit}) {
      commit('clearDocumentActionComplete')
    },
    clearActionsForDoc ({commit}) {
      commit('clearActionsForDoc')
    },
    addPrintStatus ({commit}, status) {
      commit('addPrintStatus', status)
    },
    clearPrintStatuses ({commit}) {
      commit('clearPrintStatuses')
    }
  },
  getters: {
    actionsForCurrentDoc: (state) => {
      return state.actionsForDoc
    },
    value: state => key => {
      return state[key]
    }
  }
})

export default store
