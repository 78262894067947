<template>
    <div class="login-wrapper">
        <h3>Регистрация</h3>
        <div v-if="loadingRegister" class="loading-register">
            <img src="../../assets/img/rrd_logo_test.svg" alt="загрузка окна регистрации">
          </div>
        <form @submit="submit($event)" v-if="!loadingRegister">
        <div class="login-form">
            <!-- <input class="input" type="text" minlength="3" v-model="query.lastName" required placeholder="Фамилия">
            <input class="input" type="text" minlength="3" v-model="query.firstName" required placeholder="Имя"> -->
            <input class="input" v-on:input="checkInput()" type="email" v-model="query.email" required placeholder="Email">
            <input class="input" v-on:input="checkInput()" type="password" minlength="8" v-model="query.pass" required placeholder="Пароль">
            <input class="input" v-on:input="checkInput()" type="password" minlength="8" v-model="query.repeatPass" required placeholder="Повторите пароль">
            <!-- <img class="passwordValid" src="../../assets/img/select.svg" v-if="validateConfirmPassword">
            <img class="passwordValid2" src="../../assets/img/select.svg" v-if="validateConfirmPassword"> -->
            <p class="password-truth">пароль должен содержать не менее 8 знаков</p>
            <p class="password-truth">пароль не должен состоять только из цифр</p>

            <div class="license-block">
              <span class="license-checkbox" @click="acceptLicense = !acceptLicense">
                <div class="fill-checkbox" v-show="acceptLicense"></div>
              </span>
              <input type="checkbox" v-model="acceptLicense" class="hidecheckbox">
              <a target="_blank" :href="licenseURL">
                Я принимаю условия пользовательского соглашения
              </a>
            </div>

          <div class="but-wrap">
            <button role="button" type="submit" class="button" v-bind:class="{'button-disabled': !requirementsOK || !acceptLicense , 'button-primary': requirementsOK && acceptLicense}" :disabled="!requirementsOK || !acceptLicense">Регистрация</button>
          </div> 
        </div>
        </form>
    </div>
</template>

<script>
// storage facilities
import apiDataStore from '../../store/apiDataStore'
import httpStore from '../../store/httpStore'

let rexExpEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/

export default {
  name: 'SignUp',
  data () {
    return {
      isBoss: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      errorAlertBody: {},
      emptyAlert: false,
      emailInvalid: false,
      passInvalid: false,
      repeatPassInvalid: false,
      acceptLicense: false,
      // loadingRegister: false,
      query: {
        isBoss: false,
        firstName: 'default',
        lastName: 'default',
        email: '',
        username: '',
        pass: '',
        repeatPass: '',
        company: '',
        inn: ''
      },
      requirements: [
        {id: 'length', ok: false, text: 'Длина пароля должна быть больше 8 символов'},
        {id: 'equals', ok: false, text: 'Пароли не совпадают'},
        {id: 'email', ok: false, text: 'Введите настоящий емейл'}
      ],
      requirementsOK: false
    }
  },
  computed: {
    validateConfirmPassword () {
      if (this.query.pass === this.query.repeatPass && this.query.pass.length > 7) {
        return true
      }
      return false
    },
    successRegisterFlag () {
      return apiDataStore.getters.value('successRegisterFlag')
    },
    loadingRegister () {
      return apiDataStore.getters.value('loadingRegister')
    },
    licenseURL () {
      return httpStore.getters.host + '/terms-of-use'
    }
  },
  methods: {
    checkInput () {
      this.requirementsOK = true
      if (this.query.pass.length < 8 || this.query.repeatPass.length < 8) {
        this.requirements[0].ok = false
      } else {
        this.requirements[0].ok = true
      }
      if (this.query.pass !== this.query.repeatPass) {
        this.requirements[1].ok = false
      } else {
        this.requirements[1].ok = true
      }
      this.requirements[2].ok = rexExpEmail.test((this.query.email).toLowerCase())
      for (let i = 0; i < this.requirements.length; i++) {
        if (this.requirements[i].ok === false) {
          this.requirementsOK = false
        }
      }
    },
    createRequestBody: function () {
      let _query = '{'
      _query += '"first_name": "' + this.query.firstName + '", '
      _query += '"last_name": "' + this.query.lastName + '", '
      _query += '"email": "' + this.query.email + '", '
      _query += '"username": "' + this.query.email + '", '
      _query += '"password1": "' + this.query.pass + '", '
      _query += '"password2": "' + this.query.repeatPass + '", '
      _query += '"is_boss": ' + this.query.isBoss + ''
      if (this.isBoss) {
        _query += ', "company": {"name_organization": "' + this.query.company + '"}'
      }
      _query += '}'
    //   console.log(_query)
      return _query
    },
    submit: function (e) {
      let _query = this.createRequestBody()
      this.registrationMix(_query)
      e.preventDefault()
    },

    validateInput: function (index) {
      switch (index) {
        case 0: {
          if (!this.query.email.includes('@') && this.query.email !== '') {
            this.emailInvalid = true
          }
          break
        }
        case 1: {
          if (this.query.pass.length < 8 && this.query.pass !== '') {
            this.passInvalid = true
          }
          break
        }
        case 2: {
          if (this.query.pass !== this.query.repeatPass && this.query.repeatPass !== '') {
            this.repeatPassInvalid = true
          }
          break
        }
      }
    },

    resetValidation: function (index) {
      switch (index) {
        case 0: {
          if (this.emailInvalid) {
            this.emailInvalid = false
          }
          break
        }
        case 1: {
          if (this.passInvalid) {
            this.passInvalid = false
          }
          break
        }
        case 2: {
          if (this.repeatPassInvalid) {
            this.repeatPassInvalid = false
          }
          break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// @import '../../assets/css/checkbox.css';
@import "../../assets/sass/varibles.scss";
.login-wrapper{
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 20vh;
}
.login-wrapper h3 {
  color: rgba($color: #000000, $alpha: .5);
  margin-left: 10px;
}
.but-wrap{
  width: 100%;
  height: auto;
  text-align: center
}
.res-register{
  position: fixed;
  width: 600px;
  left: 50%;
  top: 50%;
  margin-top: -200px;
  margin-left: -310px;
  height: auto;
  box-shadow: 1px 1px 7px 1px rgba($color: #000000, $alpha: .2);
  background-color: white;
  padding: 25px 20px;
  z-index: 99;
}
.res-register p {
  text-align: center;
  font-size: 12pt;
}
.res-register button {
  float: right;
}
.overlay-result-register{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: $color-overlay;
  z-index: 9;
}
.passwordValid{
  position: absolute;
  width: 16px;
  height: 16px;
  object-fit: cover;
  margin-left: 470px;
  margin-top: -31px;
}
.passwordValid2 {
  position: absolute;
  width: 16px;
  height: 16px;
  object-fit: cover;
  margin-left: 470px;
  margin-top: -76px;
}
.error-register p{
  text-align: left;
  padding-left: 12px;
}
.detail-error-register-p{
  color: $color-accent;
}
.loading-register {
  width: 100%;
  height: 251px;
  text-align: center;
}
.loading-register img {
  width: 100%;
  height: 100%;
}
.password-truth {
  line-height: 1;
  margin-top: 10px;
  margin-left: 20px;
  font-weight: 300;
}
.license-checkbox {
  display: block;
  float: left;
  width: 15px;
  height: 15px;
  margin-top: 3px;
  margin-right: 15px;
  border: 1px solid rgba($color: $color-second, $alpha: 1);
  border-radius: 2px;
  cursor: pointer;
}
.fill-checkbox{
  width: 100%;
  height: 100%;
  background-color: $color-second;
}
.license-block {
  margin-left: 10px;
  margin-bottom: 10px;
}
</style>
