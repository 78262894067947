<template>
  <div>
    <div class="action-dialog-wrapper">
        <button @click.stop="showDialog" class="button-round button-main-action">
          <img v-if="loadingActions" src="../../../assets/img/spinner-blue.svg" title="Действя для документа">
          <img v-else src="../../../assets/img/more.svg" title="Действя для документа">
        </button>
        <!-- действия -->
      <transition name="fade">
      <div class="popup-actions animated fadeInDown faster" v-if="currentItemAction == true && actionsForDoc.length > 0 && !loadingActionsForDoc">
        <div v-for="action in actionsForDoc" :key="action.id" class="actions-animation animated fadeIn faster">
          <button v-if="!isGis(action.code)" class="button-action"
                  @mouseover="showGisMenuFlag = false"
                  v-bind:class="{'disabled-action': !action.is_permitted, 'no-click': actionHaveStatuses(action.code, [202, 500]), 'button-action-hover': actionHaveStatuses(action.code, [0])}" 
                  :disabled="!action.is_permitted || actionHaveStatuses(action.code, [202, 500])">
            <div class="action-name" @click.stop="beforeSubmit(action)">
              {{ action.name }}
            </div>

            <div class="status-wrapper" v-if="actionHaveStatuses(action.code, [200, 500]) && action.is_permitted" title="Обработать повторно">
              <img @click.stop="sendToProcessAgain(action)" src="../../../assets/img/refresh.svg" alt="отправить документ в обработку повторно" class="refresh-img">
            </div>
            <div class="status-wrapper" v-if="actionHaveStatuses(action.code, [200, 202, 500]) && action.is_permitted">
              <img src="../../../assets/img/download.svg" title="Скачать обработанный документ" alt="Скачать напечатанный документ .pdf" class="status-img" @click.stop="downloadDocDirectly(action)" v-if="actionHaveStatuses(action.code, [200]) && action.is_permitted">
              <img src="../../../assets/img/gears.svg" title="Файл в обработке" alt="Идет процесс печати документа" class="status-img" v-else-if="actionHaveStatuses(action.code, [202])">
              <img src="../../../assets/img/warning.svg" title="Ошибка обработки файла" alt="Ошибка печати документа" class="status-img" v-else-if="actionHaveStatuses(action.code, [500])">
            </div>
          </button>
        </div>
        <button v-if="countGisActions > 0" class="button button-action" @mouseover="showGisMenuFlag = true">
          <div class="action-name" title="Эти действия предназанчены для работы с программой GIS">
            в ГИС
          </div>
          <div class="status-wrapper-gis">
            <img src="../../../assets/img/list-blue.svg" class="gis-img">
          </div>
        </button>
      </div>
      <div class="popup-actions animated fadeInDown faster" v-else-if="currentItemAction == true && actionsForDoc.length == 0 && !loadingActionsForDoc">
        <div class="no-actions no-click">нет действий</div>
      </div>
      </transition>
      <!-- конец действия -->
      <!-- действия ГИС -->
        <div v-show="currentItemAction && showGisMenuFlag" 
          class="popup-actions-gis animated fadeInDown faster"
          :class="[computedStyleGisActionsTop]">
          <!-- <div style="height: 40px" v-for="fakeAction in actionsForDoc" :key="fakeAction.id" v-show="!isGis(fakeAction.code)"></div> -->
          <div v-for="action in actionsForDoc" :key="action.id" class="actions-animation animated fadeIn faster">
            <button v-if="isGis(action.code)" class="button-action" 
                  v-bind:class="{'disabled-action': !action.is_permitted, 'no-click': actionHaveStatuses(action.code, [202, 500]), 'button-action-hover': actionHaveStatuses(action.code, [0])}"
                  :disabled="!action.is_permitted || actionHaveStatuses(action.code, [202, 500])">
                  <div class="action-name"  @click.stop="beforeSubmit(action)">
                    {{ action.name }}
                  </div>
              <div class="status-wrapper" v-if="actionHaveStatuses(action.code, [200, 500]) && action.is_permitted" title="Обработать повторно">
                <img @click.stop="sendToProcessAgain(action)" src="../../../assets/img/refresh.svg" alt="отправить документ в обработку повторно" class="refresh-img">
              </div>
              <div class="status-wrapper" v-if="actionHaveStatuses(action.code, [200, 202, 500]) && action.is_permitted">
                <img src="../../../assets/img/download.svg" title="Скачать обработанный документ" alt="Скачать обработанный документ" class="status-img" @click.stop="downloadDocDirectly(action)" v-if="actionHaveStatuses(action.code, [200]) && action.is_permitted">
                <img src="../../../assets/img/gears.svg" title="Файл в обработке" alt="Файл в обработке" class="status-img" v-else-if="actionHaveStatuses(action.code, [202])">
                <img src="../../../assets/img/warning.svg" title="Ошибка обработки файла" alt="Ошибка обработки файла" class="status-img" v-else-if="actionHaveStatuses(action.code, [500])">
              </div>
            </button>
          </div>
        </div>
        <!-- конец действия ГИС -->
    </div>
    <div class="overlay-popup-action" @click.stop="closePopupActions()" @mouseover="showGisMenuFlag = false" v-if="currentItemAction == true"></div>
  </div>
</template>

<script>
// storage facilities
import apiDataStore from '../../../store/apiDataStore'
import alertStore from '../../../store/AlertStore'
import userStore from '../../../store/userStore'

export default {
  data () {
    return {
      currentItemAction: false,
      gisCodes: ['mapinfo', 'shape', 'kml', 'geojson'],
      longHumanNames: ['owner'],
      showGisMenuFlag: false,
      loadingActions: false
    }
  },
  computed: {
    computedStyleGisActionsTop () { // выбор стиля для отступа выпадающего меню("в ГИС") сверху (вычисляемый стиль перестал, после собрки)
      return 'gis-computed-top-margin-style-' + this.nonGisActionsCount
    },
    // computedStyleGisActionsleft () {  // выбор стиля для отступа справа выпадающего меню "в ГИС"
    //   let _className = 'gis-computed-left-style'
    //   let _haveLongHumanName = false
    //   let _haveIcons = false
    //   let _haveIconsOnLongName = false
    //   for (let i in this.documentActionComplete) {
    //     if (this.longHumanNames.includes(this.documentActionComplete[i].name)) {  // есть ли длинное имя
    //       _haveLongHumanName = true
    //     }
    //     if (!this.isGis(this.documentActionComplete[i].name) && this.documentActionComplete[i].status !== 0) {  // если не гис и статус не 0 (т.е. что-то делали)
    //       _haveIcons = true
    //       if (this.longHumanNames.includes(this.documentActionComplete[i].name)) {  // если что-то делали, то проверить что это не с длинным именем что-то делали
    //         _haveIconsOnLongName = true
    //       }
    //     }
    //   }
    //   if (_haveIconsOnLongName) {
    //     _className += '-with-icons-on-long-name'
    //   }
    //   if (_haveLongHumanName && !_haveIconsOnLongName) {
    //     _className += '-with-long-name'
    //   }
    //   if (_haveIcons && !_haveIconsOnLongName) {
    //     _className += '-with-icons'
    //   }
    //   return _className
    // },
    actionsForDoc () {
      return apiDataStore.getters.actionsForCurrentDoc
    },
    documentActionComplete () {
      return apiDataStore.getters.value('documentActionComplete')
    },
    demoModeFlag () {
      return userStore.getters.value('demoMode')
    },
    countGisActions () {
      let count = 0
      for (let item in this.actionsForDoc) {
        if (this.isGis(this.actionsForDoc[item].code)) {
          count++
        }
      }
      return count
    },
    nonGisActionsCount () {
      return this.actionsForDoc.length - this.countGisActions
    },
    loadingActionsForDoc () {
      return apiDataStore.getters.value('loadingActionsForDocMix')
    }
  },
  props: ['item'],  //  получаю выбранный документ из documentList
  methods: {
    sendToProcessAgain (action) {
      this.submit(action.id)
      this.closePopupActions()
    },
    isGis (actionCode) {
      return this.gisCodes.includes(actionCode)
    },
    actionHaveStatuses (actionCode, StatusCodes) {
      for (let i = 0; i < this.documentActionComplete.length; i++) {
        if (this.documentActionComplete[i].name === actionCode && StatusCodes.includes(this.documentActionComplete[i].status)) {
          return true
        }
      }
      return false
    },
    getUrl (actionCode) {
      for (let i = 0; i < this.documentActionComplete.length; i++) {
        if (this.documentActionComplete[i].name === actionCode) {
          return this.documentActionComplete[i].url
        }
      }
      return null
    },
    downloadDocDirectly (action) {
      let _urlDownloadDirectly = this.getUrl(action.code)
      // console.log(_urlDownloadDirectly)
      if (_urlDownloadDirectly !== null) {
        window.location.href = _urlDownloadDirectly
      }
    },
    beforeSubmit (action) { //  выполняется на выбор действия, либо скачивает, либо отправляет в обработку
      // console.log(action)
      if (this.demoModeFlag) {
        alertStore.dispatch('setValue', {key: 'offer', value: true})
        this.closePopupActions()
      // } else if (this.actionHaveStatuses(action.code, [200])) { //  если все хорошо, скачиваем вместо действия
      //   this.downloadDocDirectly(action)
      } else if (this.actionHaveStatuses(action.code, [0])) { // если не было ранее попыток, то выполнить действие
        this.submit(action.id)
        this.closePopupActions()
      } //  ничего, если не то не то(т.е. док в обработке или в ошибке)
    },
    submit: function (actionid) {
      this.sendToProcessesMix(actionid, this.item)
      this.currentItemAction = false
    },

    getActions: function () { //  запрос допустимых действий для данного документа.
      return this.getActionsMix(this.item)
    },

    closePopupActions () {
      this.currentItemAction = false
      apiDataStore.dispatch('clearDocumentActionComplete')
      apiDataStore.dispatch('clearActionsForDoc')
    },
    showDialog () {
      this.loadingActions = true
      this.getActions().then(() => {
        this.$emit('setCurrentDoc')
        this.currentItemAction = true
        this.loadingActions = false
      })
    },
    downloadDocument () {
      this.$emit('downLoad')
      this.currentItemAction = false
    },
    delDoc () {
      this.$emit('delDoc')
      this.currentItemAction = false
    }
  }
}
</script>

<style lang="scss" scoped>

@import "../../../assets/sass/varibles.scss";
@import "../../../assets/sass/vue2-animate.scss";

.action-dialog-wrapper {
  width: 30px;
  height: 30px;
  float: left;
}
.popup-actions {
  // min-width: 170px;
  height: auto;
  position: absolute;
  background-color: white;
  z-index: 100;
  border-radius: 2px;
  box-shadow: 1px 1px 7px 1px rgba($color: #333333, $alpha: .2);
  margin-top: -10px;
  // margin-left: -115px;
  right: 90px;
}
.overlay-popup-action{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  cursor: default;
}
.popup-actions-gis {
  @extend .popup-actions;
  position: absolute;
  max-width: 170px;
  // left: -260px;
  // margin-top: 15px;
  // margin-left: -275px;
  right: 156px;
}
.button-main-action:hover {
  background-color: rgba($color: $color-accent, $alpha: .1);
}
.button-action{
  width: 100%;
  margin: 0px;
  min-height: 40px;
  text-align: left;
  cursor: default;
}
.button-action:active {
  background-color: white;
}
.button-action-hover:hover {
  background-color: $color-light;
  cursor: pointer;
}
.action-name {
  height: 100%;
  max-width: 130px;
  margin: 0;
  padding: 7px 10px;
  text-align: left!important;
  display: inline-block;
  // cursor: default;
  white-space: normal;
}
.status-wrapper {
  // padding: 8px;
  margin: 0;
  float: right;
  padding: 6px;
  cursor: pointer;
  height: 100%;
  min-height: 40px;
  text-align: center;
  width: 40px;
}
.status-wrapper-gis {
  @extend .status-wrapper;
  cursor: default;
}
.status-wrapper-gis:hover {
  background-color: white!important;
  cursor: default;
}
.status-wrapper:hover {
  background-color: $color-light;
}
.status-img {
  width: 17px;
  height: 17px;
}
.refresh-img {
  @extend .status-img;
}
.actions-animation {
  overflow: hidden;
}
.disabled-action, .disabled-action:hover {
  color: rgba($color: gray, $alpha: 0.5);
  background-color: white;
  cursor: default;
}
.no-click, .no-click:hover {
  cursor: default;
  background-color: white;
}
.no-actions {
  position: fixed;
  right: 25px;
  width: 150px;
  padding: 10px 20px;
  background-color: white;
  box-shadow: $shadow;
}
.animated.middle {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}
.gis-computed-top-margin-style-1 {
  margin-top: 30px;   // 40px (высота одного блока) * 1 (кол-во не гис действий) - 10px(какой-то отсуп)
}
.gis-computed-top-margin-style-2 {
  margin-top: 70px;   // 40px (высота одного блока) * 2 (кол-во не гис действий) - 10px(какой-то отсуп)
}
.gis-computed-top-margin-style-3 {
  margin-top: 110px;  // 40px (высота одного блока) * 3 (кол-во не гис действий) - 10px(какой-то отсуп)
}
.gis-computed-top-margin-style-4 {
  margin-top: 150px;  // 40px (высота одного блока) * 4 (кол-во не гис действий) - 10px(какой-то отсуп)
}
.gis-computed-top-margin-style-5 {
  margin-top: 190px;  // 40px (высота одного блока) * 5 (кол-во не гис действий) - 10px(какой-то отсуп)
}
.gis-computed-top-margin-style-6 {
  margin-top: 230px;  // 40px (высота одного блока) * 5 (кол-во не гис действий) - 10px(какой-то отсуп)
}
.gis-computed-top-margin-style-7 {
  margin-top: 270px;  // 40px (высота одного блока) * 5 (кол-во не гис действий) - 10px(какой-то отсуп)
}
.gis-computed-left-style {  // без длинного имени и без иконок +
  right: 194px;
}
.gis-computed-left-style-with-long-name {  // длинное имя
  right: 165px;
}
.gis-computed-left-style-with-long-name-with-icons {  // длинное имя и иконки(на других) +
  right: 245px;
}
.gis-computed-left-style-with-icons {  // иконки на коротких именах +
  right: 245px;
}
.gis-computed-left-style-with-icons-on-long-name {  // иконки на длинном имени +
  right: 297px;
}

</style>
