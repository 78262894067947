<template>
  <div class="wrap">
    <div class="context-search">
      <input type="text" class="input context-input" v-on:input="requestDataInp()" v-model="query" placeholder="Введите кадастровые номера через запятую или примечание">
    </div>
    <div class="table-controllers">
      <document-upload @updateUploadedList="updateUploadedList()"></document-upload>
      <div class="download-button-wrap">
        <!-- <button class="button button-primary" @click="downloadPopupCheck = true">
          <img v-if="multiDownloadPackageState" src="../../assets/img/spinner-white.svg" class="wait-download">
          Действия
        </button> -->
        <!-- <div class="download-popup" v-if="downloadPopupCheck">
          <button class="download-popup-elem" v-on:click="downloadSelected()" :class="{'disabled':this.selected.length == 0}" :disabled="this.selected.length == 0">Скачать выбранные</button>
          <button class="download-popup-elem" v-on:click="downloadAll()">Скачать все</button>
        </div> -->
        <!-- <noindex> -->
        <button class="button button-primary" v-on:click="downloadSelected()" :class="{'download-button-disabled':this.selected.length == 0}" :disabled="this.selected.length == 0">
          <img v-if="multiDownloadPackageState" src="../../assets/img/spinner-white.svg" class="wait-download">
          <img v-else src="../../assets/img/list.svg" class="wait-download">          
          Скачать выбранные
        </button>
        <!-- </noindex> -->
      </div>
      <div class="select-date">
        <p class="input date-selected" @click="showDates()"> {{ selectedDateName || "Дата актуальности"}}</p>
        <div class="date-selector" v-if="showDatesFlag">
          <p class="date-item" v-for="item in dateList" :key="item.id" @click="selectDate(item)">{{ item.name }}</p>
        </div>
      </div>

      <div class="select-status">
        <p class="input status-selected" @click="showStatusesFlag = true"> {{ status['value'] || "Статус обработки"}}</p>
        <div class="status-selector" v-if="showStatusesFlag">
          <p class="status-item" v-for="item in statusList" :key="item.id" @click="selectStatus(item)">{{ item.text }}</p>
        </div>
      </div>

      <div class="date-picker" v-if="showDatePicker">
        <flat-pickr v-model="startDate" :config="flatpicrConfigFrom" placeholder="от"></flat-pickr>
        <flat-pickr v-model="endDate" :config="flatpicrConfigTo" placeholder="до"></flat-pickr>            
      </div>

      <!-- <div class="overlay-download overlay-popup" v-if="downloadPopupCheck" @click="downloadPopupCheck = false"></div> -->
    </div>
    <div class="spinner" v-if="loading || userDataLoading"><img src="../../assets/img/rrd_logo_test.svg" alt="загрузка загруженных документов"></div>
    <div class="table-wrapper" style="overflow-x:auto;" v-else-if="rows.length > 0">
      <table class="table doc-table">
        <tr class="table-row doc-table-row table-row-header">
          <th class="table-col doc-table-col">
            <span class="table-checkbox" @click="selectAllItems">
              <div class="fill-checkbox" v-if="allItemsSelected"></div>
            </span>
          </th>
          <th class="table-col doc-table-col">
            Имя
          </th>
          <th class="table-col doc-table-col">
            Дата загрузки
          </th>
          <th class="table-col doc-table-col">
            Обработано
          </th>
          <th class="table-col doc-table-col">
            Действия
          </th>
        </tr>
        <tr class="table-row doc-table-row" v-for="item in rows" :key="item.id" @click="checkingItem(item.id)" v-bind:class="{'table-row-public': !item.my}">
          <td class="table-col doc-table-col">
            <span class="table-checkbox">
              <div class="fill-checkbox" v-show="checkSelect(item.id)==true"></div>
            </span>
            <input type="checkbox" :value="item.id" v-model="selected" class="hidecheckbox">
          </td>
          <td class="table-col doc-table-col">
            {{ item.name_doc }}
          </td>
          <td class="table-col doc-table-col">
            {{ item.date_upload }}
          </td>
          <td class="table-col doc-table-col">
            <div class="status-icon">
              <img v-if="item.status_id === 200" src="../../assets/img/checked.svg" alt="документ обработан">
              <img v-else-if="item.status_id === 202" src="../../assets/img/gears.svg" alt="документ в обработке">
              <img v-else-if="item.status_id === 500" src="../../assets/img/warning.svg" alt="ошибка обработки документа">
            </div>
          </td>
          <td class="table-col doc-table-col">
            <div class="status-item-block status-item-block-download" @click.stop="downloadDoc(item.id, item.cadNumber)">
              <img src="../../assets/img/download.svg" alt="скачать документ" class="download-button">
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="table-no-results" v-else>
      <p>ДОКУМЕНТЫ НЕ НАЙДЕНЫ</p>
    </div>
    <div class="paginator-wrap">
      <div class="paginator-info-count" v-if="!error && count !== 0 && !loading"><p>Документов: <b>{{count}}</b></p></div>
      <div class="paginator-body" v-if="!error && count !== 0 && !loading && rightPageNumber > 1">
        <div id="paginatorNavigation">
          <button class="paginator-button" v-if="currentPage !== 1" v-on:click="changeCurrentPage('first')"><img src="../../assets/img/double-arrow-left.svg"></button>
          <button class="paginator-button" v-if="currentPage !== 1" v-on:click="changeCurrentPage('prev')"><img src="../../assets/img/arrow-left.svg"></button>

          <div class="page-selector">
            <span class="page-selector-item" @click="changeCurrentPage(item)" v-for="(item, index) in pagesList" :key="index" v-bind:class="{'selected-page': checkPage(item)}"> {{item}} </span>
          </div>

          <button class="paginator-button paginator-right" v-if="currentPage !== pages" v-on:click="changeCurrentPage('next')"><img src="../../assets/img/arrow-right.svg"></button>
          <!-- <button class="paginator-button paginator-double-right" v-if="currentPage !== pages" v-on:click="changeCurrentPage('last')"><img src="../../assets/img/double-arrow-right.svg"></button> -->
        </div>

        <div class="select-count-document-on-page">
          <p>Отобразить на странице</p>
          <select @change="changeLimit()" v-model="limitOnPage" class="input selector-count-documents">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
    </div>
    <div class="date-selector-overlay" v-if="showDatesFlag" @click="showDatesFlag = false"></div>
    <div class="status-selector-overlay" v-if="showStatusesFlag" @click="showStatusesFlag = false"></div>
  </div>
</template>

<script>
    import aca from '../Utils/autocompillerArchive.js'
    import moment from 'moment'

    // storage facilities
    import apiDataStore from '../../store/apiDataStore'
    import uploadedStore from '../../store/UploadedStore'
    import store from '../../store/store'
    import alertStore from '../../store/AlertStore'

    // components
    import DocumentUpload from '../DocumentList/DocumentUpload'

    export default {
      name: 'UploadList',
      components: {
        'document-upload': DocumentUpload
      },
      data () {
        return {
          query: '',
          currentPage: 1,
          limitOnPage: 10,
          // downloadPopupCheck: false,
          selected: [],
          allItemsSelected: false,
          status: '',
          error: false,
          dateList: [
            {
              id: 0,
              name: '-'
            },
            {
              id: 1,
              name: 'Сегодня'
            },
            {
              id: 2,
              name: 'Неделя'
            },
            {
              id: 3,
              name: 'Месяц'
            },
            {
              id: 4,
              name: 'Год'
            },
            {
              id: 5,
              name: 'Задать промежуток'
            }
          ],
          showStatusesFlag: false,
          statusList: [
            {'id': '', 'text': '-', 'value': null},
            {'id': 500, 'text': 'Не выполнен', 'value': 'Не выполнен'},
            {'id': 100, 'text': 'Новый', 'value': 'Новый'},
            {'id': 200, 'text': 'Выполнен', 'value': 'Выполнен'}
          ],
          showDatePicker: false,
          startDate: null,
          endDate: null,
          showDatesFlag: false,
          moment: moment,
          selectedDate: {
            id: 0,
            name: ''
          },
          timeout: null
        }
      },
      computed: {
        perPage: function () {
          return uploadedStore.getters.value('perPage')
        },
        pages: function () {
          return uploadedStore.getters.value('pagesCount')
        },
        count: function () {
          return uploadedStore.getters.value('count')
        },
        nextPage () {
          return uploadedStore.getters.value('nextPage')
        },
        prevPage () {
          return uploadedStore.getters.value('prevPage')
        },
        computeOffsetLastPage () {
          return this.count - (this.count % this.limitOnPage)
        },
        offset () {
          return (this.currentPage - 1) * this.limitOnPage
        },
        multiDownloadPackageState () {
          return apiDataStore.getters.value('multiDownloadPackageStateUploads')
        },
        loading: function () {
          return uploadedStore.getters.value('loadingUploaded')
        },
        rows () {
          return uploadedStore.getters.value('rows')
        },
        flatpicrConfigFrom () {
          return {
            maxDate: 'today'
          }
        },
        flatpicrConfigTo () {
          return {
            minDate: this.startDate,
            maxDate: 'today'
          }
        },
        selectedDateName () { // для отображения надписи даты, если она не выбрана
          if (this.selectedDate.id !== 0) {
            return this.selectedDate['name']
          } else {
            return ''
          }
        },
        exQueryString () {
          let exSrt = ''
          if (this.startDate !== null) {
            exSrt = '&start_date=' + this.startDate
          }
          if (this.endDate !== null) {
            exSrt += '&end_date=' + this.endDate
          }
          return exSrt
        },
        leftPageNumber () {
          let lp = (this.offset - 4 * this.limitOnPage) / this.limitOnPage
          return (lp > 0 ? lp : 1)
        },
        rightPageNumber () {
          let tmp1 = (this.offset + 5 * this.limitOnPage) / this.limitOnPage
          let tmp2 = this.count / this.limitOnPage
          let min = Math.min(tmp1, tmp2)
          if (tmp2 >= 10 && tmp1 < 10) {
            min = 10
          }
          return min
        },
        pagesList () {
          let pages = []
          for (let i = this.leftPageNumber; i <= Math.ceil(this.rightPageNumber); i++) {
            if (i < this.rightPageNumber) {
              pages.push(i + '')
            } else {
              pages.push(String(i))
            }
          }
          return pages
        },
        userDataLoading () {
          return store.getters.value('userDataLoading')
        }
      },
      watch: {
        startDate (newVal) {
          if (this.selectedDate.id === 5) {
            this.startDate = newVal
            this.requestData()
          }
        },
        endDate (newVal) {
          if (this.selectedDate.id === 5) {
            this.endDate = newVal
            this.requestData()
          }
        }
      },
      mounted: function () {
        store.state.activeIcon = {type: 'uploaded', id: 5}
        this.getUploadedMix(this.query, this.exQueryString, this.limitOnPage, this.offset, this.status.id, false)
      },

      methods: {
        updateUploadedList () {
          this.requestData()
        },
        selectStatus (item) {
          this.status = item
          this.requestData()
          this.showStatusesFlag = false
        },
        selectDate (item) {
          if (item.id === 0) {
            this.startDate = null
            this.endDate = null
            this.showDatePicker = false
          }
          if (item.id === 1) {
            this.startDate = this.moment().format('DD.MM.YYYY')
            this.endDate = this.moment().format('DD.MM.YYYY')
            this.showDatePicker = false
          }
          if (item.id === 2) {
            this.startDate = this.moment().date(this.moment().date() - 7).format('DD.MM.YYYY')
            this.endDate = this.moment().format('DD.MM.YYYY')
            this.showDatePicker = false
          }
          if (item.id === 3) {
            this.startDate = this.moment().month(this.moment().month() - 1).format('DD.MM.YYYY')
            this.endDate = this.moment().format('DD.MM.YYYY')
            this.showDatePicker = false
          }
          if (item.id === 4) {
            this.startDate = this.moment().year(this.moment().year() - 1).format('DD.MM.YYYY')
            this.endDate = this.moment().format('DD.MM.YYYY')
            this.showDatePicker = false
          }
          if (item.id === 5) {
            this.endDate = this.moment().format('DD.MM.YYYY')
            this.showDatePicker = true
          }
          this.selectedDate = item
          this.closePopup()
          this.requestData()
        },
        closePopup () {
          this.showDatesFlag = false
        },
        showDates () {
          this.showDatesFlag = !this.showDatesFlag
        },
        checkSelect (itemId) {
          // проверяет наличие элемента в selected, если есть возвращает тру, если нет фолс(нужно для отображения галочек чекбокса)
          if (this.selected.indexOf(itemId) !== -1) {
            return true
          } else {
            return false
          }
        },

        selectAllItems () {
          // добавляет все документы с страницы в selected если allItemsSelected-фолс, иначе, "опустошает" массив selected
          if (!this.allItemsSelected) {
            this.allItemsSelected = !this.allItemsSelected
            this.rows.forEach(item => this.selected.push(item.id))
          } else {
            this.allItemsSelected = !this.allItemsSelected
            this.selected.splice(0, this.selected.length)
          }
        },
        checkingItem (itemId) {
          let pos = this.selected.indexOf(itemId)
          if (pos === -1) {
            this.selected.push(itemId)
          } else {
            this.selected.splice(pos, 1)
          }
        },

        setCurrentDoc: function (id, packageName) {
          this.currentDocId = id
          this.currentDocName = packageName
        },

        clearCurrentVals: function () {
          this.currentDocId = null
          this.currentDocName = null
        },

        downloadDoc: function (id, cadNumber) {
          this.setCurrentDoc(id, cadNumber)
          this.downloadDocMix(this.currentDocId, 'source')
          this.clearCurrentVals()
        },

        downloadSelected: async function () {
          apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageStateUploads', value: true})
          // this.downloadPopupCheck = false
          let arr = await this.createListToArhcive()
          if (arr !== null) {
            aca(arr)
          } else {
            alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка', text: 'Вы не выбрали ни одного документа'})
          }
        },

        // возвращает массив который моэно отправить на архивацию
        createListToArhcive: async function () {
          let urls = []
          for (let i in this.selected) {
            let doc = this.getDocByIdMix(this.rows, this.selected[i])
            let docUrl = await this.getDocUrlsMix(doc)
            let item = {
              cadNum: doc.name,
              name: doc.name_doc,
              date: doc.date_upload,
              id: doc.id,
              url: docUrl['source']
            }
            urls.push(item)
          }
          // console.log(urls)
          return (urls.length > 0 ? urls : null)
        },

        downloadAll: function () {
          // this.downloadPopupCheck = false
          // this.multiDownloadPackageStateUploads = true
          // let _url = this.baseUrl() + httpStore.state.me + this.url + 'jump_all_docs/'
          let _query = '?search=' + this.query + this.exQueryString + '&only_actual=' + this.onlyActual
          this.downloadAllMix(_query)
        },

        deleteDoc: function () {
          this.clearCurrentVals()
        },

        deleteDocs () {
          this.deleteDocsMix()
        },

        requestDataInp () {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.requestData()
          }, 500)
        },

        requestData (link) {
          this.currentPage = 1
          this.allItemsSelected = false
          this.selected.splice(0, this.selected.length)
          if (link !== false && link !== undefined) {
            this.getUploadedMix(this.query, this.exQueryString, this.limitOnPage, this.offset, this.status.id, link)
          } else {
            this.getUploadedMix(this.query, this.exQueryString, this.limitOnPage, this.offset, this.status.id, false)
          }
        },

        changeCurrentPage: function (page) {
          this.allItemsSelected = false
          this.selected.splice(0, this.selected.length)
          switch (page) {
            case 'next': {
              this.currentPage++
              this.getUploadedMix(this.query, this.exQueryString, this.limitOnPage, this.offset, this.status.id, false)
              break
            }
            case 'prev': {
              this.currentPage--
              this.getUploadedMix(this.query, this.exQueryString, this.limitOnPage, this.offset, this.status.id, false)
              break
            }
            case 'last': {
              this.currentPage = this.pages
              this.getUploadedMix(this.query, this.exQueryString, this.limitOnPage, this.offset, this.status.id, false)
              break
            }
            case 'first': {
              this.currentPage = 1
              this.requestData()  // меняет страницу на первую
              break
            }
            default: {
              this.currentPage = Number(page.replace(',', ''))
              this.getUploadedMix(this.query, this.exQueryString, this.limitOnPage, this.offset, this.status.id, false)
              break
            }
          }
        },

        changeLimit () {
          this.requestData()
        },
        checkPage (page) {  // проверка на совпадение страницы в списке с данной страницой
          page = Number(page.replace(',', ''))
          if (this.currentPage === page) {
            return true
          } else {
            return false
          }
        }
      }
    }
</script>
<style lang="scss" scoped>
@import "../../assets/sass/varibles.scss";
.wrap{
  position: relative;
  margin-bottom: 150px;
}
.icon-list-result{
  margin-top: -2px;
  margin-right: 4px;
}
.result-but-wrap{
  float: left;
}
.table-row {
  width: 100%;
}
.table{
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  margin: auto;
}
.table-row{
  border-bottom: 1px solid rgba($color: gray, $alpha: 0.3);
}
td {
  cursor: pointer;
}
th, td {
    text-align: left;
    padding: 10px;
}

.paginator-body img {
  width: 12px;
  height: 12px;
}
.paginator-button{
  float: left;
  margin-right: 5px;
  margin-left: 5px;
  outline: none;
}
.paginator-text{
  margin-left: 5px;
  margin-right: 5px;
  float: left;
}
.paginator-info-count{
  float:left;
  margin-right: 20px;
}
.overlay-popup{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.download-popup{
  position: absolute;
  width: 190px;
  height: auto;
  top: 160px;
  right: 300px;
  background-color: white;
  box-shadow: 1px 1px 7px 1px rgba($color: #000000, $alpha: .2);
  z-index: 100;
}
.download-popup-elem{
  width: 100%;
  height: 50px;
  background-color: white;
  margin: 0;
  padding: 0;
  color: #333333;
  cursor: pointer;
  text-align: left;
  padding-left: 25px;
  padding-right: 25px;
}
.download-popup-elem:hover{
  background-color: $color-light;
}
.download-popup-elem.disabled, .download-popup-elem.disabled:hover{
  // background-color: rgba($color: #000000, $alpha: 0.2);
  color: rgba($color: gray, $alpha: 0.5);
  background-color: white;
  cursor: default;
}
.table-controllers{
  width: 92%;
  margin: 30px 15px 20px 55px;
  height: 50px;
  padding: 0;
}
.table-wrapper{
  width: 96%;
  margin: auto;
}
.table-no-results{
  width: 96%;
  margin: auto;
  height: auto;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
}
.table-no-results p {
  color: #333333;
  font-size: 24pt;
}
.table-checkbox {
  display: block;
  float: left;
  width: 15px;
  height: 15px;
  margin-top: 0px;
  margin-right: 15px;
  border: 1px solid rgba($color: $color-second, $alpha: 1);
  border-radius: 2px;
  cursor: pointer;
}
.fill-checkbox{
  width: 100%;
  height: 100%;
  background-color: $color-second;
}
.paginator-wrap{
  width: 96%;
  margin:auto;
  margin-top: 20px;
}
.doc-table-row {
  background-color: rgba($color: $color-second, $alpha: .06);
}
.doc-table-row:hover {
  background-color: rgba($color: $color-second, $alpha: .1);
}
.table-row-public{
  background-color: white;
}
.table-row-public:hover {
  background-color: rgba($color: $color-light, $alpha: 1.0);
}
.table-row-header, .table-row-header:hover{
  background-color: white;
}
.download-button{
  height: 15px;
  width: 15px;
  margin-top: 2px;
  outline: none;
}
.status-item-block {
  height: 30px;
  width: 30px;
  outline: none;
  margin-left: 25px;
  border-radius: 49%;
  text-align: center;
}
.status-item-block-download:hover {
  border-radius: 49%;
  background-color: rgba($color: $color-first, $alpha: .1);
}
.spinner {
  width: 96%;
  margin: auto;
  height: auto;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
}
.spinner img {
  width: 400px;
  height: 400px;
}
.select-count-document-on-page {
  float: right;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 60px;
}
.selector-count-documents {
  width: 50px;
  // display: inline-block;
  margin-top: 0!important;
  font-weight: bolder;
}
.context-search {
  width: inherit;
  text-align: center !important;
  height: 60px;
  margin-bottom: 90px;
}
.context-input {
  margin: auto;
  margin-top: 25px;
  margin-bottom: 10px;
  width: 60%;
}
.download-button-wrap {
  float: right;
  // position: relative;
}
.date-selector, .status-selector {
  position: absolute;
  background-color: white;
  z-index: 110;
  box-shadow: $shadow;
}
.download-button-disabled, .download-button-disabled:hover {
  // margin-right: 50px;
  background-color: rgba($color: gray, $alpha: 0.5);
  color: white;
  cursor: default;
}
.exsearch-elem-status-item {
  width: 190px;
  cursor: pointer;
  margin: 0;
}
.date-selected, .status-selected {
  width: auto;
  color: gray;
  cursor: pointer;
}
.date-item, .status-item {
  margin: 0;
  padding: 12px;
  cursor: pointer;
}
.date-item:hover, .status-item:hover {
  background-color: $color-light;
}
.date-picker {
  display: flex;
  // width: calc(100% + 20px);
  width: 350px;
  // margin-top: 50px;
  // z-index: 110;
  background-color: white;
  padding: 10px;
}
.select-date {
  // width: 300px;
  background-color: white;
  float: left;
}
.select-status {
  background-color: white;
  float: left;
}
.status-icon img{
  margin-bottom: 5px;
  width: 15px;
  height: 15px;
  margin-left: 40px;
}
.date-selector-overlay, .status-selector-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 104;
}
.page-selector {
  max-width: 500px;
  float: left;
}
.page-selector-item {
  cursor: pointer;
  padding-right: 5px;
}
.selected-page {
  font-weight: bold;
}
</style>

