<template>
  <div id='ordering'>
    <div class="search-wrapper">
        <!-- <input class="input search-input" type="text" v-model="search" id="doc-search" v-on:input="searchOnTable($event)" placeholder="Введите кадастровые номера через запятую"/> -->
        <textarea rows='1' class="input search-input" type="text" v-model="search" id="doc-search" v-on:input="searchOnTable($event)" maxlength="1501" placeholder="Введите кадастровые номера через запятую"/>
        <button class="button-round clear-field" v-show="showClearFieldIcon" @click="clearOrderContextSearch()" id="clr-context-search-button">
          <img src="../../assets/img/close-white.svg" alt="">
        </button>
      <!-- <div class="balance-element" v-if="!disableBillingLocal && havePermissionOrderLocal">
        <balance></balance>
      </div> -->
    </div>
    <div class="wrap-top-line-control">
      <div class="select-status-order">
        <div class="exsearch-elem exsearch-elem-status">
          <p class="exsearch-elem-item exsearch-elem-status-item" @click="popupOpenItemList('status')">{{ fieldName('status', 'text') || 'Статус заказа' }}</p>
          <div class="exsearch-popup-list exsearch-popup-status-list" v-if="flagPopupItemList == 'status'">
            <p class="exsearch-popup-item exsearch-popup-status-item"  @click="selectPopupItem([], 'status')">Все</p>
            <p class="exsearch-popup-item exsearch-popup-status-item" v-for="item in statusList" :key="item.id" @click="selectPopupItem(item, 'status')">
              {{ item.text }}
            </p>
          </div>
        </div>
      </div>
      <div class="overflow-popup-item" @click="closePopup()" v-if="flagPopupItemList !== ''"></div>
      <div class="button-block">
        <button title="Заказать документы из росреестра" class="button button-trust order-new-button" v-if="havePermissionOrderLocal || demoModeFlag" @click="openNewOrderPopup()">
          <img src="../../assets/img/sort.svg" class="wait-download">
          Заказать документы
        </button>
        <div>
          <button title="Найти заказанные документы в каталоге" class="button button-primary search-selected-button" :class="{'disabled': selected.length === 0}" :disabled="selected.length === 0" @click="searchSelected()">
            <img src="../../assets/img/next.svg" class="wait-download">
            Перейти к документам
          </button>
        </div>
      </div>
    </div>
    <div v-if="loading || userDataLoading" class="spinner"><img src="../../assets/img/rrd_logo_test.svg" alt="загрузка списка заказанных выписок"></div>
    <div class="order-table" v-else-if="ordered.length > 0">
      <table class="table doc-table">
        <tr class="table-row doc-table-row table-row-header">
          <th class="table-col doc-table-col">
            <span class="table-checkbox" @click="selectAllItems">
              <div class="fill-checkbox" v-if="allItemsSelected"></div>
            </span>
          </th>
          <th class="table-col doc-table-col">
            Наименование (кадастровый номер)
          </th>
          <th class="table-col doc-table-col">
            Номер заказа
          </th>
          <th class="table-col doc-table-col">
            Примечание
          </th>
          <th class="table-col doc-table-col">
            Дата заказа
          </th>
          <th class="table-col doc-table-col">
            Статус заказа
          </th>
        </tr>
        <!-- <noindex> -->
        <tr class="table-row doc-table-row" v-for="item in ordered" :key="item.id" :class="{'disabled': item.status_id != 410 }" @click="checkingItem(item)">
          <td class="table-col doc-table-col">
            <span class="table-checkbox">
              <div class="fill-checkbox" v-show="checkSelect(item)==true"></div>
            </span>
            <input type="checkbox" :value="item" v-model="selected" class="hidecheckbox">
          </td>
          <td class="table-col doc-table-col">
            {{ item.cad_num }}
          </td>
          <td class="table-col doc-table-col" v-if="item.type !== null">
            {{ item.number}}
          </td>
          <td class="table-col doc-table-col">
            {{ item.project | projectName }}
          </td>
          <td class="table-col doc-table-col">
            {{ item.date_order |dateFormat }}
          </td>
          <td class="table-col doc-table-col">
            {{ item.status }}
          </td>
        </tr>
        <!-- </noindex> -->
      </table>
    </div>
    <div class="table-no-results" v-else>
      <p>ЗАКАЗЫ НЕ НАЙДЕНЫ</p>
    </div>
    <!-- popup window -->
    <order-component @closeOrder="closeOrder()" v-if="newOrderPopupFlag" :listFlag="'order'" @updateList="getOrderData(10, 0)"/>
    <!-- end popup window -->
    <div class="paginator-wrap">
      <div class="paginator-info-count" v-if="!error && count !== 0 && !loading"><p>Заказов: <b>{{count}}</b></p></div>
      <div class="paginator-body" v-if="!error && count !== 0 && !loading && rightPageNumber > 1">
        <div id="paginatorNavigation">
          <button class="paginator-button" v-if="currentPage !== 1" v-on:click="changeCurrentPage('first')"><img src="../../assets/img/double-arrow-left.svg"></button>
          <button class="paginator-button" v-if="currentPage !== 1" v-on:click="changeCurrentPage('prev')"><img src="../../assets/img/arrow-left.svg"></button>

          <div class="page-selector">
            <span class="page-selector-item" @click="changeCurrentPage(item)" v-for="(item, index) in pagesList" :key="index" v-bind:class="{'selected-page': checkPage(item)}"> {{item}} </span>
          </div>

          <button class="paginator-button paginator-right" v-if="currentPage !== pages" v-on:click="changeCurrentPage('next')"><img src="../../assets/img/arrow-right.svg"></button>
          <!-- <button class="paginator-button paginator-double-right" v-if="currentPage !== pages" v-on:click="changeCurrentPage('last')"><img src="../../assets/img/double-arrow-right.svg"></button> -->
        </div>

        <div class="select-count-document-on-page">
          <p>Отобразить на странице</p>
          <select @change="changeLimit()" v-model="limit" class="input selector-count-documents">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
    </div>
    <div class="overlay-new-order-popup" v-if="newOrderPopupFlag" @click="closeOrder()"></div>
  </div>
</template>
<script>
// storage facilities
import apiDataStore from '../../store/apiDataStore'
import store from '../../store/store'
import userStore from '../../store/userStore'
import documentStore from '../../store/documentStore'
import alertStore from '../../store/AlertStore'
import orderStore from '../../store/orderStore'
import settingsStore from '../../store/settingsStore'

// components
import order from './OrderingUtils/Order'

var optionsDate = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
  timezone: 'UTC'
}

var optionsTime = {
  hour: 'numeric',
  minute: 'numeric',
  timezone: 'UTC'
}

export default {
  name: 'Ordering',
  components: {
    'order-component': order
  },
  data () {
    return {
      changebleToken: true,
      error: false,
      currentPage: 1,
      search: '',
      selected: [],
      forSearch: [],
      statusList: [{'id': 600, 'text': 'Не выполнен'},
                    {'id': 100, 'text': 'Новый'},
                    {'id': 300, 'text': 'В работе'},
                    {'id': 410, 'text': 'Выполнен'}],
      newOrderPopupFlag: false,
      listCadNumInput: '',
      failListCadNumInput: '',
      allItemsSelected: false,
      status_order: null,
      flagPopupItemList: '',
      filters: {
        status: []
      },
      premissions: [],
      limit: 10
    }
  },
  async beforeMount () {
    await this.getSettingsFromApiAndSetToStore() // получение флага на заказ выписок(жив ли РР)
  },
  mounted () {
    store.state.activeIcon = {type: 'shop', id: 4}
    this.search = orderStore.getters.value('orderContextSearch')  //  сохранение поиска
    this.filters = orderStore.getters.value('filters')
    this.getOrderData(this.limit, 0)
    this.pay_result()
    // this.getCurrentPermissionsInfo()
    // this.getAllUserBillingInfoMix()
  },
  computed: {
    showClearFieldIcon () {
      return this.search.length > 0
    },
    appSettings () {
      return settingsStore.getters.settings
    },
    selected_for_search () {
      if (this.selected.length > 0) {
        return false
      } else {
        return true
      }
    },
    havePermissionOrderLocal () {
      return userStore.getters.permission('order')
    },
    loading () {
      return apiDataStore.getters.value('loadingOrderData')
    },
    count () {
      return apiDataStore.getters.value('countOrdered')
    },
    pages () {
      return apiDataStore.getters.value('pageCountOrdered')
    },
    ordered () {
      return apiDataStore.getters.value('ordered')
    },
    demoModeFlag () {
      return userStore.getters.value('demoMode')
    },
    offset () {
      return (this.currentPage - 1) * this.limit
    },
    leftPageNumber () {
      let lp = (this.offset - 4 * this.limit) / this.limit
      return (lp > 0 ? lp : 1)
    },
    rightPageNumber () {
      let tmp1 = (this.offset + 5 * this.limit) / this.limit
      let tmp2 = this.count / this.limit
      let min = Math.min(tmp1, tmp2)
      if (tmp2 >= 10 && tmp1 < 10) {
        min = 10
      }
      return min
    },
    pagesList () {
      let pages = []
      for (let i = this.leftPageNumber; i <= Math.ceil(this.rightPageNumber); i++) {
        if (i < this.rightPageNumber) {
          pages.push(i + '')
        } else {
          pages.push(String(i))
        }
      }
      return pages
    },
    userDataLoading () {
      return store.getters.value('userDataLoading')
    }
  },
  watch: {
    search (newVal) {
      orderStore.dispatch('setValue', {key: 'orderContextSearch', value: newVal})
    },
    filters (newVal) {
      orderStore.dispatch('setValue', {key: 'filters', value: newVal})
    }
  },
  filters: {
    dateFormat: function (value) {
      value = new Date(value)
      value.toString()
      return value.toLocaleString('ru', optionsTime) + ' ' + value.toLocaleString('ru', optionsDate)
    },
    projectName (value) {
      if (value === null) {
        return '-'
      } else {
        return value
      }
    }
  },
  methods: {
    clearOrderContextSearch () {
      this.search = ''
      this.autosize()
      this.searchOnTable()
    },
    autosize () {
      var el = document.querySelector('textarea') // textarea
      var clrBut = document.getElementById('clr-context-search-button')  //  кнопка очистить
      setTimeout(function () {
        el.style.cssText = 'height: 25px' // сделать высоты дефолтными
        clrBut.style.cssText = 'top: 75px'
        let _height = el.scrollHeight // вычислить высоту
        // console.log(_height)
        el.style.cssText = 'height:' + (_height + 3) + 'px' // изменить на новые
        clrBut.style.cssText = 'top:' + (_height > 128 ? 128 : _height + 50) + 'px'
      }, 0)
    },
    closeOrder () {
      this.newOrderPopupFlag = false
    },
    openNewOrderPopup () {
      if (this.appSettings.ORDER_IS_ACTIVE) {
        if (this.havePermissionOrderLocal || this.demoModeFlag) {
          this.newOrderPopupFlag = true
        } else {
          alertStore.dispatch('showAlert', {code: 1})
        }
      } else {
        alertStore.dispatch('showAlert', {code: 3}) // росреестр упал
      }
    },
    pay_result () {
      if (this.$route.path === '/pay/success') {
        alertStore.dispatch('showAlert', {type: 'success', title: 'Успешное пополнение счета', text: 'Поздравляем, счет успешно пополнен. Зачисление средств произойдет через некоторое время.'})
      } else if (this.$route.path === '/pay/fail') {
        alertStore.dispatch('showAlert', {type: 'error', title: 'Баланс не пополнен', text: 'Баланс не пополнен. Возможно, вы не подтвердили платеж'})
      }
    },
    fieldName (type, field) {
      return this.filters[type][field]
    },
    popupOpenItemList (type) {
      this.flagPopupItemList = type
    },
    selectPopupItem (item, type) {
      this.filters[type] = []
      this.filters[type] = item
      this.closePopup()
      this.currentPage = 1
      this.getOrderData(this.limit, this.offset)
    },
    closePopup () {
      this.flagPopupItemList = ''
    },
    checkingItem (item) {
      if (item.status_id === 410) {
        let pos = this.selected.indexOf(item)
        if (pos === -1) {
          this.selected.push(item)
        } else {
          this.selected.splice(pos, 1)
        }
      }
    },
    checkSelect (item) {
      // проверяет наличие элемента в selected, если есть возвращает тру, если нет фолс(нужно для отображения галочек чекбокса)
      if (this.selected.indexOf(item) !== -1) {
        return true
      } else {
        return false
      }
    },
    selectAllItems () {
      // добавляет все документы с страницы в selected если allItemsSelected-фолс, иначе, "опустошает" массив selected
      if (!this.allItemsSelected) {
        this.allItemsSelected = !this.allItemsSelected
        for (let item of this.ordered) {
          if (item.status_id === 410) {
            this.selected.push(item)
          }
        }
      } else {
        this.allItemsSelected = !this.allItemsSelected
        this.selected.splice(0, this.selected.length)
      }
    },
    onSelect (items) {
      this.selected = items
    },
    getOrderData (limit, offset) {
      this.getOrderDataMix(limit, offset, this.search, this.filters['status']['id'])
    },
    // get_url (page) {
    //   return this.baseUrl() + httpStore.state.me + '/orders/' + '?page=' + page + '&search=' + this.search + '&statusList=' + (this.filters['status']['id'] || '')
    // },
    changeCurrentPage: function (page) {
      this.allItemsSelected = false
      this.selected.splice(0, this.selected.length)
      switch (page) {
        case 'next': {
          this.currentPage++
          this.getOrderData(this.limit, this.offset)
          break
        }
        case 'prev': {
          this.currentPage--
          this.getOrderData(this.limit, this.offset)
          break
        }
        case 'last': {
          this.currentPage = this.pages
          this.getOrderData(this.limit, this.offset)
          break
        }
        case 'first': {
          this.currentPage = 1
          this.getOrderData(this.limit, this.offset)
          break
        }
        default: {
          this.currentPage = Number(page.replace(',', ''))
          this.getOrderData(this.limit, this.offset)
          break
        }
      }
    },
    changeLimit () {
      this.allItemsSelected = false // поменять флаг "выделить все"
      this.selected.splice(0, this.selected.length) // очистить список выбранных
      this.currentPage = 1
      this.getOrderData(this.limit, this.offset)
    },
    searchOnTable () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let temStr = this.search
        if (temStr[this.search.length - 1] === '\n') {
          temStr = temStr.substring(0, temStr.length - 1) // убираем последний символ(при вставке из экселя это переход на новую строку)
        }
        temStr = temStr.replace(/\n/g, ', ')
        this.search = temStr
        this.autosize()
        this.selected.splice(0, this.selected.length) // очистить список выбранных
        this.currentPage = 1
        this.getOrderData(this.limit, this.offset)
      }, 500)
    },
    updateData () {
      this.currentPage = 1
      this.getOrderData(this.limit, this.offset)
    },
    searchSelected () {
      this.forSearch = ''
      for (let item of this.selected) {
        this.forSearch += item.cad_num
        this.forSearch += ','
      }
      documentStore.state.ordersForSearch = this.forSearch
      this.$router.push('/')
    },
    checkPage (page) {  // проверка на совпадение страницы в списке с данной страницой
      page = Number(page.replace(',', ''))
      if (this.currentPage === page) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/sass/varibles.scss";
.order-table{
  overflow-x: auto;
  width: 96%;
  margin: auto;
}
.table{
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  margin: auto;
}
.table-row{
  border-bottom: 1px solid rgba($color: gray, $alpha: 0.3);
}
td {
  cursor: pointer;
}
th, td {
    text-align: left;
    padding: 10px;
}
.doc-table-row {
  background-color: white;
}
.doc-table-row:hover {
  background-color: rgba($color: $color-light, $alpha: 1.2);
}
.doc-table-row.disabled {
  background-color: rgba($color: #000000, $alpha: .07);
}
.doc-table-row.disabled:hover{
  background-color: rgba($color: #000000, $alpha: .07);
}
.doc-table-row.disabled td {
  cursor: default;
}
.table-row-header, .table-row-header:hover{
  background-color: white;
}
.table-checkbox {
  display: block;
  float: left;
  width: 15px;
  height: 15px;
  margin-top: 0px;
  margin-right: 15px;
  border: 1px solid rgba($color: $color-second, $alpha: 1);
  border-radius: 2px;
  cursor: pointer;
}
.fill-checkbox{
  width: 100%;
  height: 100%;
  background-color: $color-second;
}
.table-no-results{
  width: 90%;
  margin: auto;
  height: auto;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
}
.table-no-results p {
  color: #333333;
  font-size: 24pt;
}
.search-wrapper {
  width: inherit;
  text-align: center !important;
  margin-bottom: 90px;
}
.search-input{
  margin: auto;
  margin-top: 25px;
  margin-bottom: 10px;
  width: 60%;
  overflow-y: auto;
  max-height: 78px;
  resize: none;
}
.overlay-new-order-popup{
  width: 100vw;
  height: 100vh;
  z-index: 99;
  top:0;
  left:0;
  position: fixed;
  background-color: $color-overlay
}
.new-order-textarea-fail {
  height: 120px!important;
}
.new-order-empty-content-fail {
  height: 274px;
  transform: translateY(-20%);
}
.new-order-content-empty p {
  text-align: center;
  margin-top: 42%;
  // margin-left: 9%;
  font-size: 16pt;
}
.wrap-top-line-control{
  width: 90%;
  margin: auto;
  margin-top:30px;
  height: 50px;
  margin-bottom: 20px;
}
.search-selected-button {
  float: right;
}
.order-new-button{
  float:right;
}
button.disabled, button.disabled:hover {
  background-color: rgba(128, 128, 128, 0.5);
  cursor: default;
}
.paginator-wrap{
  width: 90%;
  margin:auto;
  margin-top: 20px;
}
.paginator-body img {
  width: 12px;
  height: 12px;
}
.paginator-button{
  float: left;
  margin-right: 5px;
  margin-left: 5px;
  outline: none;
}
.paginator-text{
  margin-left: 5px;
  margin-right: 5px;
  float: left;
}
.paginator-info-count{
  float:left;
  margin-right: 20px;
}
.select-status-order{
  margin-top: 5px;
  float: left;
}
.exsearch-elem{
  width: 100%;
  height: auto;
  border-bottom: 1px solid rgba($color: $color-dark, $alpha: 0.5);
  margin-left: 10px;
  position: relative;
  text-align: left;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 12px;
  padding-left: 10px;
}
.exsearch-elem-item{
  color: rgba($color: #333333, $alpha: 0.7);
  margin: 0;
  line-height: 2;
}
.exsearch-popup-list{
  position: absolute;
  width: auto;
  min-width: 200px;
  height: auto;
  background-color: white;
  box-shadow: 2px 2px 7px 1px rgba($color: #000000, $alpha: 0.2);
  z-index: 100;
}
.exsearch-popup-item{
  margin: 0;
  padding: 12px;
  cursor: pointer;
}
.exsearch-popup-item:hover{
  background-color: $color-light;
}
.overflow-popup-item{
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left: 0;
  z-index: 99;
}

.balance-element {
  padding-right: 20px;
  float: right;
}
.spinner {
  width: 90%;
  margin: auto;
  height: auto;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 200px;
}
.spinner img {
  width: 400px;
  height: 400px;
}
.button-block {
  float: right;
  display: flex;
}
.page-selector {
  max-width: 500px;
  float: left;
}
.page-selector-item {
  cursor: pointer;
  padding-right: 5px;
}
.selected-page {
  font-weight: bold;
}
.select-count-document-on-page {
  float: right;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 60px;
}
.selector-count-documents {
  width: 50px;
  // display: inline-block;
  margin-top: 0!important;
  font-weight: bolder;
}
.clear-field {
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: rgba($color: gray, $alpha: 0.5);
  position: absolute;
  right: 22%;
  top: 75px;
}
.clear-field img {
  width: 20px;
  height: 20px;
  padding: 5px;
  margin-top: -6.5px;
}
</style>
