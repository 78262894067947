<template>
  <div>
    <div class="exsearch-btn-wrapper">
      <div>
        <div class="exsearch-string-block">
          <p class="exsearch-string" v-if="!exsearchOpen">{{ exsearchStatusString }}</p>
        </div>
        <button class="button button-primary exsearch-button" v-on:click="toggleExsearch()">
          <img :class="{'rotate': exsearchOpen}" src="../../../assets/img/angle-arrow-down.svg">
          Расширенный поиск
        </button>
      </div>
      <div class="exsearch-body">
      <transition name='fade'>
      <div class="exsearch-wrapper" v-if="exsearchOpen">
          <!-- <input v-model="region" type="num" class="input input-extend-search" placeholder="Регион"> -->

<!-- status -->
        <!-- <div class="exsearch-elem exsearch-elem-status" @click="popupOpenItemList('status')">
          <p class="exsearch-elem-item exsearch-elem-status-item">{{ multifieldName('status', 'name') || 'Статус' }}</p>
          <div class="exsearch-popup-list exsearch-popup-status-list" v-if="flagPopupItemList == 'status'">
            <p class="exsearch-popup-item exsearch-popup-status-item" v-for="item in statusList" :key="item.code" @click="multiSelectPopupItem(item, 'status')">
              <span class="exsearch-popup-checkbox">
                <div class="fill-checkbox" v-if="filters['status'].includes(item)"></div>
              </span>
              {{ item.name }}
            </p>
          </div>
        </div> -->
<!-- end status -->

        <div class="exsearch-elem exsearch-elem-status" @click="popupOpenItemList('type')">
          <p class="exsearch-elem-item exsearch-elem-status-item">{{ multifieldName('type', 'short_name') || 'Тип' }}</p>
          <div class="exsearch-popup-list exsearch-popup-status-list" v-if="flagPopupItemList == 'type'">
            <p class="exsearch-popup-item exsearch-popup-status-item" v-for="item in typeList" :key="item.type" @click="multiSelectPopupItem(item, 'type')" v-show="item.type !== 'dci'">
              <span class="exsearch-popup-checkbox">
                <!-- <img src="../../../assets/img/select.svg" v-if="filters['type'].includes(item)"> -->
                <div class="fill-checkbox" v-if="checkSelected(item)" ></div>
              </span>
              {{ item.name }}
            </p>
          </div>
        </div>        

        <div class="exsearch-elem exsearch-elem-status">
          <p class="exsearch-elem-item exsearch-elem-status-item" @click="popupOpenItemList('actualDate')">{{ fieldName('actualDate', 'name') || 'Дата актуальности' }}</p>
          <div class="exsearch-popup-list exsearch-popup-status-list" v-if="flagPopupItemList == 'actualDate'">
            <p class="exsearch-popup-item exsearch-popup-status-item" v-for="item in dateList" :key="item.id" @click="selectPopupItem(item, 'actualDate')">
              {{ item.name }}              
            </p>
          </div>
        </div>

        <div id=datePicker v-if="this.showDatePicker">
          <flat-pickr v-model="startDate" :config="flatpicrConfigFrom" placeholder="от"></flat-pickr>
          <flat-pickr v-model="endDate" :config="flatpicrConfigTo" placeholder="до"></flat-pickr>            
        </div>

        <div class="exsearch-controlls">
          <!-- <button class="button button-primary" v-on:click="submit()">Применить</button> -->
          <button class="button button-accent" v-on:click="reset()">Сбросить</button>
        </div>
      </div>
      </transition>
      </div>
    </div>
    <div class="overflow-popup-item" @click="closePopup()" v-if="flagPopupItemList !== ''"></div>
  </div>
</template>

<script>
// storage facilities
import documentStore from '../../../store/documentStore'
import apiDataStore from '../../../store/apiDataStore'
import moment from 'moment'

export default {
  name: 'DocExSearch',
  data () {
    return {
      showDatePicker: false,
      query: {
        status: null,
        type: null,
        startDate: null,
        endDate: null
      },
      moment: moment,
      dateSelectedItem: null,  // хранит ИД даты для отслеживания ручного ввода, чтобы вотчеры не срабатывали давжды на изменение двух дат(начальной и конечной)
      dateString: '-',
      dateList: [
        {
          id: 0,
          name: '-'
        },
        {
          id: 1,
          name: 'Сегодня'
        },
        {
          id: 2,
          name: 'Неделя'
        },
        {
          id: 3,
          name: 'Месяц'
        },
        {
          id: 4,
          name: 'Год'
        },
        {
          id: 5,
          name: 'Задать промежуток'
        }
      ],
      startDate: null,
      endDate: null,
      statusList: [
        {
          name: 'Файл успешно обработан',
          code: '200'
        },
        {
          name: 'Ошибка обработки файла',
          code: '500'
        },
        {
          name: 'В работе',
          code: '202'
        }
      ],
      status: [],
      type: [],
      flagPopupItemList: '',
      timeout: null
    }
  },
  computed: {
    flatpicrConfigFrom () {
      return {
        maxDate: 'today'
      }
    },
    flatpicrConfigTo () {
      return {
        minDate: this.startDate,
        maxDate: 'today'
      }
    },
    typeList () {
      return apiDataStore.getters.value('typeList')
    },
    filters () {
      return documentStore.getters.value('filters')
    },
    exsearchOpen () {
      return documentStore.getters.value('exsearchOpen')
    },
    exsearchStatusString () { //  строка с фильтрами
      let str = ''
      if (this.filters.type.length > 0) {
        for (let item in this.filters.type) {
          str += this.filters.type[item].short_name + ', '
        }
        str = str.substring(0, str.length - 2)
      }
      if (this.filters.actualDate.id !== undefined) {
        switch (this.filters.actualDate.id) {
          case 1:
            str += ' за сегодня'
            break
          case 2:
            str += ' за неделю'
            break

          case 3:
            str += ' за месяц'
            break

          case 4:
            str += ' за год'
            break

          case 5:
            str += ' от ' + this.startDate + ' до ' + this.endDate
            break
          default:
            break
        }
      }
      return str
    }
  },
  mounted: function () {
    this.getDocumentsTypes()
    this.startDate = documentStore.getters.value('startDate')
    this.endDate = documentStore.getters.value('endDate')
    this.showDatePicker = documentStore.getters.value('showDatePicker') // сохранение состояния
    this.dateSelectedItem = this.filters['actualDate'].id
  },

  watch: {
    startDate (newVal, oldVal) {  // при изменении даты запрашивать данные и сохранять в хранилище
      if (newVal !== oldVal) {
        documentStore.dispatch('setValue', {key: 'startDate', value: newVal})
        this.submit()
      }
    },
    endDate (newVal, oldVal) {
      if (newVal !== oldVal) {
        documentStore.dispatch('setValue', {key: 'endDate', value: this.endDate})
        // если ИД равен ручному вводу дат отправить в хранилище
        if (this.dateSelectedItem === 5) {
          this.submit()
        }
      }
    },
    showDatePicker (newVal) {
      documentStore.dispatch('setValue', {key: 'showDatePicker', value: newVal})  // для сохранения состояния
    }
  },

  methods: {
    checkSelected (item) {  // передает элемент, ежели он в фильтре
      for (let i in this.filters['type']) {
        if (this.filters['type'][i]['type'] === item['type']) {
          return true
        }
      }
      return false
    },
    multifieldName (type, field) {
      let name = ''
      for (let item of this.filters[type]) {
        name += item[field] + '; '
      }
      return name
    },
    fieldName (type, field) {
      return this.filters[type][field]
    },
    multiSelectPopupItem (item, type) {
      let pos = this.filters[type].map(function (e) { return e.name }).indexOf(item.name)
      if (pos === -1) {
        // this.filters[type].push(item)
        documentStore.dispatch('setFilter', {type: 'type', item: item})
      } else {
        // this.filters[type].splice(pos, 1)
        documentStore.dispatch('deleleFilter', {type: 'type', pos: pos})
      }
      if (this.timeout === null) {
        this.timeout = setTimeout(() => {
          this.submit()
          this.timeout = null
        }, 1000)
      }
    },
    selectPopupItem (item, type) { // данные отправятся в хранилище из-за вотчеров startDate и endDate
      this.filters[type] = []
      this.filters[type] = item
      if (item.id === 0) {
        this.dateSelectedItem = item.id
        this.startDate = null
        this.endDate = null
        this.filters[type] = {}
        this.showDatePicker = false
        // this.submit()
      }
      if (item.id === 1) {
        this.dateSelectedItem = item.id
        this.startDate = this.moment().format('DD.MM.YYYY')
        this.endDate = this.moment().format('DD.MM.YYYY')
        this.showDatePicker = false
      }
      if (item.id === 2) {
        this.dateSelectedItem = item.id
        this.startDate = this.moment().date(this.moment().date() - 7).format('DD.MM.YYYY')
        this.endDate = this.moment().format('DD.MM.YYYY')
        this.showDatePicker = false
      }
      if (item.id === 3) {
        this.dateSelectedItem = item.id
        this.startDate = this.moment().month(this.moment().month() - 1).format('DD.MM.YYYY')
        this.endDate = this.moment().format('DD.MM.YYYY')
        this.showDatePicker = false
      }
      if (item.id === 4) {
        this.dateSelectedItem = item.id
        this.startDate = this.moment().year(this.moment().year() - 1).format('DD.MM.YYYY')
        this.endDate = this.moment().format('DD.MM.YYYY')
        this.showDatePicker = false
      }
      if (item.id === 5) {
        this.dateSelectedItem = item.id
        this.endDate = this.moment().format('DD.MM.YYYY')
        this.showDatePicker = true  // не обновлять, когда просто показали календарь
      }
      this.closePopup()
    },
    closePopup () {
      this.flagPopupItemList = ''
    },
    popupOpenItemList (type) {
      this.flagPopupItemList = type
    },
    toggleExsearch: function () {
      // this.exsearchOpen = !this.exsearchOpen
      documentStore.dispatch('setValue', {key: 'exsearchOpen', value: !this.exsearchOpen})
    },

    getDocumentsTypes: function () {
      this.getDocumentsTypesMix()
    },

    reset: function () {
      this.startDate = null
      this.endDate = null
      this.showDatePicker = false
      this.query = ''
      // this.region = ''
      this.status = []
      this.type = []
      this.dateString = '-'
      documentStore.dispatch('clearFilters')
      if (this.dateSelectedItem === null) { // если дату не изменяли, то спровоцировать измеенение состояния для запроса с новыми полями
        this.submit()
      }
      this.dateSelectedItem = null
    },

    // submit: function () {
    //   this.updateQuery()
    // },

    submit: function () {
      this.query = {
    // region: this.region,
        status: this.filters.status.map(function (value) { return value.code }),
        type: this.filters.type.map(function (value) { return value.type }),
        startDate: this.startDate,
        endDate: this.endDate
      }
      documentStore.dispatch('setExQuery', this.query)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/varibles.scss";
.overflow-popup-item{
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left: 0;
  z-index: 9999;
}
.exsearch-elem{
  width: 100%;
  height: auto;
  border-bottom: 1px solid rgba($color: $color-dark, $alpha: 0.5);
  margin-left: 10px;
  position: relative;
  text-align: left;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 12px;
  padding-left: 10px;
}
.exsearch-elem-item{
  color: rgba($color: #333333, $alpha: 0.7);
  margin: 0;
  line-height: 2;
}
.exsearch-popup-list{
  position: absolute;
  width: auto;
  min-width: 200px;
  height: auto;
  background-color: white;
  box-shadow: 2px 2px 7px 1px rgba($color: #000000, $alpha: 0.2);
  z-index: 99999;
}
.exsearch-popup-item{
  margin: 0;
  padding: 12px;
  cursor: pointer;
}
.exsearch-popup-item:hover{
  background-color: $color-light;
}
.exsearch-popup-checkbox{
  display: block;
  float: left;
  width: 15px;
  height: 15px;
  margin-top: 2px;
  margin-right: 15px;
  border: 1px solid rgba($color: $color-second, $alpha: 1);
  border-radius: 2px;
}
.exsearch-popup-checkbox img{
  float: left;
  padding: 2px;
  width: 100%;
  height: 100%;
}
.only-actual-checkbox {
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  float: left;
  height: auto;
  cursor: pointer;
}
.exsearch-string {
  color: gray;
  height: 26px;
  margin: 0;
}
.exsearch-string-block {
  height: 26px;
  margin: 0;
}
.only-actual-span-checkbox{
  display: block;
  float: left;
  width: 15px;
  height: 15px;
  margin-top: 2px;
  margin-right: 10px;
  border-radius: 2px;
  border: 1px solid rgba($color: $color-second, $alpha: 1);
}
.only-actual-span-checkbox img{
  float: left;
  padding: 2px;
  width: 100%;
  height: 100%;
}
.exsearch-controlls{
  display: inline-block;
  width: 100%;
  height: 50px;
  text-align: center;
}
.exsearch-btn-wrapper{
  width: 100%;
  text-align: center;
}
.exsearch-wrapper {
  width: 50vw;
  margin-left: calc(25vw - 60px);
}
.exsearch-button{
  margin: auto;
}
.input-extend-search{
  color: rgba($color: #333333, $alpha: 0.7);
}
.fill-checkbox{
  width: 100%;
  height: 100%;
  background-color: $color-second;
}
 .exsearch-slide-enter-active {
    animation: exsearch-slide-in .3s;
  }
  .exsearch-slide-leave-active {
    animation: exsearch-slide-in .3s reverse;
  }
  @keyframes exsearch-slide-in {
    0% {
      margin-top: -700px;
    }
    100% {
      margin-top: 0;
    }
  }
  #datePicker {
    display: flex;
    width: calc(100% + 20px);
  }
.exsearch-body {
  margin-left: 30px;
}
.rotate {
  transform: rotate(180deg);
}
</style>
