import store from '../store/store'
import httpStore from '../store/httpStore'
import alertStore from '../store/AlertStore'
import userStore from '../store/userStore'

import Vue from 'vue'

export default {
  data () {
    return {
      baseurl: httpStore.state.host + httpStore.state.api,
      baseauthurl: httpStore.state.host + httpStore.state.auth
    }
  },
  methods: {
    getToken: function () {
      return this.$cookie.get('rrdtkn')
    },

    setToken: function (token) {
      this.$cookie.set('rrdtkn', token)
      Vue.http.headers.common['Authorization'] = 'Bearer ' + token
    },

    verifyToken: function () {
      let _token = '{ "token": "' + this.getToken() + '"}'
      this.$http.post(this.baseauthurl + '/api-token-verify/', JSON.parse(_token)).then(response => {
        if ((response.status === 200) && (response.data.token === this.getToken())) {
          this.refreshToken()
        } else {
          this.logout()
        }
      }).catch(error => {
        // alertStore.dispatch('showAlert', {title: 'Ошибка токена', text: 'Не удалось подтвердить корректность токена.'})
        console.log(error.status)
        this.logout()
      })
    },

    refreshToken: function () {
      try {
        let _token = '{ "token": "' + this.getToken() + '"}'
        this.$http.post(this.baseauthurl + '/api-token-refresh/', JSON.parse(_token)).then(response => {
          let _newToken = response.data.token
          this.setToken(_newToken)
        }).catch(error => {
          // alertStore.dispatch('showAlert', {title: 'Ошибка токена', text: 'Не удалось обновить токен'})
          console.log(error.status)
          this.logout()
        })
      } catch (err) {
        console.log('err')
      }
    },
    deleteAllCookies () {
      var cookies = document.cookie.split(';')
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i]
        var eqPos = cookie.indexOf('=')
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
      }
    },
    login: function (loginData) {
      store.state.loginLoading = true
      this.$cookie.delete('JWT')
      // this.deleteAllCookies()
      this.$http.post(this.baseauthurl + '/login/', loginData).then(response => {
        // console.log(response)
        let _token = response.data.token
        this.setToken(_token)
        Vue.http.headers.common['Authorization'] = 'Bearer ' + _token
        this.$cookie.set('username', response.data.user.username)
        this.$cookie.set('userid', response.data.user.pk)
        this.$cookie.set('email', response.data.user.email)
        store.state.loginLoading = false
        store.state.authorized = true
        // store.state.authError = false
        if (response.data.user.email === 'demo@rrdoc.ru') {
          userStore.dispatch('setDemoMode', true)
        } else {
          userStore.dispatch('setDemoMode', false)
        }
        // this.getUserBalance()
        // this.getCurrentPermissionsInfo()
        this.$router.push({name: store.state.currentRouteName})
      }).catch((error) => {
        store.state.loginLoading = false
        // store.state.authError = true
        store.state.authorized = false
        // console.log(error)
        // добавить в метод повторную отправку письма, когда появится api
        if (error.body['non_field_errors'] !== undefined) {
          if (error.body['non_field_errors'][0] === 'E-mail не подтверждён.') {
            alertStore.dispatch('showAlert', {type: 'error', emitName: 'sendEmailAgan', title: 'E-mail не подтвержден', text: 'Проверьте свою почту и подтвердите свою учетную запись.'})
          } else {
            alertStore.dispatch('showAlert', {type: 'error', code: error.status})
          }
        } else {
          alertStore.dispatch('showAlert', {type: 'error', code: error.status})
        }
      })
    },

    logout: function () {
      this.$http.post(this.baseauthurl + '/logout/').then(response => {
        console.log('Logout: ' + response.status)
        this.$cookie.delete('JWT')
        this.$cookie.delete('rrdtkn')
        this.$cookie.delete('username')
        this.$cookie.delete('userid')
        this.$cookie.delete('messages')
        this.$cookie.delete('sessionid')
        store.dispatch('setValue', {key: 'authorized', value: false})
        this.closeWSMix()
        this.$router.push({name: 'SignsItem'})
        userStore.dispatch('clearStore')
        // console.log(userStore.state)
        store.dispatch('changeShowProfileFlag', false)
      }).catch(error => {
        console.log(error)
      })
    },

    baseUrl: function () {
      return this.baseurl
    },

    baseAuthUrl: function () {
      return this.baseauthurl
    },

    postDocUrl: function () {
      return this.postdocurl
    },

    handleError: function (errStatus) {
      switch (errStatus) {
        case 401: {
          let demoData = {}
          demoData['email'] = 'demo@rrdoc.ru'
          demoData['password'] = 'mLxkElOQ9mmw'
          this.logout()
          // this.login(demoData)
          break
        }
        default: {
          console.log(errStatus)
          break
        }
      }
    }
  }
}
