
<template>
<div id="app" class="page-container" v-bind:class="{'order-loading': orderLoading}">
    <div v-if="serverAlive !== true">
      <stub></stub>
    </div>
    <div v-else>
      <div class="head-toolbar">
        <img class="drawer-button" src="./assets/img/gamburger.svg" @click="drawerCheck = !drawerCheck" v-if="authorized">
        <img class="logotype" src="./assets/img/rrd_logo_white.svg" @click="goToLanding()"/>
        <div v-if="company !== null && company !== '' && company !== '[object Object]'" class="user-company"><p>|&nbsp; {{ company }}</p></div>
        <div class="user-company"><p>|&nbsp; </p></div>
        <div class="current-page-title"><p>{{currentHeadTitle}}</p></div>
        <h1 class="good-class">{{currentHeadTitle}}</h1>
        <div class="account-block" v-if="authorized && !demoModeFlag">
          <!-- <button class="exit-btn button button-primary" v-on:click="beforeExit()">Выйти</button> -->
          <router-link to="/profile">
            <div title="Профиль" class="profile-small">
              <img class="profile-icon" src="./assets/img/user.svg" alt="Иконка профиля пользователя ррд">
            </div>
          </router-link>
          <balance-component v-if="!disableBillingLocal && havePermissionOrderLocal"></balance-component>
          <img @click="youtubeCheck=!youtubeCheck" class="youtube-button" src="./assets/img/youtube.svg">
        </div>
        <div class="account-block" v-else-if="demoModeFlag && authorized">
          <img @click="youtubeCheck=!youtubeCheck" class="youtube-button" src="./assets/img/youtube.svg">
          <button class="exit-btn-demo button button-primary" v-on:click="toLogin()">Вход</button>
          <button class="exit-btn-demo button button-primary" v-on:click="toRegister()">Регистрация</button>
        </div>
      </div>

      <!-- small drawer -->
      <div class="drawer-small" v-if="authorized">
        <router-link to="/">
          <div title="База документов. КПТ, выписка из ЕГРН, границы и зоны" class="drawer-small-item" v-bind:class="{ 'drawer-small-item-active': activeIcon['id'] == 1 }" @click="changeActiveIcon('documents', 1)">
            <img src="./assets/img/documents.svg">
          </div>
        </router-link>

        <router-link to="/landPlans">
          <div title="Межевые и технические планы" class="drawer-small-item" v-bind:class="{ 'drawer-small-item-active': activeIcon['id'] == 6 }" @click="changeActiveIcon('landPlans', 6)">
            <img src="./assets/img/dki-icon.svg">
          </div>
        </router-link>
        
        <router-link to="/order">
          <div title="Заказ выписок ЕГРН" class="drawer-small-item" v-bind:class="{ 'drawer-small-item-active': activeIcon['id'] == 4 }" @click="changeActiveIcon('shop', 4)">
            <img src="./assets/img/list_2.svg">
          </div>
        </router-link>
        
        <router-link to="/upload">
        <div title="Загруженные Вами документы" class="drawer-small-item" v-bind:class="{ 'drawer-small-item-active': activeIcon['id'] == 5 }" @click="changeActiveIcon('uploaded', 5)">
          <img src="./assets/img/uploaded.svg">
        </div>
      </router-link>

        <div class="social-small">

          <div title="Обратная связь" class="drawer-small-item social-small-item" @click="showFeedback()">
            <img src="./assets/img/feedback.svg" alt="обратная связь">
          </div>

        </div>
      </div>
      <!-- end small drawer -->
      <!-- drawer -->
      <transition name="drawer-slide">
        <div class="drawer" v-if="drawerCheck && authorized">
          <router-link to="/">
            <div class="drawer-item" v-bind:class="{ 'drawer-item-active': activeIcon['id'] == 1 }" @click="changeActiveIcon('documents', 1)">
              <img src="./assets/img/documents.svg">
              <p>База документов</p>
            </div>
          </router-link>

          <router-link to="/landPlans">
            <div class="drawer-item" v-bind:class="{ 'drawer-item-active': activeIcon['id'] == 6 }" @click="changeActiveIcon('landPlans', 6)">
              <img src="./assets/img/dki-icon.svg">
              <p>Меж. и тех. планы</p>
            </div>
          </router-link>

          <router-link to="/order">
            <div class="drawer-item" v-bind:class="{ 'drawer-item-active': activeIcon['id'] == 4 }" @click="changeActiveIcon('shop', 4)">
              <img src="./assets/img/list_2.svg">
              <p>Заказ сведений</p>
            </div>
          </router-link>

          <router-link to="/upload">
            <div class="drawer-item" v-bind:class="{ 'drawer-item-active': activeIcon['id'] == 5 }" @click="changeActiveIcon('uploaded', 5)">
              <img src="./assets/img/uploaded.svg">
              <p>Мои загрузки</p>
            </div>
          </router-link>

          <div class="contacts social-icons">
            <div class="icon can-click" @click="showFeedback()">
              <div>
                <button class="button can-click button-primary drawer-social-button" @click="showFeedback()">Отправить отзыв</button>
              </div>
              <img src="./assets/img/feedback.svg" alt="обратная связь">
            </div>

          <div class="term-of-use-block">
            <router-link to="/terms-of-use">
              <p @click="changeActiveIcon('termOfUse', 7)" class="term-of-use">Пользовательское соглашение</p>
            </router-link>
          </div>
          </div>
        </div>
      </transition>
      <!-- end drawer -->
      <div class="content">
      <transition name="content-animation">      
        <router-view v-if="!loginLoading" />
      </transition>      
      </div>

  <!-- popup windows -->

      <div v-if="showFeedbackFlag">
        <feedback-component @closeFeedback="closeFeedback"></feedback-component>
      </div>
      <div class="feedback-overlay" v-if="showFeedbackFlag" @click="closeFeedback"></div>

      <div class="my-alert">
        <alert-component 
          @logOut="exit($event)" @sendEmailAgan="sendEmailAgan()" @toRegistration="registerEmit($event)" @getExitFromCompany="getExitFromCompany($event)"
          @updateTarif="updateTarif($event)"></alert-component>
      </div>

      <div class="youtube-frame" v-if="youtubeCheck">
        <iframe width="854" height="480" src="https://www.youtube.com/embed/hjAJUt6wtzU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div v-if="youtubeCheck" class="overflow-youtube-frame" @click="youtubeCheck = false"></div>

      <div class="drawer-overlay" v-if="drawerCheck" @click="drawerCheck = false"></div>

      <!-- <transition name="fade">
        <greeting-component v-if="!secondTime"></greeting-component>
      </transition> -->
      <transition name="fade">
        <offer-component v-if="offer" @toSignIn="toLogin()" @toSignUp="toRegister()"></offer-component>
      </transition>
    </div>
  </div>
</template>

<script type="text/templates">

  import { setInterval } from 'timers'

  // storage facilities
  import store from './store/store'
  import userStore from './store/userStore'
  import httpStore from './store/httpStore'
  import apiDataStore from './store/apiDataStore'
  import alertStore from './store/AlertStore'
  import settingsStore from './store/settingsStore'

  // components
  import Alert from './components/Alert/Alert'
  import Balance from './components/Ordering/OrderingUtils/Balance'
  import Offer from './components/Greeting/Offer'
  import stub from './components/Stub/stub'
  import FeedbackComponent from './components/Utils/Feedback'

  export default {
    name: 'app',
    components: {
      FeedbackComponent,
      'feedback-component': FeedbackComponent,
      'alert-component': Alert,
      'balance-component': Balance,
      'offer-component': Offer,
      'stub': stub
    },
    data () {
      return {
        disableBillingLocal: store.state.disableBillingFlag,
        menuVisible: false,
        drawerTitle: '',
        username: '',
        letterPlaceholder: '',
        email: '',
        drawerCheck: false,
        youtubeCheck: false,
        showFeedbackFlag: false,
        showPhoneNumberFlag: false
      }
    },
    computed: {
      appSettings () {
        return settingsStore.state
      },
      serverAlive () {
        return store.getters.value('serverAlive')
      },
      // secondTime () {
      //   return userStore.getters.value('secondTime')
      // },
      havePermissionOrderLocal () {
        return userStore.getters.permission('order')
      },
      company () {
        return userStore.getters.value('company')
      },
      authorized: function () {
        return store.state.authorized
      },
      currentTitle: function () {
        return store.state.currentTitle
      },
      currentHeadTitle: function () {
        return store.state.currentHeadTitle
      },
      feedback: function () {
        return store.state.feedback
      },
      activeIcon () {
        return store.state.activeIcon
      },
      showBalanceElementFlag: function () {
        return this.$route.path === '/order' || this.$route.path === '/pay/success' || this.$route.path === '/pay/fail'
      },
      showProfileFlag () {
        return store.state.showProfileFlag
      },
      orderLoading () {
        return apiDataStore.getters.value('orderLoading')
      },
      loginLoading () {
        return store.getters.value('loginLoading')
      },
      demoModeFlag () {
        return userStore.getters.value('demoMode')
      },
      offer () {
        return alertStore.state.offer
      },
      flagRequestData () {  //  изменение этой переменной приводит к запросу данных
        return store.getters.value('flagRequestData')
      }
    },
    watch: {
      authorized: function (newState) {
        console.log('Authorized: ' + newState)
        if (newState) {
          this.username = this.$cookie.get('username')
          this.letterPlaceholder = this.username[0]
          this.email = this.$cookie.get('email')
          this.checkActiveTarifAndDaysLeft()
          this.requestUserDataOnChangPage() // запрос данных когда авторизовался
          this.runNotificationUpdateConnect()
          this.checkWantSeeTariff()
        }
      },
      currentTitle: function (newValue) {
        this.drawerTitle = newValue
      },
      // при смене страницы зпрашивает данные юзера (биллинг, юзердата, разрешения и пр.)
      flagRequestData: function (newVal) {
        if (this.authorized && newVal !== 'SignsItem' && newVal !== 'reset-password' && newVal !== 'confirm_email_success' && newVal !== 'accounts_profile' && newVal !== 'term_of_use') {
          // console.log('change current route name in App')
          this.requestUserDataOnChangPage()
        }
      }
    },

    mounted: function () {
      console.log('processes.env', process.env)
      this.menuVisible = false
      this.initTokenChecker()
      // this.initServerChecker()
      if (this.authorized && this.flagRequestData !== 'SignsItem' && this.flagRequestData !== 'reset-password' && this.flagRequestData !== 'confirm_email_success' && this.flagRequestData !== 'accounts_profile' && this.flagRequestData !== 'term_of_use') {
        // console.log('request in App mounted ' + this.flagRequestData)
        this.requestUserDataOnChangPage() // запросить данные, если страницу обновили и залогинены
      }
      if (this.authorized) {  // коннект уведомлений
        this.runNotificationUpdateConnect()
      }
      this.checkWantSeeTariff()
    },

    created: function () {
      console.log('init')
      store.state.activeIcon = {type: 'documents', id: 1}
      this.drawerTitle = store.state.currentTitle
      this.username = this.$cookie.get('username')
      if (this.username !== undefined && this.username !== null) {
        this.letterPlaceholder = this.username[0]
      }
      this.email = this.$cookie.get('email')
    },
    methods: {
      checkWantSeeTariff () {
        if (localStorage.getItem('wantToSeeTariffs') !== null && this.authorized && !this.demoModeFlag) { // если авторизован, не демо и хотел увидеть тарифы(с ландинга флаг), то перейти в ЛК
          this.$router.push({name: 'PersonalCabinet'})
        } else if (localStorage.getItem('wantToSeeTariffs') !== null) { // если что-то не выполнено, но он все еще хотел увидеть тарифы
          this.$router.push({name: 'SignsItem'})
        }
      },
      goToLanding () {
        window.location.href = 'https://rrdoc.ru/welcome'
      },
      getRegularPaymentPriceInfo () { // получнние ежедневной выплаты для рассчета оставшихся дней
        return new Promise(resolve => {
          this.$http.get(httpStore.getters.host + httpStore.getters.value('getRegularPaymentPrice')).then(response => {
            resolve(response.data.result)
          }).catch(error => {
            resolve(null)
            console.log(error.status)
          })
        })
      },
      getBalance () { // получение баланса для проверки на оставишиеся дни
        return new Promise(resolve => { //  http://host:port/api/v1/billing/balance/
          this.$http.get(httpStore.state.host + httpStore.state.api + '/billing/balance/').then(response => {
            resolve(response.body.amount_balance)
          }).catch(error => {
            console.log(error.status)
            resolve(null)
          })
        })
      },
      checkDaysLeft: async function () {  // рассчет оставшихся дней
        let daysToAlert = 2
        let regular = await this.getRegularPaymentPriceInfo()
        let balance = await this.getBalance()
        if (regular !== null && balance !== null) {
          let daysLeft = Math.floor(balance / regular)
          if (daysLeft <= daysToAlert) {
            let _text = 'Ваших средств хватит еще на ' + daysLeft
            switch (daysLeft) {
              case 0:
                _text += ' дней.'
                break
              case 1:
                _text += ' день. '
                break
              case 2:
                _text += ' дня.'
                break
              default:
                _text += ' дней.'
                break
            }
            _text += ' Не забудьте своевременно пополнить баланс'
            alertStore.dispatch('showAlert', {type: 'error', title: 'Приближение к нулю', text: _text})
          }
        }
      },
      // проверяет блокировку тарифа. Если заблокирован, выводит сообщение
      checkActiveTarifAndDaysLeft (callback = this.checkDaysLeft) {
        if (!userStore.getters.value('demoMode')) {
          this.$http.get(httpStore.getters.host + httpStore.getters.value('user')).then(response => {
            if (!response.body.tariff_is_active) {
              alertStore.dispatch('showAlert', {type: 'fullscreen', title: 'Тариф заблокирован', text: 'На вашем счету недостаточно средств для совершения ежедневной оплаты. Вам доступны только базовые действия. Для разблокировки пополните баланс.', showAbort: false})
            } else {
              callback()
            }
            return (response.body.tariff_is_active)
          })
        } else {
          return true
        }
      },
      initServerChecker () { // проверка доступности сервера каждый интервал времени
        setInterval(() => {
          // console.log('check connection')
          this.$http({url: httpStore.getters.host + httpStore.getters.value('testServer'), method: 'get'}).then((response) => {
            if (response.body.DatabaseBackend === 'working') {
              store.dispatch('setValue', {key: 'serverAlive', value: true})
            } else {
              store.dispatch('setValue', {key: 'serverAlive', value: false})
            }
          }).catch((err) => {
            console.log(err)
            store.dispatch('setValue', {key: 'serverAlive', value: false})
          })
        }, 1000 * 60 * 3)
      },
      initTokenChecker () {
        setInterval(() => {
          if (this.authorized) {
            this.verifyToken()
          }
        }, 1000 * 60 * 5)
      },
      registerEmit (value) {
        if (value) {
          store.dispatch('setValue', {key: 'signIn', value: false})
          this.$router.push({name: 'SignsItem'})
        }
      },
      toRegister () {
        store.dispatch('setValue', {key: 'signIn', value: false})
        this.$router.push({name: 'SignsItem'})
      },
      toLogin () {
        store.dispatch('setValue', {key: 'signIn', value: true})
        this.$router.push({name: 'SignsItem'})
      },
      sendEmailAgan () {
        console.log('in meth')
      },
      closeFeedback () {
        this.showFeedbackFlag = false
      },
      showFeedback () {
        if (this.demoModeFlag) {
          alertStore.dispatch('setValue', {key: 'offer', value: true})
        } else {
          this.showFeedbackFlag = true
          this.drawerCheck = false
        }
      },
      changeActiveIcon (type, id) {
        store.state.activeIcon = {type: type, id: id}
        this.showFeedbackFlag = false
        this.drawerCheck = false
        store.state.showProfileFlag = false  //  закрыть окна при смене вкладки
        this.changePasswordFlag = false
        this.showPhoneNumberFlag = false
        apiDataStore.dispatch('setValue', {key: 'totalPrice', value: 0})
        apiDataStore.dispatch('setValue', {key: 'hasEnoughMoney', value: true})
      },
      toggleMenu: function () {
        this.menuVisible = !this.menuVisible
      },

      toggleFeedback: function () {
        store.state.feedback = true
      },
      exit: function (flag) {
        console.log(flag)
        if (flag) {
          userStore.dispatch('clearStore')
          this.logout()
          this.menuVisible = false
        }
      },
      getExitFromCompany (flag) {
        if (flag) {
          this.getExitFromCompanyMix()
        }
      },
      updateTarif (emit) {
        if (emit.flag) {
          this.updateTariffMix(emit.params[0])
        }
      },
      showPhoneNumber () {
        this.showPhoneNumberFlag = !this.showPhoneNumberFlag
      }
    }
  }
</script>

<style lang="scss">
@import "./assets/sass/varibles.scss";
// @import "./assets/sass/vue2-animate.scss";
  p, span, h1, h2, h3, h4, h5, button, div, a, b {
    font-family: 'Open Sans', sans-serif;
  }
  p, span, button, div, a {
    font-size: 12pt;
  }
  #app {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    // background-image: url("https://www.transparenttextures.com/patterns/45-degree-fabric-light.png");
    // background-repeat: repeat;
  }
  .md-app {
    min-height: 100vh;
  }
  .logo {
    height: 30px !important;
    width: auto;
    margin-top: -10px;
    margin-left: 10px;
  }
  .head-toolbar{
    top: 0;
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 50px;
    background-color: $color-first;
  }
  .logotype{
    width: auto;
    height: 32px !important;
    object-fit: cover;
    margin-left: 20px;
    margin-top: 6px;
    display: block;
    float: left;
  }
  .drawer-button{
    height: 30px !important;
    width: auto;
    object-fit: cover;
    margin-left: 15px;
    margin-top: 10px;
    display: block;
    float: left;
    cursor: pointer;
  }
  .drawer{
    width: 300px;
    height: 100%;
    position: fixed;
    background-color: $color-light;
    left: 0;
    top:0;
    z-index: 99998;
    padding-top: 70px;
    box-shadow: 0px 1px 7px 1px rgba($color: #000000, $alpha: .5);
  }
  .drawer-slide-enter-active {
    animation: drawer-slide-in .1s;
  }
  .drawer-slide-leave-active {
    animation: drawer-slide-in .1s reverse;
  }
  @keyframes drawer-slide-in {
    0% {
      margin-left: -300px;
    }
    100% {
      margin-left: 0;
    }
  }

  .content-animation-enter-active {
    animation: fade-in-content .1s;
  }

  .content-animation-leave-active {
    animation: fade-in-content .1s reverse;
  }
  @keyframes fade-in-content {
    0% {
      opacity: 0;
      // margin-left: -100%;
      margin-bottom: 100%;
    }
    100% {
      opacity: 1;
      // margin-left: 60px;
      margin-bottom: 100%;
    }
  }

  .drawer-small{
    width: 60px;
    height: 100%;
    position: fixed;
    background-color: $color-light;
    left: 0;
    top:0;
    z-index: 99997;
    padding-top: 70px;
  }
  .drawer-small-item{
    width: 60px;
    height: 60px;
    background-color: transparent;
    padding: 13px;
    cursor: pointer;
  }
  .drawer-small-item-active{
    background-color: white;
  }
  .drawer-small-item-disabled{
    cursor: default;
  }
  .drawer-small img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .drawer-item{
    width: 300px; // drawer width
    height: 60px;
    background-color: transparent;
    padding: 13px;
    cursor: pointer;
  }
  .drawer-item-active{
    background-color: white;
    color: $color-first;
  }
  .drawer-item-disabled {
    cursor: default;
  }
  .drawer-item img{
    width: 36px;
    height: auto;
    object-fit: cover;
    float: left;
  }
  .drawer-item p {
    float: left;
    font-size: 14pt;
    margin: 0;
    margin-left: 20px;
    margin-top: 4px;
  }
  .drawer-item-disabled{
    color: rgba($color: #000000, $alpha: .5);
  }
  .drawer-item-choosed {
    background-color: white;
    color: $color-first;
  }
  .content{
    margin-top: 50px;
    margin-left: 60px;
  }
  .account-block{
    margin-right: 20px;
    height: 100%;
    float:right;
  }
  .user-email{
    font-size: 14pt;
    margin-bottom: 30px;
  }
  .exit-btn{
    color: $color-light;
    font-size: 12pt;
    margin: 0;
    height: 100%;
    float: right;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
  }
  .exit-btn:hover{
    background-color: rgba($color: $color-light, $alpha: .2);
  }
  .exit-btn-demo{
    color: $color-light;
    font-size: 12pt;
    margin: 0;
    height: 100%;
    float: right;
    margin-left: 0;
    margin-right: 5px;
    cursor: pointer;
  }
  .exit-btn-demo:hover{
    background-color: rgba($color: $color-light, $alpha: .2);
  }
  .youtube-button{
    width: 37px;
    height: 27px !important;
    object-fit: cover;
    float: left;
    margin-top: 12px;
    margin-right: 20px;
    border-radius: 6px;
    cursor: pointer;
    -ms-interpolation-mode: bicubic;
  }
  .youtube-button:hover{
    width: 37px;
    height: 27px !important;
    object-fit: cover;
    float: left;
    margin-top: 12px;
    margin-right: 20px;
    border-radius: 7px;
    border: 2px solid rgba($color: $color-light, $alpha: .5);
    -ms-interpolation-mode: bicubic;
  }
  .youtube-frame{
    width: 854px; 
    height: 490px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -397px;
    margin-top: -240px;
    z-index: 100;
    box-shadow: 1px 1px 7px 1px rgba($color: #000000, $alpha: 0.2);
  }
  .youtube-frame iframe{
    width: 100%;
    height: 100%;
  }
  .overflow-youtube-frame{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $color-overlay;
    z-index: 99;
  }
  .social-small{
    position: absolute;
    bottom: 28px;
  }
  .contacts{
    width: 100%;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .icon {
    float: right;
    // margin-left: 15px;
    margin: 6px 0 6px 15px!important;
    outline: none;
    height: 38px;
  }
  .icon img {
    margin-left: 15px;
    height: 30px;
  }
  .icon-help-dialog {
    float: right;
    outline: none;
  }
  .social-small-item {
    width: 60px;
    height: 50px;
    background-color: transparent;
    padding: 10px 15px;
  }
  a:visited {
    color: $color-first;
  }
  .my-alert {
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .where-is-user {
    position: absolute;
    padding-left: 50vw;
    margin-left: -100px;
    width: 200px;
    text-align: center;
  }
  .where-is-user p {
    color: white;
    font-size: 16pt;
    margin: 0;
    margin-top: 11px;
    font-weight: bold;
    width: 200px;
  }
  .drawer-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .feedback-overlay {
  top: 0;
  left: 0;
  background-color: $color-overlay;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 104;
  overflow: hidden;
}
.user-company {
  float: left;
}
.user-company {
  width: auto;
  color: white;
  margin: 0;
  margin-top: 12px;
  font-weight: bold;
  margin-left: 13px;
}
.user-company p{
  font-size: 14pt;
}
.current-page-title {
  float:left;
  color:white;
  margin:0;
  margin-top: 13px;
  margin-left: 13px;
}
.current-page-title span {
  font-weight: bold;
  color: white;
}
.current-page-title p{
  font-weight: 400;
  font-size: 14pt;
}
.profile-icon {
  width: 21px;
  height: 21px;
  margin-top: 10px;
  margin-left: 10px;
}
.profile-small {
  height: 40px;
  width: 40px;
  margin-top: 5px;
  margin-left: 0;
  float: right;
  cursor: pointer;
  border-radius: 49%;
}
.profile-small:hover {
  background-color: rgba($color: $color-light, $alpha: .2);
  border-radius: 49%;
}
.profile-popup {
  position: fixed;
  top: 60px;
  right: 20px;
  background-color: white;
  padding: 20px;
  // padding-bottom: 20px;
  border-radius: 3px;
  text-align: center;
  box-shadow: 1px 1px 7px 1px rgba($color: #333333, $alpha: .2);
  z-index: 100
}
.profile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 95;
}
.password-change-popup {
  position: fixed;
  width: 600px;
  // height: 50%;
  left: calc(50% + 60px);
  margin-left: calc(-300px - 20px);
  top: calc(50% - 50px);
  margin-top: calc(-15% + 50px);
  background-color: white;
  z-index: 105;
  padding: 30px;
  padding-bottom:10px;
}
.password-change-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 95;
  background-color: $color-overlay;
}
.password-change-content {
  margin: 5% 0;
  margin-bottom: 0;
  text-align: center;
}
.password-change-input {
  width: calc(100% - 20px);
}
.change-password-buttons {
  float: right;
  // position: absolute;
  // bottom: 30px;
  // right: 30px;
  margin-top: 10px;
}
.password-requirements {
  margin: 0 10px;
  // height: auto;
  margin-bottom: 10px;
}
.password-requirements-item {
  // color: red;
  float: left;
  font-weight: 600;
  width: 80%;
  outline: none;
  margin-top:10px;
  margin-left:10px;
  font-weight: 300;
  line-height: 1;
}
.password-requirements-succeess-first {
  width: 16px;
  height: 16px;
  position: absolute;
  object-fit: cover;
  margin-top: 2%;
}
.password-requirements-succeess-second {
  width: 16px;
  height: 16px;
  object-fit: cover;
  position: absolute;
  margin-top: -30px;
  right: 40px;
}
.profile-button-exit {
  text-align: right;
  cursor: pointer;
  font-weight: 300;
}
.profile-button-exit:hover {
  text-decoration: underline;
}
.help-dialogs-control {
  position: fixed;
  left: 60px;
  bottom: 10px;
  background-color: white;
}
.show-phone-number-main {
  position: fixed;
  left: 60px;
  bottom: 110px;
  height: 52px;
  border-radius: 3px;
  background-color: white;
}
.show-phone-number {
  background-color: $color-first;
  color: $color-light;
  border-radius: 3px;
  padding: 7px 10px;
  font-size: 12pt;
  outline: none;
  margin: 7px;
}
.phone-number {
  margin: 0;
}
.social-icons {
  width: 300px; // drawer width
  // height: 60px;
  background-color: transparent;
  padding: 0 13px 0 0;
  // cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.social-icons img {
  width: 30px;
  height: auto;
  object-fit: cover;
}
.can-click {
  cursor: pointer;
  margin: 0;
}
.phone-number-drawer {
  margin: 0;
  font-size: 12pt;
}
.drawer-social-button {
  height: 38px;
}
.auth-loading {
  margin-top: 100px;
  height: 70%;
  width: 70%;
  margin-left: 15%;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.term-of-use-block {
  text-align: right;
  width: 100%;
}
.term-of-use {
  font-size: 10pt;
  margin-bottom: 0;
  text-decoration: underline;
  cursor: pointer;
}
</style>

<style lang="scss">
@import './assets/sass/tooltip.scss';

.order-loading {  // блокирует ВСЕ НАЖАТИЯ в контейнере app.vue т.е. по всему приложению
  pointer-events: none; //  не поддерживается Opera Mini и IE 10 и ниже (включительно)
}
button {
  transition: background-color 0.3s;
}
</style>

