<template>
  <div id="sign-item">
    <div class="wrapp">
      <div class="sign-wrap">
        <sign-in v-show="signIn"></sign-in>
        <sign-up @backToLogin="changeSingIn(true)" v-show="!signIn"></sign-up>
        <div class="register" v-show="signIn">
          <h5>Нет учетной записи?</h5>
          <button class="button button-trust" v-on:click="changeSingIn(!signIn)">Зарегистрироваться</button>
        </div>
        <div class="register" v-if="!signIn">
          <h5>Уже зарегистрированы?</h5>
          <button class="button button-primary" v-on:click="changeSingIn(!signIn)">Войти</button>
        </div>
      </div>
    </div>
    <div class="main-info-padding">
      <div class="line-info-padding"></div>
    </div>
    <div class="main-info-wrapp">
      <div class="main-info-title">
        <img class="main-info-title-logo" src="../../assets/img/rrd_logo_color.svg">
        <p class="main-info-title-text">Документы Росреестра</p>
      </div>
      <div class="main-info-text">
        <p>
           Знакомство с сервисом
        </p>
      </div>
      <div class="main-info-video">
        <div class="main-info-frame-wrap">
          <iframe src="https://www.youtube.com/embed/hjAJUt6wtzU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// storage facilities
import store from '../../store/store'
import alertStore from '../../store/AlertStore'

// components
import SignIn from './SignIn'
import SignUp from './SignUp'

export default {
  name: 'SignsItem',
  components: {
    'sign-in': SignIn,
    'sign-up': SignUp
  },
  data () {
    return {
      // signIn: true
    }
  },
  computed: {
    signIn () {
      return store.getters.value('signIn')
    }
  },
  mounted () {
    if (localStorage.getItem('wantToSeeTariffs') !== null) {
      alertStore.dispatch('showAlert', {type: 'success', title: 'Выбор тарифа', text: 'Для того, чтобы выбрать тариф войдите или зарегестрируйтесь'})
    }
  },
  methods: {
    changeSingIn (value) {
      store.dispatch('setValue', {key: 'signIn', value: value})
    }
  }
}
</script>

<style lang="scss" scoped>
#sign-item{
  width: 100%;
  height: calc(100vh - 50px);
}
.wrapp {
  width: 35%;
  height: 100%;
  float: left;
  padding-right: 50px;
}
.sign-wrap {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: auto;
}
.register{
  text-align: center;
  margin-top: 10vh;
}
.main-info-padding {
  width: 1%;
  height: 100%;
  float: left;
  position: relative;
}
.line-info-padding {
  width: 1px;
  height: 80%;
  position: absolute;
  right:0;
  top:10%;
  background-color: rgba(0, 0, 0, 0.1);
}
.main-info-wrapp {
  width: 64%;
  height: 100%;
  float: left;
}
.main-info-title {
  width: 100%;
  height: 150px;
  text-align: center;
  margin-top: 60px;
}
.main-info-title-logo {
  height: 70px;
}
.main-info-title-text {
  margin-top: 10px;
  font-size: 12pt;
  font-weight: 300;
}
.main-info-text {
  width: 100%;
  height: 60px;
  text-align: center
}
.main-info-text p{
  font-size: 16pt;
}

.main-info-video {
  width: 100%;
  height: auto;
}
.main-info-frame-wrap {
  width: 60%;
  margin: auto;
}
.main-info-frame-wrap iframe {
  width: 100%;
  height: 400px;
}
</style>