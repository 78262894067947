import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    dateUpdated: 'undef',
    amountBalance: 'загрузка...',
    bonusesInfo: [],
    showPopupBalanceAddFlag: false,
    operationsPrices: { // для заполнения вызвать mixin getPrices()
      // print: 10,
      order: 4
    }
  },
  mutations: {
    setPrice (state, operObj) {
      state.operationsPrices[operObj.name] = operObj.value
    },
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    }
  },
  actions: {
    setPrice ({commit}, operObj) {
      commit('setPrice', operObj)
    },
    setValue ({commit}, keyValue) {
      commit('setValue', keyValue)
    }
  },
  getters: {  // usage balanceStore.getters.price('order')
    price: state => opName => {
      return state.operationsPrices[opName]
    },
    operations (state) {
      return state.operationsPrices
    },
    bonuses: state => bonusName => {
      for (let bonus in state.bonusesInfo) {
        // проверка полей
        if (state.bonusesInfo !== undefined && state.bonusesInfo[bonus] !== undefined && state.bonusesInfo[bonus].permission !== undefined && state.bonusesInfo[bonus].permission.name !== undefined) {
          // проверка на совпадения
          if (state.bonusesInfo[bonus].permission.name === bonusName) {
            return state.bonusesInfo[bonus].total
          }
        }
      }
      return null
    },
    value: state => key => {
      return state[key]
    }
  }
})

export default store
