<template>
    <div @stopTime="stopTime()">
      <div v-if="!showConfirmOrder" class="new-order-popup">
        <p class="new-order-title">Заказ документов</p>
        <h2 class="good-class">Заказ документов</h2>
        <div v-if="listCadNumForOrder.length > 0" class="popup-form">
          <div class="new-order-elem" v-for="(item, index) in listCadNumForOrder" v-show="(showFailNumbersFlag && !listCadNumForOrder[index].valid) || showAllNumbersFlag" id="new-order-elem" :key="index" v-bind:class="{'valid': listCadNumForOrder[index].valid, 'no-valid': !listCadNumForOrder[index].valid}">
            <div v-if="!listCadNumForOrder[index].changeing" class="new-order-elem-num" @click="activateEdit(index)">{{ listCadNumForOrder[index].number}}</div>
            <input v-else class="new-order-elem-num input" v-bind:ref="'inp' + index" type="text" placeholder="введите кадастровый номер" v-model="listCadNumForOrder[index].number" @input="checkValid(index)" @keyup.enter="(listCadNumForOrder[index].number !== '') ? disableEdit(index): null">
            <img class="new-order-elem-img" src="../../../assets/img/close.svg" @click="delete_item_new_order(index)">
          </div>
        </div>
        <div v-else>
          <p class="instruction">Введите кадастровые номера<br>или вставьте (ctrl + v)</p>
        </div>
        <div class="under-table-block">
          <div class="new-order-elem" style="text-align: center; cursor: pointer">
            <input class="input new-order-elem-num" id="input-under-table" type="text" v-model="cadNumInput" @keyup.enter="addFieldInCadNumForOrder(cadNumInput, 'clear')" @paste="onPaste($event)" 
            placeholder="введите кадастровый номер" autofocus>
            <div @click="addFieldInCadNumForOrder(cadNumInput, 'clear')"><img class="new-order-add-elem-img" src="../../../assets/img/plus.svg" alt="добавить кадастровый номер"></div>
          </div>
          <div>
            <p class="fail-number-warning" v-if="failNumberCount > 0">
              невалидные ЕГРН номера ({{ failNumberCount }})
              <button class="show-hide-fail" v-if="!showFailNumbersFlag" @click="showFailNumbers()">показать</button>
              <button class="show-hide-fail" v-else @click="showAllNumbers()">показать все</button>
            </p>
          </div>
        </div>
        
        <button class="button-close new-order-popup-button-close" @click="closeOrder()"><img src="../../../assets/img/close-form.svg" alt="закрыть окно заказа выписок"></button>
        <div class="new-order-control-block">
          <!-- <button class="button button-primary new-order-control" @click="beforeCreateOrder()" :class="{'newOrderButDisabled': readyForOrder == 0 || !hasEnoughMoney || readyForOrder > 1500}" :disabled="readyForOrder == 0 || !hasEnoughMoney || readyForOrder > 1500"> -->
          <button class="button button-primary new-order-control" @click="beforeCreateOrder()" :class="{'newOrderButDisabled': readyForOrder == 0 || readyForOrder > 1500}" :disabled="readyForOrder == 0 || readyForOrder > 1500">
            <img src="~@/assets/img/spinner-white.svg" alt="" v-show="loadingCheckBalance">
            Заказать
          </button>
          <button class="new-order-clear-button" @click="clearNewOrderList()">Очистить</button>
          <div class="calculator-block new-order-control" v-if="!disableBillingLocal">
            <!-- 1 заявка: <b>{{ orderPrice }}</b> руб<br>
            <p class="calculator-total" v-bind:class="{'not-enough' : !hasEnoughMoney}">итого: <b>{{ totalPrice }}</b> руб  </p> -->
            <p class="calculator-total">всего номеров: {{ listCadNumForOrder.length }}</p>
            <p v-if="!demoModeFlag && appSettings.SHOW_PRISES" class="calculator-total" :style="{'not-enough': !hasEnoughMoney}">доступно к заказу: <b>{{ orderCount }}</b> </p>
          </div>
        </div>
    </div>

    <!-- Форма подтверждения -->
    <div v-else>
      
      <div class="confirm-order-popup">
          <div v-if="!orderLoading"  >
          <!-- поиск по проектам -->
          <p class="new-order-title">Подтверждение заказа</p>
          <h3 class="good-class">Подтверждение заказа</h3>
          <div class="project-search">
            <input type="text" placeholder="Примечание к заказу" v-model="projectSearch" class="input project-search-input" @click="showProjectsList()" @input="get_projects_names()">
            <div class="project-search-result" v-if="showProjectsFlag && filtredProjects.length > 0">
              <div @click="selectProject(project)" v-for="project in filtredProjects" class="project-search-result-item" v-bind:key="project.id">
                {{ project.name }}
              </div>
            </div>
          </div>
          <div class="overlay-projects" @click="hideProjectsList()" v-if="showProjectsFlag"></div>
          <!-- поиск по проектам конец -->
          <!-- Калькулятор -->
          <div class="confirm-calculator-block new-order-control">
            <!-- 1 заявка: <b>{{ orderPrice }}</b> руб<br> -->
            <p class="confirm-field">Готовых к заказу документов: <b>{{ readyForOrder }}</b></p>
            <p class="confirm-field" v-if="failNumberCount > 0">Невалидных ЕГРН номеров: <b>{{ failNumberCount }}</b></p>
            <!-- <p class="calculator-total confirm-field" v-bind:class="{'not-enough' : !hasEnoughMoney}">Итого: <b>{{ totalPrice }}</b> руб <span v-if="!hasEnoughMoney" class="not-enough"><b> Недостаточно средств </b></span> </p> -->
          </div>
          <!-- калькулятор конец -->
          <!-- кнопки контроля -->
          <button class="button-close confirm-button-close" @click="cloasConfirmOrder()"><img src="../../../assets/img/close-form.svg" alt="Закрыть окно заказа выписок"></button>
          <div class="confirm-control">
            <button class="button button-accent confirm-control-button" @click="cloasConfirmOrder()">Назад</button>
            <button class="button button-primary confirm-control-button" @click="createOrder()" 
              > <img src="~@/assets/img/spinner-white.svg" alt="" v-show="loadingCheckBalance"> Заказать</button>
              <!-- :class="{'newOrderButDisabled': readyForOrder == 0 || !hasEnoughMoney}" :disabled="readyForOrder == 0 || !hasEnoughMoney">Заказать</button> -->
          </div>
          <!-- кнопки контроля конец -->
        </div>
        <div class="order-loading-block" v-else>
          <img src="../../../assets/img/rrd_logo_test.svg" alt="идет заказ выписок">
          <p class="order-loading-text">{{ orderLoadingText[textPointer] }}</p>
          <p class="order-loading-text">Осталось заказать выписок {{ ordersLeftMix }}</p>
        </div>
      </div>
    </div>
    <div v-if="showConfirmOrder" class="confirm-order-overlay" @click="cloasConfirmOrder()"></div>
  </div>
</template>

<script>
import { setTimeout, setInterval } from 'timers'

// storage facilities
import apiDataStore from '../../../store/apiDataStore'
import userStore from '../../../store/userStore'
import store from '../../../store/store'
import balanceStore from '../../../store/BalanceStore'
import alertStore from '../../../store/AlertStore'
import orderStore from '../../../store/orderStore'
import settingsStore from '../../../store/settingsStore'

var reg = new RegExp('^[0-9]{2}:[0-9]{2}:[0-9]{6,7}(:[0-9]{1,})$')

export default {
  props: ['listFlag'],
  name: 'order',
  data () {
    return {
      showProjectsFlag: false,
      listCadNumForOrder: [
        // {number: '', valid: false, changeing: false, show: true}
      ],
      projectSearch: '',
      selectedProject: '',
      isValid: null,
      cadNumInput: '',
      showConfirmOrder: false,
      showFailNumbersFlag: false,
      showAllNumbersFlag: true,
      timeOut: null,
      orderLoadingText: [
        'Подключение к базе Росреестра...',
        'Запрос сведений...',
        'Заказ документов...'
      ],
      textPointer: 0,
      timer: null,
      timerSearchProject: null,
      loadingCheckBalance: false
    }
  },
  computed: {
    appSettings () {
      return settingsStore.getters.settings
    },
    disableBillingLocal () {
      return store.getters.value('disableBillingFlag')
    },
    orderPrice () {
      return balanceStore.getters.price('order')
    },
    hasEnoughMoney () {
      return apiDataStore.getters.value('hasEnoughMoney')
    },
    totalPrice () {
      return apiDataStore.getters.value('totalPrice')
    },
    filtredProjects () {
      return apiDataStore.getters.value('filtredProjects')
    },
    havePermissionOrderLocal () {
      return userStore.getters.permission('order')
    },
    readyForOrder () {  //  подсчет готовых к заказу
      let count = 0
      for (let i = 0; i < this.listCadNumForOrder.length; i++) {
        if (this.listCadNumForOrder[i].valid) {
          count++
        }
      }
      if (count > 1500 && this.timeOut === null) {  // ограничение на 50 номеров за раз
        alertStore.dispatch('showAlert', {title: 'Много номеров', text: ' Не возможно закзать не более 1500 выписок за раз'})
        /* eslint-disable-next-line */
        this.timeOut = setTimeout(() => {
          /* eslint-disable-next-line */
          this.timeOut = null
        }, 50000)
      }
      return count
    },
    failNumberCount () {
      return this.listCadNumForOrder.length - this.readyForOrder
    },
    listFailCadNum () {
      let failList = []
      for (let i = 0; i < this.listCadNumForOrder.length; i++) {
        if (this.listCadNumForOrder[i].valid) {
          failList.push(this.listCadNumForOrder[i])
        }
      }
      return failList
    },
    orderCount () { // переделка без рублей(RRD-400)
      return Math.floor(balanceStore.getters.value('amountBalance') / balanceStore.getters.price('order')) + balanceStore.getters.bonuses('order')
    },
    orderLoading () {
      return apiDataStore.getters.value('orderLoading')
    },
    demoModeFlag () {
      return userStore.getters.value('demoMode')
    }
  },
  watch: {
    // readyForOrder (newVal, oldVal) {  // пересчет стоимости при добавлении или удалении элемента готового к заказу
    //   this.checkCanOrderMoney()
    // },
    listCadNumForOrder (newVal) {
      orderStore.dispatch('setList', {name: this.listFlag, value: newVal})
    },
    failNumberCount (newVal) {
      if (newVal === 0) {
        this.showAllNumbersFlag = true
        this.showFailNumbersFlag = false
      }
    }
  },
  mounted () {
    this.listCadNumForOrder = []
    let newList = orderStore.getters.numbersList(this.listFlag)
    for (let item in newList) {
      this.addFieldInCadNumForOrder(newList[item].number)
    }
    this.getAllUserBillingInfoMix()
    this.checkCanOrderMoney()
  },
  methods: {
    startTime () {  // надписи при заказе
      this.textPointer = 0
      this.timer = setInterval(() => {
        if (this.textPointer < this.orderLoadingText.length - 1) {
          this.textPointer++
        }
        if (this.textPointer === this.orderLoadingText.length - 1) {
          this.stopTime()
        }
      }, 2000)
    },
    stopTime () {
      this.timer = null
    },
    showAllNumbers () {
      this.showAllNumbersFlag = true
      this.showFailNumbersFlag = false
    },
    showFailNumbers () {
      this.showAllNumbersFlag = false
      this.showFailNumbersFlag = true
    },
    cloasConfirmOrder () {
      this.showConfirmOrder = false
    },
    disableEdit (index) {
      this.listCadNumForOrder[index].changeing = false
      document.getElementById('input-under-table').focus()
    },
    activateEdit (index) {
      for (let i = 0; i < this.listCadNumForOrder.length; i++) {  // отменить изменения других элементов
        this.listCadNumForOrder[i].changeing = false
      }
      this.listCadNumForOrder[index].changeing = true
      this.$nextTick(() => this.$refs['inp' + index][0].focus())
    },
    checkValid (index) {
      this.listCadNumForOrder[index].valid = reg.test(this.listCadNumForOrder[index].number)
    },
    addFieldInCadNumForOrder (number, clear) {
      if (number !== '') {
        if (number !== undefined) {
          if (!this.checkRepeatInList(number)) {
            this.isValid = reg.test(number)
            this.listCadNumForOrder.push({number: number, valid: this.isValid, changeing: false})
          }
        } else {
          this.listCadNumForOrder.push({number: '', valid: false, changeing: false})
        }
        if (clear !== undefined && clear === 'clear') { // если срабатывает условие, то чистим значние переменной после добавления, дабы очистить инпут
          this.cadNumInput = ''
        }
      }
    },
    closeOrder () {
      this.$emit('closeOrder')
      this.$emit('refreshUnserched')
    },
    selectProject (prj) {
      this.projectSearch = prj.name
      this.showProjectsFlag = false
      this.selectedProject = prj
    },
    clearNewOrderList () {
      this.listCadNumForOrder = []
      orderStore.dispatch('clearList', this.listFlag)
      apiDataStore.dispatch('setValue', {key: 'totalPrice', value: 0})
      apiDataStore.dispatch('setValue', {key: 'hasEnoughMoney', value: true})
      this.cadNumInput = ''
      this.timeOut = null
    },
    delete_item_new_order (index) {
      this.listCadNumForOrder.splice(index, 1)
    },
    checkCanOrderMoney () {
      if (this.listCadNumForOrder.length >= 0) {
        this.checkHasMoneyForOrderMix(this.readyForOrder)
      }
    },

    onPaste (e) {
      let listCadNumInput = ''
      if (window.clipboardData && window.clipboardData.getData) { // IE
        listCadNumInput = window.clipboardData.getData('Text')
      } else {  //  fireFox chrome
        listCadNumInput += (e.clipboardData.getData('text'))
      }
      listCadNumInput = listCadNumInput.replace(/\n\s{2,}/g, '\n')
      listCadNumInput = listCadNumInput.split('\n')
      for (let i = 0; i < listCadNumInput.length; i++) {
        listCadNumInput[i] = listCadNumInput[i].trim()
      }
      for (let i = 0; i < listCadNumInput.length; i++) { // проход по тем что добавляем
        this.addFieldInCadNumForOrder(listCadNumInput[i])
      }
      e.preventDefault()
    },

    beforeCreateOrder: async function () {
      this.getCurrentPermissionsInfo()
      await this.getSettingsFromApiAndSetToStore()
      console.log(this.appSettings.ORDER_IS_ACTIVE)
      if ((this.havePermissionOrderLocal && this.appSettings.ORDER_IS_ACTIVE) || this.demoModeFlag) {
        this.loadingCheckBalance = true
        await this.checkHasMoneyForOrderMix(this.readyForOrder)
        this.loadingCheckBalance = false
        if (this.hasEnoughMoney || this.demoModeFlag) {
          this.showConfirmOrder = true
        }
      } else {
        if (!this.havePermissionOrderLocal) {
          alertStore.dispatch('showAlert', {type: 'error', code: 1})
        } else {
          alertStore.dispatch('showAlert', {type: 'error', code: 3})
        }
      }
    },
    createOrder: async function () {
      if (this.demoModeFlag) {
        alertStore.dispatch('setValue', {key: 'offer', value: true})
      } else {
        this.startTime()
        this.getCurrentPermissionsInfo()
        if (this.havePermissionOrderLocal && this.appSettings.ORDER_IS_ACTIVE) {
          this.loadingCheckBalance = true
          await this.checkHasMoneyForOrderMix(this.readyForOrder)
          this.loadingCheckBalance = false
          if (this.hasEnoughMoney) {
            // this.closeOrder()
            let cadNums = []
            for (let i = 0; i < this.listCadNumForOrder.length; i++) {  // для отправки на заказ только номеров, а не объектов с флагами
              if (this.listCadNumForOrder[i].valid) {
                cadNums.push(this.listCadNumForOrder[i].number)
              }
            }
            this.createOrderMix(cadNums, this.selectedProject, this.projectSearch)
            this.showAllNumbersFlag = true
            this.showFailNumbers = false
            this.$router.push('/order')
            // this.showConfirmOrder = false
          }
        } else { // if (this.havePermissionOrderLocal && this.appSettings.ORDER_IS_ACTIVE) {
          if (!this.havePermissionOrderLocal) {
            alertStore.dispatch('showAlert', {type: 'error', code: 1})
          } else {
            alertStore.dispatch('showAlert', {type: 'error', code: 3})
          }
        }
      }
    },

    checkRepeatInList (number) {
      for (let j = 0; j < this.listCadNumForOrder.length; j++) {  // проход по тем что есть
        if (number === this.listCadNumForOrder[j].number) {
          return true  // нашелся повтор
        }
      }
      return false //  повтора нет
    },

    showProjectsList () {
      this.showProjectsFlag = true
    },

    get_projects_names () {
      if (this.timerSearchProject !== null) {
        clearTimeout(this.timerSearchProject._id)
      }
      this.timerSearchProject = setTimeout(() => {
        this.getProjectsNamesMix(this.projectSearch)
        if (this.filtredProjects.length === 0) {
          this.showProjectsFlag = false
        } else {
          this.showProjectsFlag = true
        }
      }, 200)
    },

    hideProjectsList () {
      this.showProjectsFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/varibles.scss";
.temp-calculator-total {
  // float: left;
  margin-bottom: 0;
  margin-top: 13px;
  font-size: 11pt;
}
.instruction {
  position: absolute;
  top: 200px;
  color: gray;
  font-size: 17pt;
  text-align: center;
  width: calc(100% - 40px);
}
.new-order-textarea-button-add{
  position: absolute;
  right: 47.5%;
  top: 46%;
}
.new-order-textarea{
  width: 100%;
  height: 300px;
  resize: none;
  background-color: $color-light;
  padding: 6px;
  outline: none;
  margin-top: 40px;
}
.popup-form {
  max-height: 300px;
  margin-top: 10px;
  width: 100%;
  overflow-y: auto;
  // margin-left: 68px;
}
.new-order-title{
  font-size: 16pt;
}
.new-order-popup{
  $w: 500px;
  $h: 500px;
  width: $w; 
  height: $h; 
  min-width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -($w/2.2);
  margin-top: -($h/2.2);
  z-index: 100;
  background-color: white;
  padding: 20px 25px;
}
.calculator-block {
  // position: absolute;
  // top: 25px;
  // left: 382px;
  float: left!important;
  font-size: 11pt;
}
.calculator-total {
  // float: left;
  margin-bottom: 0;
  font-size: 11pt;
}
.not-enough {
  color: red;
}
// search project styles
.project-search {
  position: absolute;
  margin-top: -7px;
  left: 25px;
  width: 170px;
  z-index: 110;
}
.project-search-result {
  background-color: rgb(255, 255, 255);
  border-radius: 2%;
  z-index: 115;
  margin-left: 3px;
  width: 250px;
  box-shadow: 2px 2px 7px 1px rgba($color: #000000, $alpha: 0.5);
}
.project-search-result-item {
  padding: 2px 2px 2px 17px;
}
.project-search-result-item:hover {
  background-color: $color-first;
  border-radius: 2%;
  border-bottom: 2px $color-first
}
.project-search-input {
  padding-left: 5px;
  margin: -10px 0 0 0;
  width: 255px;
  z-index: 110;
}
.overlay-projects {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // z-index: 100;
}
// end search project styles
.new-order-content, .new-order-content-empty{
  margin-top: 40px;
  width: 45%;
  height: 300px;
  float: right;
  overflow-y: auto;
}
.new-order-content-fail {
  height: 274px;
}
.new-order-elem{
  width: 100%;
  height: 30px;
  // float: left;
  padding-left: 5px;
  padding-right: 5px;
}
.new-order-elem-num{
  float: left;
  font-size: 12pt;
  margin:0;
  margin-top: 3px;
  width: 90%;
}
.new-order-elem-img {
  width: 13px;
  height: 13px;
  margin-top: 8px;
  margin-right: 3px;
  float: right;
  cursor: pointer;
}
.new-order-add-elem-img {
  width: 22px;
  height: 22px;
  margin: 2px -3px 0 0;
  float: right;
  cursor: pointer;
}
.newOrderButDisabled, .newOrderButDisabled:hover{
  background-color: rgba($color: #000000, $alpha: .5);
  cursor: default;
}
.valid {
  background-color: rgba(189, 195, 199, 0.3);
}
.no-valid {
  background-color: rgba(189, 195, 199, 0.5);
}
// .new-order-elem:hover{
//   background-color: rgba($color: $color-dark, $alpha: .2);
// }
.new-order-control-block {
  position: absolute;
  width: 90%;
  bottom: 25px;
  left: 25px;
}
.new-order-control{
  float: right;
  bottom: 20px;
  margin-right: 0;
  margin-bottom: 0;
}
.new-order-popup-button-close {
  position: absolute;
  top: 20px;
  right: 25px;
}
.new-order-clear-button {
  text-decoration: underline;
  float: right;
  // bottom: 20px;
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 13px;
}
.fail-number-warning {
  width: 80%;
  margin: 5px 0 0 0;
  height: 12px;
  font-size: 12pt;
  float: left;
  color: rgba(44, 62, 80,1.0);
}
.under-table-block {
  margin-top: 5px;
}
.show-hide-fail {
  // float: right;
  text-decoration: underline;
  color: black!important;
  // margin-top: 5px;
}
// подтверждение заказа
.confirm-order-popup {
  $w: 420px;
  $h: 270px;
  width: $w; 
  // height: $h; 
  min-width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -($w/2.2);
  margin-top: -($h/2.2);
  z-index: 110;
  background-color: white;
  padding: 20px 25px;
}
.confirm-order-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 105;
}
.confirm-calculator-block {
  float: left;
  margin-top: 30px;
}
.confirm-field {
  margin-bottom: 10px;
  font-size: 12pt!important;
}
.confirm-control {
  float: left;
  width: 100%;
  height: 38px;
  text-align: right;
}
.confirm-control-button {
  margin: 0;
}
.confirm-button-close {
  position: absolute;
  top: 20px;
  right: 25px;
}
// конец подтверждение заказа
.order-loading-block {
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
}
.order-loading-block img {
  height: 100%;
  width: 100%;
}
.order-loading-text {
  text-align: center;
  width: 100%;
}
</style>
