<template>
<div>
  <div class="alerts-wrapper">
    <div v-for="(item, index) in alertsQueue" :key="index">
      <!-- <transition-group name="errorAlert" enter-active-class="animated slideInRight faster"> -->
        <!-- @mouseenter='mouseenter' @mouseleave='mouseleave' -->
        <div key='err' class="alert-body error-body animated slideInRight faster" :style="{top: 70 + index * 120 + 'px'}" v-if="item.showAlert && item.type === 'error'">
          <div @click="hideAlert(item.alertId)" class="btn-close btn-error-close"><img src="../../assets/img/close-white.svg" alt="закрытие окна оповещения"></div>
          <div class="alert-title">
            <!-- <img src="../../assets/img/cancel.svg" alt=""> -->
            {{ item.alertTitle }}
          </div>
          <div class="alert-text">
            {{ item.alertText }}
          </div>
        </div>
        <!-- </transition-group> -->
        <div key='succ' class="alert-body success-body animated slideInRight faster" :style="{top: 70 + index * 120 + 'px'}" v-if="item.showAlert && item.type === 'success' && item.alertTitle !== '' && item.alertText !== ''">
          <div @click="hideAlert(item.alertId)" class="btn-close btn-success-close"><img src="../../assets/img/close-white.svg" alt="закрытие окна оповещения"></div>
          <div class="alert-title">
            <!-- <img src="../../assets/img/" alt=""> -->
            {{ item.alertTitle }}
          </div>
          <div class="alert-text">
            <p class="block-with-text">
              {{ item.alertText }}
            </p>
          </div>
        </div>
        <div key='warn' class="alert-body warning-body animated slideInRight faster" :style="{top: 70 + index * 120 + 'px'}" v-if="item.showAlert && item.type === 'warning' && item.alertTitle !== '' && item.alertText !== ''">
          <div @click="hideAlert(item.alertId)" class="btn-close btn-warning-close"><img src="../../assets/img/close-white.svg" alt="закрытие окна оповещения"></div>
          <div class="alert-title">
            <!-- <img src="../../assets/img/" alt=""> -->
            {{ item.alertTitle }}
          </div>
          <div class="alert-text">
            <p class="block-with-text">
              {{ item.alertText }}
            </p>
          </div>
        </div>
        <div class="alert-fullscreen" name="full" key="full" v-if="item.showAlert && item.type === 'fullscreen' && item.alertTitle !== '' && item.alertText !== ''">
          <h5>{{ item.alertTitle }}</h5>
          <div class="alert-fullscreen-content" v-if="item.showText">
            {{ item.alertText }}
        </div>
        <div class="alert-fullscreen-buttons-block">
          <button v-if="item.showAccept" class="button button-primary alert-button" @click="hideAlert(item.alertId, true)">{{ item.buttonAccept }}</button>
          <button v-if="item.showAbort" class="button button-accent alert-button" @click="hideAlert(item.alertId, false)">{{ item.buttonAbort }}</button>
        </div>
          <button class="button-close alert-fullscreen-close-button" @click="hideAlert(item.alertId, false)"><img src="../../assets/img/close-form.svg" alt="закрытие окна подтверждения"></button>
      </div>
      <div class="alert-fullscreen-overlay" @click="hideAlert(item.alertId, false)" name="alert-fullscreen-overlay" key="alert-fullscreen-overlay" v-if="item.showAlert && item.type === 'fullscreen' && item.alertTitle !== '' && item.alertText !== ''"></div>
    </div>
  </div>
</div>
</template>

<script>
  // storage facilities
  import alertStore from '../../store/AlertStore'

  export default {
    name: 'alert-component',
    data () {
      return {
        // localStore: alertStore.state,
        timerWoking: false,
        counter: '',
        secondsConuter: 0,
        delay: 5  //  sec
      }
    },
    computed: {
      alertsQueue () {
        return alertStore.getters.value('alertsQueue')
      }
    },
    methods: {
      mouseenter (alertId) {
        alertStore.dispatch('freezeTimer', alertId)
      },
      mouseleave (alertId) {
        alertStore.dispatch('unFreezeTimer', alertId)
      },
      hideAlert (alertId, result) {
        for (let i = 0; i < this.alertsQueue.length; i++) { // поиск алерта
          if (this.alertsQueue[i].alertId === alertId) {
            if (this.alertsQueue[i].emitParams.length && result !== undefined) { // если есть событие и у него есть параметры
              this.$emit(this.alertsQueue[i].emitName, {flag: result, params: this.alertsQueue[i].emitParams})
            } else {
              this.$emit(this.alertsQueue[i].emitName, result)
            }
          }
        }
        alertStore.dispatch('deleteAlert', alertId)
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../../assets/sass/vue2-animate.scss";
@import "../../assets/sass/varibles.scss";
  .errorAlert-enter, .errorAlert-leave-to {
    opacity: 0;
  }
  .alerts-wrapper {
    height: auto;
    position: fixed;
    width: 300px;
    top: 70px;
    right: 20px;
    z-index: 999999;
  }
  .alert-body {
    width: 300px;
    max-height: 300px;
    // position: ;
    right: 20px;
    // top: 70px;
    padding: 7px 0px;
    padding-right: 10px;
    padding-left: 20px;
    border-radius: 2px;
    box-shadow: 1px 1px 5px 1px rgba($color: #000000, $alpha: .2);
    z-index: 999999;
    color: $color-light;
    margin-bottom: 30px;
  }
  .alert-body:hover {
    cursor: default;
  }
  // .error-body {
  //   background-color: #df7979e7;
  // }
  .error-body {
    background-color: #df7979;    
  }
  // .success-body {
  //   background-color: rgba($color: $color-first, $alpha: .9);
  // }
  .success-body {
    background-color: $color-first;
  }
  .warning-body {
    background-color: rgb(184, 184, 50);
  }
  .alert-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .alert-title img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .alert-text {
    margin-bottom: 3px;
  }
  .btn-close {
    width: 20px;
    height: 20px;
    float: right;
    margin-left: 5px;
    text-align: center;
    // padding: 3px;
  }
  .btn-close img {
    margin-top: -7px;
    padding: 3px;
    width: 15px;
    height: 15px;
  }
  .btn-error-close:hover {
    background-color: #D93D4A;
    border-radius: 2px;
  }
  .btn-success-close:hover {
    background-color: $color-second;
    border-radius: 2px;
  }
  .btn-warning-close:hover {
    background-color: rgb(148, 148, 40);
    border-radius: 2px;
  }
  //fullscreen  style
  .alert-fullscreen {
    // position: absolute;
    position: fixed;
    width: 500px;
    height: auto;
    min-height: 200px;
    background-color: white;
    z-index: 9999999; // на *****9 больше чем alert-fullscreen-overlay (он в этом же компоненте)
    top: 50%;
    left: 50%;
    margin-top: calc(-10vh - 50px);  //  -50vh - heigth/2 - 50px  (пол экрана - пол высоты элемента - верхнее меню)
    margin-left: -250px;
    padding: 20px;
  }
  // .margin-auth {
  //   margin-left: calc(-250px + 40px);  //  -width/2 - 50vw  (-пол высоты элемента - пол экрана - боковое меню)
  // }
  // .margin-on-auth {
  //   margin-left: calc(-250px);  //  -width/2 - 50vw  (-пол высоты элемента - пол экрана - боковое меню)
  // }
  .alert-fullscreen-content {
    font-size: 12pt;
    margin-bottom: 50px;
  }
  .alert-fullscreen-buttons-block {
    float: right;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  .alert-fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-overlay;
    z-index: 999999;  // на ****9 больше чем меню в APP.vue
  }
  .alert-button {
    min-width: 70px;
  }
  .alert-fullscreen-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
</style>

