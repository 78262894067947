<template>
    <div class="upload-body">
      <button class="button button-trust" @click="openUploadDoc()" title="Вы можете загрузить свои документы к нам для их хранения и обработки">
        <img src="../../assets/img/share-arrow.svg" class="wait-download">
        Загрузить</button>
      <div class="form-wrap" v-if="uploadDoc">
        <div class="upload-title">
          <p>ЗАГРУЗКА ДОКУМЕНТОВ</p>
        </div>
        <input class="upload-file-input" type="file" name="file" id="file" multiple ref="newFiles" :value="filelist" accept=".zip" @change="newUpload()">
        <label class="button button-primary upload-file-label" for="file">Добавить архивы .zip</label>
        <input v-if="documents.length > 0" class="input notes-for-documents" v-model="note" type="text" placeholder="Примечание к документам">
        <div class="upload-list-files" v-if="documents.length > 0">
          <div class="upload-list-files-item" v-for="(item, index) in documents" :key="item.name">
            <p>{{ item.name }}</p>
            <!-- <input class="input" v-model="item.notes" placeholder="Введите примечание для документа"> -->
            <img src="../../assets/img/close.svg" @click="deleteItem(index)">
          </div>
        </div>
        <div v-else class="no-documents">
          <p>Поддерживаемые типы документов</p>
          <div class="no-document-table">
          <table cellspacing="0">
            <tr>
              <th>
                Тип
              </th>
              <th>
                Версия
              </th>
            </tr>
            <tr><td>Межевой План</td><td>06</td></tr>
            <tr><td>Кадастровый паспорт объекта капитального строительства</td><td>4.0.1</td></tr>
            <tr><td>Кадастровая выписка об объекте капитального строительства</td><td>3.0.1</td></tr>
            <tr><td>Кадастровая выписка о земельном участке</td><td>7.0.1</td></tr>
            <tr><td>Кадастровый паспорт земельного участка</td><td>6.0.1</td></tr>
            <tr><td>Выписка из ЕГРН об основных характеристиках и правах на земельный участок</td><td>-</td></tr>
            <tr><td>Кадастровый план территории</td><td>-</td></tr>
            <tr><td>Межевой План</td><td>06</td></tr>
          </table>
          </div>
        </div>
        <button class="button-close upload-controls-close" @click="closeUploadDoc()"><img src="../../assets/img/close-form.svg" alt="закрыть окно загрузки документов росреестра"></button>
        <div class="upload-controlls">
          <button class="button button-accent" @click="clearDoc()">Очистить</button>
          <button class="button button-primary" @click="beforeSubmit()" :disabled="documents.length <= 0" v-bind:class="{'disabled-button': documents.length <= 0}">Загрузить</button>
        </div>
      </div>
      <transition name="fade">
      <div class="succes-alert-message alert-message" v-if="successAlert">
        <p>{{currentUploadDoc}} загружен</p>
      </div>
      </transition>
      <transition name="fade">
      </transition>
      <div class="overlay-upload" v-if="uploadDoc" @click="closeUploadDoc()"></div>
      <div class="overlay-loading" v-if="loading"></div>
      <div class="popup-loading" v-if="loading">
        <p class="load-title">Загрузка...</p>
        <div class="load-item-wrapp">
          <div class="load-item" v-for="item in progress" :key="item.name">
            <p class="load-item-name">{{item['name']}}</p>
            <div class="load-item-progress" :style="{width: getWidthLoadLine(item['progress'])}"></div>
            <p class="load-item-percent">{{Math.round(item['progress'])}} %</p>
          </div>
        </div>
        <button v-if="!loadActive" class="close-load-btn button-close" @click="closeLoadDoc()"><img src="../../assets/img/close-form.svg" alt="закрытие окна загрузки документов росреестра"></button>
      </div>
    </div>
</template>

<script>
// storage facilities
import httpStore from '../../store/httpStore'
import documentStore from '../../store/documentStore'
import alertStore from '../../store/AlertStore'
import userStore from '../../store/userStore'

export default {
  name: 'DocumentUpload',
  data () {
    return {
      alertStore,
      url: '',
      loading: false,
      successAlert: false,
      files: null,
      documents: [],
      uploadDoc: false,
      progress: [],
      filelist: null,
      note: ''
    }
  },
  components: {
  },
  created () {
  },
  mounted: function () {
    this.url = this.baseUrl() + httpStore.state.me + '/documents/'
  },
  computed: {
    loadActive () {
      for (let item of this.progress) {
        if (item['progress'] < 100) {
          return true
        }
      }
      return false
    },
    demoModeFlag () {
      return userStore.getters.value('demoMode')
    }
  },
  methods: {
    beforeSubmit () {
      if (this.demoModeFlag) {
        alertStore.dispatch('setValue', {key: 'offer', value: true})
      } else {
        this.submit()
      }
    },
    closeUploadDoc () {
      this.uploadDoc = false
      documentStore.state.upldDoc = false
    },
    openUploadDoc () {
      this.uploadDoc = true
      documentStore.state.upldDoc = true
    },
    closeLoadDoc () {
      this.loading = false
      documentStore.state.upldDoc = false
    },
    clearDoc () {
      this.documents = []
    },
    getWidthLoadLine (progress) {
      return (280 / 100) * progress + 'px'
    },
    deleteItem (index) {
      this.documents.splice(index, 1)
    },
    newUpload () {
      for (let elem of this.$refs.newFiles.files) {
        if (elem.type !== 'application/x-zip-compressed' && elem.type !== 'application/zip' && elem.type !== 'application/octet-stream' && elem.type !== 'application/x-zip') {
          alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка загружаемых данных', text: 'Один или несколько загружаемых файлов имеют не подходящий формат. Убедитесь, что загружаете файлы расширение .zip'})
        } else {
          this.documents.push(elem)
        }
      }
      document.getElementById('file').value = null
    },
    submit: function () {
      this.uploadDoc = false
      this.progress = []
      let counter = 0
      let loadingController = this.documents.length
      this.loading = true
      // this.listControl = new Array(this.documents.length)
      for (let i = 0; i < this.documents.length; i++) {
        this.progress.push({'name': this.documents[i].name, 'progress': 0})
        let _data = new FormData()
        _data.append('file', this.documents[i], this.documents[i].name)
        if (this.note !== '') {
          this.documents[i].notes = this.note
        }
        _data.append('name', this.note)
        this.$http.post(this.url, _data, {
          progress: e => {
            this.progress[i]['progress'] = (e.loaded / e.total) * 100
          }
        }).then(() => {
          alertStore.dispatch('showAlert', {type: 'success', title: 'Успех', text: 'Документ ' + this.progress[i].name + ' загружен'})
          counter++
          if (counter === loadingController) {
            this.loading = false
            this.$emit('updateUploadedList')
          }
        }).catch(error => {
          alertStore.dispatch('showAlert', {type: 'error', title: 'Не удалось загрузить документы.', text: 'Произошла ошибка. Не удалось загрузить документы'})
          counter++
          if (counter === loadingController) {
            this.loading = false
          }
          console.log('errorStatus', error.status)
        })
      }
      this.documents = []
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/sass/varibles.scss";
.upload-body{
  float:right;
}
.form-wrap {
  width: 60%;
  height: 550px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -255px;
  margin-left: calc(40px - 30%);
  background-color: white;
  z-index: 9990;
  padding: 25px 20px;
}
.upload-list-files {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  height: 350px;
  padding: 20px;
  overflow-y: auto;
}
.upload-list-files-item{
  width: 100%;
  height: 40px;
  background-color: $color-light;
  padding: 10px;
  margin-bottom: 10px;
}
.upload-list-files p {
  height: 28px;
  font-size: 14pt;
  margin-top: -4px;
  margin-bottom: 0;
  float: left;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.upload-list-files input{
  width: 45%;
  float: left;
  margin-top: -4px;
  margin-bottom: 0;
}
.upload-list-files img{
  width: 15px;
  height: 15px;
  cursor: pointer;
  float: right;
  margin-top: 2px;
}
.upload-controlls{
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.upload-controlls button{
  margin-left: 0;
}
.upload-file-label{
  margin-left:0;
  cursor: pointer;
}
.upload-file-input{
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.overlay-upload{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: $color-overlay;
}
.upload-title{
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
.upload-title p{
  font-size: 14pt;
}
.no-documents{
  width: 80%;
  height: 320px;
  margin: auto;
  margin-top: 5px;
  text-align: left;
}
.no-document-table {
  width: 100%;
  height: 300px;
  overflow-y: auto;
}
.no-documents table {
  width: 100%;
}
.no-documents p {
  font-size: 16pt;
}
.no-documents td {
  padding-right: 40px;
  padding-left: 10px;
}
.no-documents th {
  padding-left: 10px;
}
.no-documents tr:nth-child(2n) {
  background-color: $color-light;
}
.alert-message{
  position: fixed;
  width: 380px;
  height: 70px;
  text-align: center;
  left: 50%;
  top: 0;
  margin-top: 60px;
  margin-left: -160px;
  background-color: $color-light;
  box-shadow: 1px 1px 7px 1px rgba($color: #000000, $alpha: .2);
}
.alert-message p {
  font-size: 14pt;
  margin-top: 23px;
}
.overlay-loading{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $color-overlay;
  z-index: 9;
}
.popup-loading{
  position: fixed;
  width: 500px;
  height: 500px;
  top: 50%;
  left: 50%;
  margin-left: -210px;
  margin-top: -250px; 
  background-color: white;
  color: rgba($color: #333333, $alpha: .7);
  padding: 25px 20px;
  z-index: 99;
  box-shadow: 1px 1px 7px 1px rgba($color: #000000, $alpha: .2);
}
.load-title {
  float: left;
  font-size: 18pt;
}
.load-item{
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  background-color: $color-light;
  float: left;
}
.load-item-name{
  float: left;
  width: 100px;
  display: block;
  font-size: 12pt;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin:0;
  margin-top: 5px;
  padding-left: 8px;
  padding-right: 5px;
}
.load-item-progress{
  display: block;
  height: 30px;
  float: left;
  background-color: rgba($color: $color-first, $alpha: 1);
}
.load-item-percent{
  display: block;
  width: 60px;
  float: right;
  font-size: 12pt;
  margin:0;
  margin-top: 5px;
  padding-left: 5px;
}
.close-load-btn{
  position: absolute;
  top: 25px;
  right: 20px;
}
.load-item-wrapp{
  margin-top: 30px;
  overflow-y: auto;
  width: 100%;
  height: 350px;
}
.close-load-btn:disabled{
  background-color: gray;
}
.disabled-button,
.disabled-button:hover {
  background-color: rgba($color: #000000, $alpha: .5);
  cursor: default;
}
.upload-controls-close {
  position: absolute;
  top: 25px;
  right: 20px;
}
.notes-for-documents {
  margin-left: 5%;
  width: 60%;
  text-align: center;
}
</style>
