<template>
  <div class="actions-for-selected-wrapper">
    <div class="slot-block" @click="setShowActionsForSelected(!showActionsForSelected)">
      <slot/>
    </div>
    <div class="actions-popup" v-if="showActionsForSelected && !loadingActions && actions.length > 0">
      <div v-for="action in actions" :key="action.id">
        <div class="actions-popup-elem" :class="{'action-disabled': !action.is_permitted}" @click="sendSelectedToProcess(action, 'onlyFirstTime')">
          <div class="action">
            {{ action.name }}
          </div>
          <div class="action-repeat" title="Обработать повторно" @click.stop="sendSelectedToProcess(action, 'all')">
            <img src="../../../assets/img/refresh.svg" class="refresh-img">
          </div>
          <div class="action-divide">
            |
          </div>
        </div>
        <!-- <button class="actions-popup-elem button" @click="sendSelectedToProcess(action)">
          {{ action.name }}
          <img src="../../../assets/img/refresh.svg" class="refresh-img">
        </button> -->
      </div>
    </div>
    <div class="actions-popup" v-if="showActionsForSelected && !loadingActions && actions.length === 0">
      <button class="actions-popup-elem">
        Нет общих действий
      </button>
    </div>
    <div class="actions-for-selected-overlay" v-if="showActionsForSelected && !loadingActions" @click="setShowActionsForSelected(!showActionsForSelected)"></div>
  </div>
</template>

<script>
// store
import apiDataStore from '../../../store/apiDataStore'
import alertStore from '../../../store/AlertStore'
import userStore from '../../../store/userStore'

export default {
  name: 'action-for-selected',
  props: ['selected'],
  data () {
    return {
      showActionsForSelected: false,
      typesIdArr: [],
      actions: [],
      loadingActions: false
    }
  },
  computed: {
    demoModeFlag () {
      return userStore.getters.value('demoMode')
    },
    loadingActionForSelected () {
      return apiDataStore.getters.value('loadingActionForSelected')
    }
  },
  watch: {
    loadingActions (newVal) { // само меняет состояние загрузки когда меняется loadingActions
      apiDataStore.dispatch('setValue', {key: 'loadingActionForSelected', value: newVal})
    }
  },
  methods: {
    sendSelectedToProcess (action, mode) {
      if (this.demoModeFlag) {
        alertStore.dispatch('setValue', {key: 'offer', value: true})
      } else {
        if (action.is_permitted) {
          this.sendToProcessesListMix(action, this.selected, mode)
          this.setShowActionsForSelected(false)
        } else {
          alertStore.dispatch('showAlert', {error: true, title: 'Недостаточно прав', text: 'У вас нет права для совершения данного действия'})
        }
      }
    },
    setShowActionsForSelected: async function (val) {
      if (val) {  // открытие элемента
        this.loadingActions = true
        this.typesIdArr = this.parseTypes(this.selected)
        this.actions = await this.getActionsByTypes(this.typesIdArr)
        this.loadingActions = false
      }
      this.showActionsForSelected = val
    },
    parseTypes (docsArr) {
      if (docsArr !== null && docsArr !== undefined && docsArr.length > 0) {  // если что-то передали
        let _docTypes = []
        for (let doc in docsArr) {
          if (!_docTypes.includes(docsArr[doc].type_id)) { //  если такого типа доков еще не было, то добавить в список
            _docTypes.push(docsArr[doc].type_id)
          }
        }
        return _docTypes
      } else {
        return null
      }
    },
    getActionsByTypes (typesIdArr) {
      return this.getUniversalActionsForTypesIdListMix(typesIdArr)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/varibles.scss";
$action-height: 40px;

.actions-popup {
  position: absolute;
  width: 200px;
  height: auto;
  background-color: white;
  box-shadow: $shadow;
  z-index: 100;
}
.actions-popup-elem {
  width: auto;
  height: $action-height;
  background-color: white;
  margin: 0;
  padding: 0;
  color: #333333;
  cursor: pointer;
  text-align: left;
  // padding-left: 25px;
  // padding-right: 10px;
}
.action {
  float: left;
  padding-left: 25px;
  padding-top: 7px;
  // display: inline-block;
  width: 80%;
  height: $action-height;
}
.action:hover {
  background-color: $color-light;
}
.action-repeat {
  float: right;
  width: 20%;
  // display: inline-block;
  padding-right: 11px;
  padding-top: 8px;
  height: $action-height;
}
.action-divide {
  // float: right;
  width: 1px;
  padding: 0;
  margin: 0;
  position: relative;
  left: 78%;
  bottom: 35px;
}
.refresh-img {
  width: 17px;
  height: 17px;
}
.action-repeat:hover {
  background-color: $color-light;
}
// .actions-popup-elem:hover {
//   background-color: $color-light;
// }
.actions-popup-elem.disabled, .actions-popup-elem.disabled:hover {
  color: rgba($color: gray, $alpha: 0.5);
  background-color: white;
  cursor: default;
}
.actions-for-selected-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.refresh-img {
  float: right;
  margin: 0!important;
  // margin: 5px;
  margin-top: 3px!important;
  cursor: pointer;
}
.action-disabled {
  color: rgb(177, 177, 177);
}
</style>
