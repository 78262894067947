import userStore from '../store/userStore'
import store from '../store/store'

export default {
  methods: {
    requestUserDataOnChangPage () {
      // console.log('requestUserDataOnChangPage')
      if (!userStore.getters.value('demoMode')) {
        store.dispatch('setValue', {key: 'userDataLoading', value: true})
        this.getAllUserBillingInfoMix()
        this.getCurrentPermissionsInfo()
      }
      this.getUserInfo()  // userDataLoding меняется внутри после загрузки данных
    }
  }
}
