import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    contextQuery: '',
    exQuery: '',  // объект с выбранными параметрами расширенного поиска
    exQueryString: '',  // то же что и exQuery, только распарсенное(не получается заствить ДокументЛист парсить при mouted computed переменную exQuery)
    loading: false,
    error: false,
    perPage: 20,
    count: 0,
    pagesCount: 0,
    onlyActual: true,
    onlyMy: false,
    resultSearchCounter: '',
    rows: [],
    ordersForSearch: '',
    nextPage: '',
    prevPage: '',
    showDatePicker: false,
    startDate: '',
    endDate: '',
    columns: [
      {
        label: 'Наименование',
        field: 'cadNumber',
        shown: true
      },
      {
        label: 'Тип',
        field: 'type',
        shown: true
      },
      {
        label: 'Реквизиты',
        field: 'registrationNumber',
        shown: true
      },
      {
        label: 'Регион',
        field: 'regionCode',
        shown: true
      },
      {
        label: 'Район',
        field: 'districtCode',
        shown: true
      },
      {
        label: 'Примечание',
        field: 'note.name',
        shown: true
      },
      {
        label: 'Статус идентификации',
        field: 'status.name',
        shown: false
      },
      {
        label: '',
        field: '',
        shown: true
      }
    ],
    upldDoc: false,
    resSearch: false,
    filters: {
      status: [],
      type: [],
      actualDate: {}
    },
    exsearchOpen: false // открыт ли расширенный поиск
  },

  mutations: {
    clrOrderForSearch (state) {
      state.ordersForSearch = ''
    },
    stOnlyActual (state, value) { //  st вместо set, для того, чтобы имя мутации отличалось от имени экшена
      state.onlyActual = value
    },
    stContextQuery (state, value) {
      state.contextQuery = value
    },
    stExQuery (state, value) {
      state.exQuery = value
    },
    stResSearch (state, value) {
      state.resSearch = value
    },
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    },
    setFilter (state, typeItem) {
      state.filters[typeItem.type].push(typeItem.item)
    },
    deleleFilter (state, typePos) {
      state.filters[typePos.type].splice(typePos.pos, 1)
    },
    clearFilters (state) {
      state.filters = {
        status: [],
        type: [],
        actualDate: {}
      }
    }
  },

  actions: {
    clearOrderFromSearch ({commit}) {
      commit('clrOrderForSearch')
    },
    setOnlyActual ({commit}, value) {
      commit('stOnlyActual', value)
    },
    setContextQuery ({commit}, value) {
      commit('stContextQuery', value)
    },
    setExQuery ({commit}, value) {
      commit('stExQuery', value)
    },
    setResSearch ({commit}, value) {
      commit('stResSearch', value)
    },
    setValue ({commit}, keyValue) {
      commit('setValue', keyValue)
    },
    setFilter ({commit}, typeItem) {
      commit('setFilter', typeItem)
    },
    deleleFilter ({commit}, typePos) {
      commit('deleleFilter', typePos)
    },
    clearFilters ({commit}) {
      commit('clearFilters')
    }
  },
  getters: {
    value: state => key => {
      return state[key]
    }
  }
})

export default store
