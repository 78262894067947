import Vue from 'vue'
import Router from 'vue-router'
// import Index from '@/components/Index/Index'
// import Stats from '@/components/Stats/Stats'
// import RequestList from '@/components/Requests/RequestList'
import DocumentList from '@/components/DocumentList/DocumentList'
import Ordering from '@/components/Ordering/Ordering'
// import PublicInformationList from '@/components/PublicInformationList/PublicInformationList'
import SignsItem from '@/components/Login/SignsItem'
// import MonitorEngineerItem from '@/components/MonitorEngineer/MonitorEngineerItem'
// import PackageProcessingItem from '@/components/Package/PackageProcessingItem'
import Page404Component from '@/components/Utils/Page404Component'
// import PageInProgressComponent from '@/components/Utils/PageInProgressComponent'
// import httpStore from '../store/httpStore'
import PageResetPassword from '@/components/Utils/ResetPassword'
import termOfUse from '@/components/Utils/TermOfUse'
import landPlans from '@/components/LandPlans/LandPlans'
import Upload from '@/components/Upload/Uploaded'
import PersonalCabinet from '@/components/PersonalCabinet/personalCabinet'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   name: 'Index',
    //   component: Index,
    //   meta: {
    //     title: 'Домашняя страница',
    //     inProgress: true
    //   }
    // },
    // {
    //   path: '/stats',
    //   name: 'Stats',
    //   component: Stats,
    //   meta: {
    //     title: 'Статистика',
    //     inProgress: true
    //   }
    // },
    // {
    //   path: '/request_list',
    //   name: 'RequestList',
    //   component: RequestList,
    //   meta: {
    //     title: 'Заявки',
    //     inProgress: true
    //   }
    // },
    {
      path: '/',
      name: 'documentList',
      component: DocumentList,
      props: (route) => ({ number: route.query.number }), // параметр для шаринга ссылок
      meta: {
        title: 'База документов',
        headTitle: 'База документов',
        inProgress: false
      }
    },
    {
      path: '/order',
      name: 'order',
      component: Ordering,
      meta: {
        title: 'Заказ выписок ЕГРН',
        headTitle: 'Заказ сведений',
        inProgress: false
      }
    },
    {
      path: '/landPlans',
      name: 'landPlans',
      component: landPlans,
      props: (route) => ({ number: route.query.number }), // параметр для шаринга ссылок
      meta: {
        title: 'Меж. и тех. планы',
        headTitle: 'Меж. и тех. планы',
        inProgress: false
      }
    },
    // {
    //   path: '/public_information_list',
    //   name: 'public-information-list',
    //   component: PublicInformationList,
    //   meta: {
    //     title: 'Открытые данные',
    //     inProgress: true
    //   }
    // },
    {
      path: '/login',
      name: 'SignsItem',
      component: SignsItem,
      props: (route) => ({ scrollTo: route.query.blockId }), // параметр для скролла страницы до нужного блока
      meta: {
        title: 'Документы Росреестра',
        headTitle: '',
        inProgress: false
      }
    },
    {
      path: '/profile',
      name: 'PersonalCabinet',
      component: PersonalCabinet,
      meta: {
        title: 'Профиль',
        headTitle: 'Профиль',
        inProgress: false
      }
    },
    {
      path: '/confirm_email_success',
      name: 'confirm_email_success',
      component: SignsItem,
      meta: {
        title: 'Документы Росреестра',
        headTitle: '',
        inProgress: false
      }
    },
    {
      path: '/accounts/profile/',
      name: 'accounts_profile',
      component: SignsItem,
      meta: {
        title: 'Профиль',
        headTitle: 'Профиль',
        inProgress: false
      }
    },
    {
      path: '/terms-of-use',
      name: 'term_of_use',
      component: termOfUse,
      meta: {
        title: 'Соглашение',
        headTitle: 'Соглашение',
        inProgress: false
      }
    },
    // {
    //   path: '/boss',
    //   name: 'BossProfile',
    //   component: BossProfile,
    //   meta: {
    //     title: 'документы Росреестра',
    //     inProgress: true
    //   }
    // },

    {
      path: '/404',
      name: 'page404',
      component: Page404Component,
      meta: {
        title: 'Документы Росреестра',
        headTitle: 'Ошибка',
        inProgress: false
      }
    },
    // {
    //   path: '/in_progress',
    //   name: 'page-in-progress',
    //   component: PageInProgressComponent,
    //   meta: {
    //     title: 'Страница в разработке',
    //     headTitle: 'В разработке',
    //     inProgress: false
    //   }
    // },
    {
      path: '/pay',
      name: 'pay-result',
      component: Ordering,
      meta: {
        title: 'Документы Росреестра',
        headTitle: 'Оплата',
        inProgress: false
      },
      children: [
        {
          path: 'success',
          component: Ordering,
          meta: {
            title: 'Документы Росреестра',
            headTitle: 'Оплата',
            inProgress: false
          }
        },
        {
          path: 'fail',
          component: Ordering,
          meta: {
            title: 'Документы Росреестра',
            headTitle: 'Оплата',
            inProgress: false
          }
        }
      ]
    },
    // первый путь на восстановление пароля (это баг)
    {
      path: '/user/password/reset/key/:privateLink',
      name: 'reset-password1',
      component: PageResetPassword,
      meta: {
        title: 'документы Росреестра',
        headTitle: 'Восстановление пароля',
        inProgress: false
      }
    },
    // второй путь на восстановление пароля
    {
      path: '/accounts/password/reset/key/:privateLink',
      name: 'reset-password2',
      component: PageResetPassword,
      meta: {
        title: 'документы Росреестра',
        headTitle: 'Восстановление пароля',
        inProgress: false
      }
    },
    {
      path: '/upload',
      name: 'Upload',
      component: Upload,
      meta: {
        title: 'Мои загрузки',
        headTitle: 'Мои загрузки',
        inProgress: false
      }
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
})

export default router
