<template>
  <div id="zaglushkaMainBlock" class="zaglushka-main-block">
    <div class="zaglushka-logo">
      <img src="../../assets/img/rrd-logo-color-black.svg" alt="лого технические работы">
      <h2>Сервис для автоматизации работы с документами Росреестра</h2>
    </div>
    <div class="function-list">
      <div class="function-list-innter">
        <h3> Данный веб сервис позволяет: </h3>
        <p class="item-in-list">Легко заказывать кадастровые выписки</p>
        <p class="item-in-list">Хранить и быстро находить документы</p>
        <p class="item-in-list">Подготавливать документы к печати в форматы .pdf и .docx</p>
        <p class="item-in-list">Отслеживать только актуальные документы</p>
        <p class="item-in-list">Скачивать несколько документов сразу</p>
        <p class="item-in-list">Получить доступ к открытой базе Росреестра</p>
      </div>
    </div>
    <div class="zaglushka-warning">
      К сожалению, сервис сейчас не доступен. Попробуйте чуточку позже, мы стараемся все исправить как можно быстрее<br><br>
      <a :href="urlForButton"><button class="button button-trust"> Попробовать еще раз </button></a>
    </div>
  </div>
</template>

<script>
// storage facilities
import httpStore from '../../store/httpStore'
import store from '../../store/store'

export default {
  data () {
    return {
      // timeout: null,
      sec: 5
    }
  },

  computed: {
    urlForButton () {
      return location.host
    },
    serverAlive () {
      return store.getters.value('serverAlive')
    }
  },

  mounted () {
    // console.log('in mounted stub')
    this.initServerChecker()
  },

  methods: {
    initServerChecker (time) {
      // console.log('sec = ' + this.sec)
      setTimeout(() => {
        this.$http({url: httpStore.getters.host + httpStore.getters.value('testServer'), method: 'options'}).then((response) => {
          if (response.status !== 200) {
            store.dispatch('setValue', {key: 'serverAlive', value: false})
          } else {
            store.dispatch('setValue', {key: 'serverAlive', value: true})
            this.sec *= 2
            this.initServerChecker(this.sec)
          }
        }).catch((err) => {
          console.log(err.status)
          store.dispatch('setValue', {key: 'serverAlive', value: false})
          this.sec *= 2
          this.initServerChecker(this.sec)
        })
      }, 1000 * time)
    }
  }
}
</script>


<style scoped>
      p, div, h1, h2, h3, h4, h5, input {
      font-family: 'Roboto', sans-serif;
    }
    .zaglushka-logo {
      width: 100%;
      text-align: center;
    }
    .zaglushka-logo img {
      width: 20%;
    }
    .function-list {
      text-align: center;
    }
    .function-list-innter {
      /* width: 50%; */
      text-align: left;
      display: inline-block;
      /* margin-left: -50px; */
    }
    .item-in-list {
      margin-left: 20px;
      font-size: 14pt;
    }
    .zaglushka-warning {
      text-align: center;
      margin-top: 100px;
      font-size: 14pt;
    }
    .button {
      margin: 7px;
      border-radius: 2px;
      white-space: nowrap;
      outline: none;
      cursor: pointer;
    }
    .button-trust {
      background-color: #27ae60;
      color: #e8e8e8;
      padding: 7px 10px;
      font-size: 14pt;
      font-weight: 500;
      outline: none;
    }
    a {
      outline: none;
      border: 0;
      border-style: none;
      border-width: 0;
    }
    button {
      outline: none;
      border: 0;
      border-style: none;
      border-width: 0;
    }
    .zaglushka-main-block {
      padding: 60px;
    }
</style>
