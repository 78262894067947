import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    listCadNumForOrder: { // {number: '213123131', valid: false, changeing: false, show: true} - необходимая структура
      order: [],
      unsearched: []
    },
    orderContextSearch: '',
    filters: {
      status: []
    }
  },
  mutations: {
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    },
    setList (state, list) {
      if (list.value.length > 0) {  // если поле не пустое
        if (list.value[0]['valid'] === undefined || list.value[0]['changeing'] === undefined) {  // если нет нужной стркутуры(просто массив номеров), от сделать её
          let newList = []
          for (let item in list.value) {
            newList.push({number: list.value[item], valid: null, changeing: null, show: null})
          }
          state.listCadNumForOrder[list.name] = newList // установить новое значение
        } else {  // если сразу нужная структура, то просто установить значение
          state.listCadNumForOrder[list.name] = list.value
        }
      }
    },
    clearList (state, listName) {
      state.listCadNumForOrder[listName] = []
    }
  },
  actions: {
    //  установить пару ключ: значение (keyValue - {key: 'key', value: 'value'})
    setValue ({commit}, keyValue) {
      commit('setValue', keyValue)
    },
    setList ({commit}, list) {
      commit('setList', list)
    },
    clearList ({commit}, listName) {
      commit('clearList', listName)
    }
  },
  getters: {
    value: store => key => {
      return store[key]
    },
    numbersList: store => key => {
      return store.listCadNumForOrder[key]
    }
  }
})

export default store
