import httpStore from '../store/httpStore'
import apiDataStore from '../store/apiDataStore'
import alertStore from '../store/AlertStore'
import userStore from '../store/userStore'
import landPlansStore from '../store/landPlansStore'
import uploadedStore from '../store/UploadedStore'

export default {
  data () {
    return {
      //  некоторые данные дублируются из хранилища, чтобы каждый раз не обращасть к оному, плюс их удобнее заполнить тут и сразу готовые отправить в хранилище
      actionsForDocMix: [],
      documentActionCompleteMix: [],
      // documentActionCompleteMix: { // статус действий (печатали, не печатали) и ссылка на скачивание, если успешно
      //  его структура
      //   [
      //     name: action code
      //     status: 0,
      //     url: null
      //   ]
      // },
      typeListMix: [], //  список типов документов для расширенного поиска
      totalPriceMix: 0,
      hasEnoughMoneyMix: true,
      progressMix: [],  //  прогресс загрузки документов
      countOrderedMix: 0,  //  количество заказанных документов удвлетворяющих фильтру (внизу страницы надпись "Всего заказов: 999")
      pageCountOrderedMix: 0,  // сколько страниц нужно для отображения всех заказанных доументов (страница 3 из 100500)
      ordersLeftMix: 0
    }
  },
  methods: {

    // begin dialogWindow.vue

    // получает допустимые действия для документа и вызывает getProcessDetails
    // результат в actionsForDoc
    // НУЖНО заменить эту логику на новый запрос GET /api/v1/me/package_processing/package_actions_with_status/{id}
    getActionsMix: function (item) {  //  item - документ
      return new Promise(resolve => {
        if (item.type_id !== null) {
          apiDataStore.dispatch('setValue', {key: 'loadingActionsForDocMix', value: true})
          let _url = httpStore.getters.host + httpStore.getters.getActionsForCurrentDoc + item.type_id
          this.$http.get(_url).then(response => {
            apiDataStore.dispatch('setValue', {key: 'actionsForDoc', value: response.body.results})
            this.actions = response.body.results
            // this.getProcessDetails(response.body.results, item.id)
            this.getProcessDetails(response.body.results, item.id).then(value => {
              apiDataStore.dispatch('setValue', {key: 'documentActionComplete', value: value})
              apiDataStore.dispatch('setValue', {key: 'loadingActionsForDocMix', value: false})
              resolve(value)
            })
          }).catch(error => {
            console.log(error.status)
            apiDataStore.dispatch('setValue', {key: 'actionsForDoc', value: []})
            apiDataStore.dispatch('setValue', {key: 'loadingActionsForDocMix', value: false})
            resolve([])
          })
        } else {
          apiDataStore.dispatch('setValue', {key: 'actionsForDoc', value: []})
          apiDataStore.dispatch('setValue', {key: 'loadingActionsForDocMix', value: false})
          resolve([])
        }
      })
    },

    // вызывается внутри getActionsMix потому что результат обработки нужен сразу и данные этому методу можно передать сразу актуальные только так
    //  заполняет данные о состоянии файла пытались скачать, получилось ли
    //  результат в documentActionComplete
    // actions - массив действий
    // docId - ид пакета(документа)
    getProcessDetails (actions, docId) {
      return new Promise(resolve => {
        for (let key = 0; key < actions.length; key++) {
          let _urlProcessDetail = httpStore.getters.host + httpStore.getters.value('porcessDetail') + docId + '?operation_code=' + actions[key].code //  /api/v1/me/package_processing/package_processes/{id}?operation_code="print etc."
          this.$http.get(_urlProcessDetail).then(response => {
            if (response.body.result === null) {
              this.documentActionCompleteMix.push({
                name: actions[key].code,
                status: 0,
                url: null
              })
            } else {
              this.checkProcessDetailResponseCode(this.actions[key].code, response.body.result) // если с документом что-то происходило, и ответ не нулл, то смотрим что
            }
            // apiDataStore.dispatch('setValue', {key: 'loadingActionsForDocMix', value: false})
          }, (err) => {
            console.log(err.status)
            this.documentActionCompleteMix.push({
              name: actions[key].code,
              status: 0,
              url: null
            })
          })
        }
        // apiDataStore.dispatch('setValue', {key: 'loadingActionsForDocMix', value: false})
        // apiDataStore.dispatch('setValue', {key: 'documentActionComplete', value: this.documentActionCompleteMix})
        resolve(this.documentActionCompleteMix)
      })
    },

    // возвращает ПРОМИС. При разрешении промиса "детали операции" (была произведена или нет, если да, то еще и урл)
    //  action - действие(печать и пр.)
    //  doc - документ
    getProcessDetailsForSingleActionAndSingleDoc (action, doc) {
      let _urlProcessDetail = ''
      _urlProcessDetail = httpStore.getters.host + httpStore.getters.value('porcessDetail') + doc.id + '?operation_code=' + action.code //  /api/v1/me/package_processing/package_processes/{id}?operation_code="print etc."
      return this.$http.get(_urlProcessDetail)
    },

    checkProcessDetailResponseCode (actionCode, result) {
      switch (result.status.code) {
        case 200:
          this.documentActionCompleteMix.push({
            name: actionCode,
            status: 200,
            url: result.file
          })
          break

        case 202:
          this.documentActionCompleteMix.push({
            name: actionCode,
            status: 202,
            url: null
          })
          break
        case 500:
          this.documentActionCompleteMix.push({
            name: actionCode,
            status: 500,
            url: null
          })
          break

        default:
          break
      }
    },

    //  создает процесс (печать/очистка/пр.)
    sendToProcessesMix (actionid, item) {
      if (item.status === 200) {
        let _package = ''
        if (item !== null) {
          _package = item.id
        }
        let _action = ''
        _action = '[' + actionid + ']'
        let _data = '{'
        _data += '"packageid": "' + _package + '", '
        _data += '"actionsid": ' + _action + '}'
        let _urlCreateProcess = httpStore.getters.host + httpStore.getters.value('createProces')
        this.$http.post(_urlCreateProcess, _data).then(() => {
          alertStore.dispatch('showAlert', {type: 'success', title: 'Успешно!', text: 'Документ поступил в обработку. Это займёт несколько минут.'})
        }).catch(error => {
          this.handleError(error.status)
          alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка выполнения', text: 'Не удалось выполнить действие', code: error.status})
          console.log(error)
        })
      } else {
        alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка операции.', text: 'Операция не доступна. Возможно, документ еще в обрабоке.'})
      }
    },
    //  создает процессЫ (их много) (печать/очистка/пр.)
    sendToProcessesListMix (actionsList, itemList, mode) {  // пока что actionsList просто название и передается одно действие
      apiDataStore.dispatch('setValue', {key: 'loadingSendToProcessesListMix', value: true})
      let _action = '[' + actionsList.id + ']'
      let _urlCreateProcess = httpStore.getters.host + httpStore.getters.value('createProces')
      let _arrPromises = []
      for (let item of itemList) {
        if (item.status === 200) {
          if (mode === 'onlyFirstTime') { // если мод "только тех кто в первый раз" то проверим печатались ли они
            this.getProcessDetailsForSingleActionAndSingleDoc(actionsList, item).then(result => {
              if (result.body.result === null) {
                let _package = ''
                if (item !== null) {
                  _package = item.id
                }
                let _data = '{'
                _data += '"packageid": "' + _package + '", '
                _data += '"actionsid": ' + _action + '}'
                _arrPromises.push(this.$http.post(_urlCreateProcess, _data))
              }
            })
          } else {  // иначе мод "все", т.е. без проверки
            let _package = ''
            if (item !== null) {
              _package = item.id
            }
            let _data = '{'
            _data += '"packageid": "' + _package + '", '
            _data += '"actionsid": ' + _action + '}'
            _arrPromises.push(this.$http.post(_urlCreateProcess, _data))
          }
        }
      }
      Promise.all(_arrPromises).then(() => {
        apiDataStore.dispatch('setValue', {key: 'loadingSendToProcessesListMix', value: false})
        alertStore.dispatch('showAlert', {title: 'Все документы отправлены в обработку', text: 'В ближайшее время они будут обработаны', type: 'success'})
      }, reason => {
        console.log(reason)
        apiDataStore.dispatch('setValue', {key: 'loadingSendToProcessesListMix', value: false})
        alertStore.dispatch('showAlert', {title: 'Один или несколько документов не отправились в обработку', text: '', showText: false})
      })
    },

    // end dialogWindow.vue
    // begin DocumentExtendedSearch

    // полчучает типы документов для расширенного поиска
    getDocumentsTypesMix: function () {
      let _url = httpStore.getters.host + httpStore.getters.value('getDocumentsTypes')
      this.$http.get(_url).then(response => {
        // console.log(response.body.results)
        this.typeListMix = response.body.results
        apiDataStore.dispatch('setValue', {key: 'typeList', value: response.data.results})
      }).catch(error => {
        console.log(error.status)
      })
    },

    // end DocumentExtendedSearch
    // begin ResultSearch

    // проверяет хватит ли денег на заказ
    checkHasMoneyForOrderMix: function (countSelected) {  // when change checkbox and mounted
      return new Promise(resolve => {
        if (!userStore.getters.value('demoMode')) {
          let _checkHasMoneyForOrderUrl = httpStore.getters.host + httpStore.getters.value('checkHasMoneyForOrder')   //  /api/v1/billing/check-fullness-balance/order
          let _datacheckHasMoneyForOrder = {}
          _datacheckHasMoneyForOrder['count'] = countSelected
          this.$http.post(_checkHasMoneyForOrderUrl, JSON.stringify(_datacheckHasMoneyForOrder)).then(response => {
            // console.log(response.body)
            this.totalPriceMix = response.body.sum
            this.hasEnoughMoneyMix = response.body.result
            apiDataStore.dispatch('setValue', {key: 'totalPrice', value: response.body.sum})
            apiDataStore.dispatch('setValue', {key: 'hasEnoughMoney', value: response.body.result})
            if (!response.body.result) {
              alertStore.dispatch('showAlert', {title: 'Не достаточно средств', text: 'Пополните баланс или закажите меньше выписок'})
            }
            resolve(response.body.result)
          }).catch(error => {
            alertStore.dispatch('showAlert', {type: 'error', title: 'Не удалось проверить баланс', text: 'Произошла ошибка, не удалось проверить баланс'})
            console.log(error)
            resolve(false)
          })
        } else {
          this.hasEnoughMoneyMix = true
          resolve(true)
        }
      })
    },

    // end ResultSearch
    // begin DocumentList

    // загрузить конкретный докумнт
    // также используется в Uploaded.vue
    // промис для downloadUploadedSelectedMix
    downloadDocMix: function (currentDocId, type) {
      console.log(type)
      return new Promise((resolve) => {
        console.log(currentDocId)
        let _url = httpStore.getters.host + httpStore.getters.value('downloadDoc') + currentDocId //  /api/v1/me/documents/
        this.$http.get(_url).then(response => {
          if (type === 'source') {
            window.location.href = response.data.source_file
            resolve('source ready')
          } else {
            if (type === 'private') {
              window.location.href = response.data.private_file
              resolve('private ready')
            } else {
              window.location.href = response.data.source_file
              resolve('else source')
            }
          }
        }).catch(error => {
          alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка загрузки документа.', text: 'Произошла ошибка при скачивании документа.', code: error.status})
          console.log(error.status)
          resolve('in catch')
        })
      })
    },

    //  скачать выбранные
    downloadSelectedMix: function (data) {
      apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageState', value: true})
      // console.log(data)
      let _url = httpStore.getters.host + httpStore.getters.value('downloadSelected')
      // console.log(_url)
      this.$http.post(_url, JSON.stringify(data)).then(response => {
        // console.log(response.data.url_to_pack)
        window.location.href = response.data.url_to_pack
        apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageState', value: false})
      }).catch(error => {
        apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageState', value: false})
        alertStore.dispatch('showAlert', {type: 'error', title: 'Скачивание документов не выполнено', text: error.data.detail, code: error.status})
        console.log(error)
      })
    },

    //  скачать все
    downloadAllMix: function (query) {
      apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageState', value: true})
      let _url = httpStore.getters.host + httpStore.getters.value('downloadAll')
      // console.log(_url + query)
      this.$http.get(_url + query).then(response => {
        // console.log(response.data)
        window.location.href = response.data.url_to_pack
        apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageState', value: false})
      }).catch(error => {
        console.log(error.data.detail)
        apiDataStore.dispatch('setValue', {key: 'multiDownloadPackageState', value: false})
        alertStore.dispatch('showAlert', {type: 'error', title: 'Скачивание документов не выполнено', text: error.data.detail, code: error.status})
      })
    },

    // удаление документа
    // НЕ МЕНЯЛ, ПРОСТО ВЫНЕС НЕ РАБОТАЕТ.
    deleteDocsMix (listId) {
      this.$http.post(this.baseUrl() + httpStore.state.me + this.url + this.urlDelete, JSON.stringify(listId)).then(() => {
        console.log('docs deleted')
      }).catch(error => {
        alertStore.dispatch('showAlert', {type: 'error', title: 'Не удалось удалить документ.', text: 'Произошла ошибка, в следствии которой не удалось удалть документ', code: error.status})
        console.log(error.status)
      })
    },

    // end DocumentList

    // не раобтает логика слишком завязана на компоненте (отображение прогрусса загрузки) так что это осталось в DocumentUpload.vue
    // загрузка документов на ресурс
    // uploadDocMix: function (document, i) {
    //   let _url = httpStore.getters.host + httpStore.getters.value('uploadDoc')
    //   // this.progressMix = []
    //   this.progressMix.push({'name': document.name, 'progress': 0})
    //   let _data = new FormData()
    //   _data.append('file', document, document.name)
    //   if (!document.notes) {
    //     document.notes = document.name
    //   }
    //   _data.append('name', document.notes)
    //   apiDataStore.dispatch('setValue', {key: 'loadingUpload', value: true})
    //   this.$http.post(_url, _data, {
    //     progressMix: e => {
    //       this.progressMix[i]['progress'] = (e.loaded / e.total) * 100
    //       apiDataStore.dispatch('setValue', {key: 'progress', value: this.progressMix})
    //     }
    //   }).then(response => {
    //     alertStore.dispatch('showAlert', {type: 'success', title: 'Успех', text: 'Документ ' + this.progressMix[i].name + ' загружен'})
    //   }).catch(error => {
    //     alertStore.dispatch('showAlert', {type: 'error', title: 'Не удалось загрузить документы.', text: 'Произошла ошибка, вследствие которой, не удалось загрузить документы'})
    //     console.log('error_status', error.status)
    //   })
    // }

    // begin SignIn.vue

    //  делает запрос на восстановление, "/rest-auth/password/reset/" data = {"email": str}
    resetPasswordMix () {
      apiDataStore.dispatch('setValue', {key: 'loadingSendEmail', value: true})
      let _urlReset = httpStore.getters.host + httpStore.getters.value('resetPassword')
      this.$http.post(_urlReset, JSON.stringify({'email': this.mailReset})).then(response => {
        // console.log(response)
        apiDataStore.dispatch('setValue', {key: 'loadingSendEmail', value: false})
        if (response.status === 200) {
          alertStore.dispatch('showAlert', {type: 'fullscreen', title: 'Успешно', text: 'Дальнейшие инструкции высланы на введенную почту', showAbort: false})
        } else {
          alertStore.dispatch('showAlert', {type: 'error', title: 'Данный почтовый адрес отсутствует!', text: 'Сожалеем, но мы ничего не знаем о данном почтовом адресе, возможно, вы ошиблись в написании или при регистрации использовали какой-то другой'})
        }
      }).catch((error) => {
        console.log(error.status)
        apiDataStore.dispatch('setValue', {key: 'loadingSendEmail', value: false})
        if (error.body.email !== undefined) {
          alertStore.dispatch('showAlert', {type: 'error', title: 'Данный почтовый адрес отсутствует', text: error.body.email.email[0]})
        } else {
          alertStore.dispatch('showAlert', {type: 'error', title: 'Данный почтовый адрес отсутствует', text: 'Сожалеем, но мы ничего не знаем о данном почтовом адресе, возможно, вы ошиблись в написании или при регистрации использовали какой-то другой'})
        }
      })
    },

    // end SignIn.vue
    // begin SignUp.Vue

    // регистрация пользователя
    registrationMix: function (query) {
      let _url = httpStore.getters.host + httpStore.getters.value('registration')
      apiDataStore.dispatch('setValue', {key: 'loadingRegister', value: true})
      this.$http.post(_url, query).then(() => {
        // console.log(response.status)
        window.ym(51614945, 'reachGoal', 'registrationEventMain')
        alertStore.dispatch('showAlert', {type: 'fullscreen', title: 'Отлично!', text: 'Для завершения регистрации перейдите по ссылке в отправленном Вам письме', showAbort: false, buttonAccept: 'Понятно'})
        apiDataStore.dispatch('setValue', {key: 'loadingRegister', value: false})
        this.$emit('backToLogin')
      }).catch(error => {
        console.log(error.status)
        // this.showErrorAlert = true
        if (error.body.email) {
          alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка', text: 'Регистрация не пройдена, возникла ошибка: ' + error.body.email[0]})
        } else {
          if (error.body.password1) {
            alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка', text: 'Регистрация не пройдена, возникла ошибка: ' + error.body.password1[0]})
          } else {
            if (error.body.username) {
              alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка', text: 'Регистрация не пройдена, возникла ошибка: ' + error.body.username[0]})
            } else {
              alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка', text: 'Регистрация не пройдена, возникла ошибка'})
            }
          }
        }
        apiDataStore.dispatch('setValue', {key: 'loadingRegister', value: false})
      })
    },

    // end SignUp.vue
    // begin Balance.vue

    // пополнение баланса
    fillUpBalanceMix (correctUserSummForFillUp) {
      let _url = httpStore.getters.host + httpStore.getters.value('fillUpBalance')
      let data = {}
      data['amount'] = correctUserSummForFillUp
      this.$http.post(_url, JSON.stringify(data)).then(response => {
        console.log(response)
        window.location.href = response.body.redirect_url_access_pay
      }).catch(error => {
        console.log(error)
        alertStore.dispatch('showAlert', {type: 'error', title: 'Баланс не пополнен', text: 'Произошла ошибка, в следствии которой не удалось пополнить баланс'})
      })
    },

    // end Balance.vue
    // begin Ordering.vue

    // получает имена проектов
    getProjectsNamesMix (prjName) {
      let _url = httpStore.getters.host + httpStore.getters.value('getProjectsNames') //  'http://192.168.2.76:8084/api/v1/me/projects/?search='
      this.$http.get(_url + prjName).then(response => {
        let projects = []
        for (let i = 0; i < response.body.length; i++) {  // отсеиваем имена с пустыми именами
          if (response.body[i].name !== '') {
            projects.push(response.body[i])
          }
        }
        apiDataStore.dispatch('setValue', {key: 'filtredProjects', value: projects})
      }).catch(error => {
        console.log(error.status)
      })
    },

    // получает список заказов для отображения на странице а также кол-во документов и необходимое кол-во страниц
    getOrderDataMix (limit, offset, search, statusId = '') {
      //  /api/v1/me/orders/?page=pageNumber&search=SomeSearch&status_list=statusId
      let _url = httpStore.getters.host + httpStore.getters.value('getOrderData') + limit + '&offset=' + offset + '&search=' + search + '&status_list=' + statusId
      apiDataStore.dispatch('setValue', {key: 'loadingOrderData', value: true})
      this.$http.get(_url).then(response => {
        // this.ordered = response.data.results
        // this.count = response.data.count
        // this.loading = false
        apiDataStore.dispatch('setValue', {key: 'ordered', value: response.data.results})
        apiDataStore.dispatch('setValue', {key: 'countOrdered', value: response.data.count})
        this.countOrderedMix = response.data.count
        apiDataStore.dispatch('setValue', {key: 'loadingOrderData', value: false})
        if (offset === 0) { // высчитываение необходимого кол-ва страниц для отображения всех документов
          let lenResult = response.data.results.length  //  кол-во документов
          this.pageCountOrderedMix = Math.floor(this.countOrderedMix / lenResult) //  кол-во документов/кол-во пришедших для отображения на странице
          if (this.count % lenResult !== 0) { this.pageCountOrderedMix++ }  // не профукать последню страницу
          apiDataStore.dispatch('setValue', {key: 'countOrdered', value: this.countOrderedMix})
          apiDataStore.dispatch('setValue', {key: 'pageCountOrdered', value: this.pageCountOrderedMix})
        }
      }).catch(error => {
        console.log(error.status)
        apiDataStore.dispatch('setValue', {key: 'loadingOrderData', value: false})
        apiDataStore.dispatch('setValue', {key: 'ordered', value: []})
      })
    },

    // используется в resultSearch.vue и Ordering.vue
    // создание заказа
    selectProjectMix (selectedProject, projectSearch, selectProjectStatus) { //  для того, чтобы при заказе 1000 документов не создавать 1000 проектов
      return new Promise((resolve, reject) => {
        // console.log(selectedProject)
        // console.log(projectSearch)
        // console.log(selectProjectStatus)
        switch (selectProjectStatus) {
          case 'maybeUserSelectProject': { //  надежда на то, что пользователь выбрал проект
            if (selectedProject !== undefined && projectSearch !== undefined) { // если имя проекта введено
              if (selectedProject.name === projectSearch) { // если выбранное и введенное совпадают(т.е. юзер выбрал проект)
                selectProjectStatus = 'finded'
                resolve({status: selectProjectStatus, id: selectedProject.id, name: projectSearch})
              } else {  // юзер ничего не выбрал
                // пробуем найти проект с таким именем
                this.$http.get(httpStore.getters.host + httpStore.getters.value('getProjectsNames') + projectSearch).then((response) => {
                  if (response.body.length > 0 && response.body[0].name === projectSearch) {  // если нашлось хоть что-то и у лучшего совпадения то же имя (избежать выбора test1 при написании test)
                    selectProjectStatus = 'finded'
                    for (let i = 0; i < response.body.length; i++) {
                      if (response.body[i].name === projectSearch) {
                        resolve({status: selectProjectStatus, id: response.body[i].id, name: projectSearch})
                      }
                    }
                  } else {  // не нашлось такого проекта, значит надо создать
                    selectProjectStatus = 'needCreate'  // возвращаем с именем проекта, создадим проект с таким именем и на следующей интерации найдем его ид
                    resolve({status: selectProjectStatus, id: null, name: projectSearch})
                  }
                }).catch(err => {
                  console.log(err)
                  reject(err)
                })
              }
            }
            break
          }

          case 'finded': {  // найдено. Ид проекта найдено все закзы туда
            resolve({status: selectProjectStatus, id: selectedProject.id, name: projectSearch})
            break
          }

          case 'created': { // мы создали проект с таким именем
            this.$http.get(httpStore.getters.host + httpStore.getters.value('getProjectsNames') + projectSearch).then((response) => {
              if (response.body.length > 0) {
                selectProjectStatus = 'finded'
                resolve({status: selectProjectStatus, id: response.body[0].id, name: projectSearch})
              }
            })
            break
          }

          default: {
            resolve({status: selectProjectStatus, id: selectedProject.id, name: projectSearch})
          }
        }
      })
    },

    createOrderMix: async function (listCadNumForOrder, selectedProject, projectSearch) {
      apiDataStore.dispatch('setValue', {key: 'orderLoading', value: true})
      let data = {
        list_cad_num: [],
        project_id: null,
        project_name: null
      }
      let ORDERS_PER_TIME = 1
      console.log(listCadNumForOrder)
      // console.log(selectedProject)
      // console.log(projectSearch)
      let _prj = await this.selectProjectMix(selectedProject, projectSearch, 'maybeUserSelectProject') // пытаетмся найти ИД проекта
      // console.log(_prj)
      if (_prj.status === 'needCreate') { // если имя проекта не нашлось, то создаем проект с таким именем, делая один заказ
        console.log('need create')
        data['list_cad_num'] = listCadNumForOrder.splice(0, 1) // добавляем первый номер (spliсe возвращет удаленные элементы)
        data['project_name'] = _prj.name
        console.log(data)
        await this.createPartOfOrder(data)
        _prj = await this.selectProjectMix(selectedProject, projectSearch, 'created')  // находим ИД закза по уже созданному имени
        console.log(_prj)
      }
      for (let i = 0; i < listCadNumForOrder.length;) {  // заказываем все номера
        this.ordersLeftMix = listCadNumForOrder.length
        if (data['list_cad_num'].length > 0) { // удаляем то, что было
          data['list_cad_num'].splice(0, data.list_cad_num.length)
        }
        data['project_id'] = null
        data['project_name'] = null
        data['list_cad_num'] = listCadNumForOrder.splice(0, ORDERS_PER_TIME)  // добавляем следующие номера (spliсe возвращет удаленные элементы)
        if (_prj.id !== null) {
          data['project_id'] = _prj.id
          console.log(data)
          await this.createPartOfOrder(data)
        } else { // если вдруг ИД все же не нашлось
          data['project_name'] = _prj.name
          await this.createPartOfOrder(data)
        }
      }
      alertStore.dispatch('showAlert', {type: 'success', title: 'Отлично', text: 'Документы заказаны и поступили в обработку'})
      apiDataStore.dispatch('setValue', {key: 'orderLoading', value: false})
      this.$emit('closeOrder') // событие на закрытие заказ
      this.$emit('updateList')
    },

    createPartOfOrder (data) {
      return new Promise((resolve) => {
        // console.log(data)
        let _url = httpStore.getters.host + httpStore.getters.value('createOrder')  //  /api/v1/me/ordering/
        // console.log(data)
        this.$http.post(_url, JSON.stringify(data)).then(() => {
          resolve(true)
        }).catch(error => {
          apiDataStore.dispatch('setValue', {key: 'orderLoading', value: false})
          alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка заказа', text: 'Не удалось сделать заказ'})
          this.$emit('stopTime')
          console.log(error.status)
          resolve(false)
        })
      })
    },

    // end Ordering.vue
    // begin Feedback.vue

    sendFeedbackMix (messageData) {
      let _url = httpStore.getters.host + httpStore.getters.value('sendFeedback')
      this.$http.post(_url, messageData).then(() => {
        alertStore.dispatch('showAlert', {type: 'success', title: 'Сообщение отправлено!', text: 'Спасибо, что используете RRDoc. Мы надеемся, что вам нравится наш сервис.'})
      }).catch(error => {
        console.log(error.status)
        alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка при отправке сообщения.', text: 'Не удалось отправить сообщение. Попробуйте повторить позже'})
      })
    },

    // end Feedback.vue
    // begin ResetPassword.vue

    // отправка нового пароля на сервер
    resetPsswordMix (data) { //  /rest-auth/password/reset/confirm/
      let _urlReset = httpStore.getters.host + httpStore.getters.value('resetPasswordConfirm')
      this.$http.post(_urlReset, JSON.stringify(data)).then(response => {
        if (response.status === 200) {
          alertStore.dispatch('showAlert', {type: 'success', title: 'Успешно', text: response.body.detail})
          this.$router.push('/login')
        }
      }).catch(err => {
        console.log(err)
        if (err.body.token !== undefined) {
          alertStore.dispatch('showAlert', {title: 'Не удалось сменить пароль', text: 'Токен не действителен, попробуйте выслать себе еще одно письмо'})
          this.$router.push('/login')
        } else {
          if (err.body.new_password2 !== undefined) {
            alertStore.dispatch('showAlert', {title: 'Не удалось сменить пароль', text: err.body.new_password2[0]})
          }
        }
      })
    },

    // end Feedback
    // begin Uploaded

    getUploadedMix (contextQuery = '', exQueryString = '', limit = '10', offset = '0', statusId = '', linkOnPage) {
      let _url = ''
      uploadedStore.dispatch('setValue', {key: 'loadingUploaded', value: true})
      if (linkOnPage === false) {
        _url = httpStore.getters.host + httpStore.getters.value('getUploaded')
        _url += '?limit=' + limit
        _url += '&status_id=' + statusId
        _url += '&search=' + encodeURIComponent(contextQuery)
        _url += exQueryString
        // _url += '&only_actual=true'
        // _url += '&only_my=true'
        // _url += '&only_my=false'  // для дебага в демо режие
        _url += '&offset=' + offset
      } else {
        _url = linkOnPage
      }
      this.$http.get(_url).then(response => {
        // console.log(response.body)
        uploadedStore.dispatch('setValue', {key: 'rows', value: response.body.results})
        uploadedStore.dispatch('setValue', {key: 'loadingUploaded', value: false})
        uploadedStore.dispatch('setValue', {key: 'nextPage', value: response.body.next})
        uploadedStore.dispatch('setValue', {key: 'prevPage', value: response.body.previous})

        //  количество доков
        let count = response.data.count
        uploadedStore.dispatch('setValue', {key: 'count', value: count})

        // вычисление кол-ва страниц
        let pagesCount = Math.floor(count / parseInt(limit))
        if (count / limit !== 0) {
          pagesCount++
        }
        uploadedStore.dispatch('setValue', {key: 'pagesCount', value: pagesCount})
      }).catch(error => {
        uploadedStore.dispatch('setValue', {key: 'loadingUploaded', value: false})
        console.log(error.body)
      })
    },

    // end Uploaded
    // begin LandPlans

    getLandPlansMix (contextQuery = '', exQueryString = '', limit = '10', offset = '1000', linkOnPage) {
      let _url = ''
      landPlansStore.dispatch('setValue', {key: 'loadingUploaded', value: true})
      if (linkOnPage === false) {
        _url = httpStore.getters.host + httpStore.getters.value('getDKI')
        _url += '?limit=' + limit
        _url += '&offset=' + offset
        _url += '&search=' + encodeURIComponent(contextQuery)
        _url += exQueryString
        // _url += '&group=dci'
        // _url += '&only_actual=true'
        // _url += '&only_my=true'
        // _url += '&only_my=false'  // для дебага в демо режие
      } else {
        _url = linkOnPage
      }
      // console.log(_url)
      this.$http.get(_url).then(response => {
        // console.log(response.body)
        landPlansStore.dispatch('setValue', {key: 'rows', value: response.body.results})
        landPlansStore.dispatch('setValue', {key: 'loadingUploaded', value: false})
        landPlansStore.dispatch('setValue', {key: 'nextPage', value: response.body.next})
        landPlansStore.dispatch('setValue', {key: 'prevPage', value: response.body.previous})

        //  количество доков
        let count = response.data.count
        landPlansStore.dispatch('setValue', {key: 'count', value: count})

        // вычисление кол-ва страниц
        let pagesCount = Math.floor(count / parseInt(limit))
        if (count % limit !== 0) {
          pagesCount++
        }
        landPlansStore.dispatch('setValue', {key: 'pagesCount', value: pagesCount})
        this.getPrintStatusesMix(response.body.results)
      }).catch(error => {
        landPlansStore.dispatch('setValue', {key: 'loadingUploaded', value: false})
        console.log(error.body)
      })
    },

    getPrintStatusesMix (docs) {
      for (let i = 0; i < docs.length; i++) {
        let _urlProcessDetail = httpStore.getters.host + httpStore.getters.value('porcessDetail') + docs[i].id + '?operation_code=print'
        this.$http.get(_urlProcessDetail).then(response => {
          if (response.body.result === null) {
            apiDataStore.dispatch('addPrintStatus', {id: docs[i].id, cadNum: docs[i].cadNumber, date: docs[i].date_formation, status: 500, shortName: docs[i].type_name, url: null})
            // console.log('if 1')
            return 500
          } else if (response.body.result.status.code === 202) {
            apiDataStore.dispatch('addPrintStatus', {id: docs[i].id, cadNum: docs[i].cadNumber, date: docs[i].date_formation, status: 202, shortName: docs[i].type_name, url: null})
            // console.log('if 2')
            return 202
          } else if (response.body.result.status.code === 200 || response.body.result.status.code === '200') {
            apiDataStore.dispatch('addPrintStatus', {id: docs[i].id, cadNum: docs[i].cadNumber, date: docs[i].date_formation, status: 200, shortName: docs[i].type_name, url: response.body.result.file})
            // console.log('with url')
            return 200
          } else {
            return 500
          }
        })
      }
    },

    getDocUrlsMix (doc) {
      return new Promise(resolve => {
        let _url = httpStore.getters.host + httpStore.getters.value('downloadDoc') + doc.id //  /api/v1/me/documents/{id}
        this.$http.get(_url).then(response => {
          let obj = {}
          obj.source = response.body.source_file
          obj.private = response.body.private_file
          resolve(obj)
        }).catch(error => {
          // alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка загрузки документа.', text: 'Произошла ошибка при скачивании документа.', code: error.status})
          console.log(error.status)
          resolve(null)
        })
      })
    },

    getDocByIdMix (rows, id) {
      for (let i in rows) {
        if (rows[i].id === id) {
          return this.rows[i]
        }
      }
      return null
    },

    getUniversalActionsForTypesIdListMix: async function (typesIdList) {
      let _actions = []
      let _resultActions = []
      for await (const typeId of typesIdList) { // пройти по всем ид типов документов
        _actions = await this.getActionsByTypeIdMix(typeId)
        if (typesIdList[0] === typeId) {  // первый элемент
          _resultActions = _actions
        } else {
          _resultActions = this.IntersecArrays(_resultActions, _actions)
        }
      }
      // console.log(_resultActions)
      return _resultActions
    },

    getActionsByTypeIdMix (typeId) {
      return new Promise(resolve => {
        let _url = httpStore.getters.host + httpStore.getters.value('getActionsForCurrentDoc') + typeId
        this.$http.get(_url).then(response => {
          // console.log(response.body.results)
          resolve(response.body.results)
        }).catch(err => {
          console.log(err)
          resolve([])
        })
      })
    },

    IntersecArrays (A, B) {
      let b = new Set() // множество кодов операций
      B.forEach(element => {  // заполнение множества
        b.add(element.code)
      })
      let intersectionArr = []  // массив пересечния
      A.forEach(element => {
        if (b.has(element.code)) {  // если код есть в множестве, то значит пересечение
          intersectionArr.push(element)
        }
      })
      return intersectionArr
    }
  }
}
