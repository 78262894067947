import Vue from 'vue'
import 'babel-polyfill'
require('es6-promise/auto')
import App from './App'
import router from './router'
import VueResource from 'vue-resource'
// import VueGoodTable from 'vue-good-table'
import Vuetify from 'vuetify'
import VueCookie from 'vue-cookie'
import VueSession from 'vue-session'
import Vuex from 'vuex'
import vClickOutside from 'v-click-outside'

// TODO: get rid of flatpickr
import flatPickr from 'vue-flatpickr-component'
import flatpickr from 'flatpickr'
import { Russian } from 'flatpickr/dist/l10n/ru.js'

// import BootstrapVue from 'bootstrap-vue'
import VueJwtDecode from 'vue-jwt-decode'

// global mixins
import authMixin from './mixins/authMixin'
import datatableMixin from './mixins/datatableMixin'
import balanceMixin from './mixins/balanceMixin'
import apiGetDataMixin from './mixins/apiGetDataMixin'
import profileMixin from './mixins/profileMixin'
import changePageMixin from './mixins/changePageMixin'
import notificationMixin from './mixins/notificationMixin'
import settingsMixin from './mixins/settingsMixin'

// global styles
import 'vuetify/dist/vuetify.min.css'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/light.css'

import httpStore from './store/httpStore'
import store from './store/store'
import userStore from './store/userStore'
import alertStore from './store/AlertStore'
import settingsStore from './store/settingsStore'

global.jQuery = require('jquery')
var $ = global.jQuery
window.$ = $

Vue.use(VueResource)
// Vue.use(VueGoodTable)
Vue.use(Vuetify)
Vue.use(VueCookie)
Vue.use(VueSession)
Vue.use(flatPickr)
// Vue.use(BootstrapVue)
Vue.use(VueJwtDecode)
Vue.use(Vuex)
Vue.use(vClickOutside)

Vue.mixin(authMixin)
Vue.mixin(datatableMixin)
Vue.mixin(balanceMixin)
Vue.mixin(apiGetDataMixin)
Vue.mixin(profileMixin)
Vue.mixin(changePageMixin)
Vue.mixin(notificationMixin)
Vue.mixin(settingsMixin)

Vue.config.productionTip = false
Vue.http.options.emulateJSON = true
Vue.http.options.crossOrigin = true

Vue.http.headers.common['Accept'] = 'application/json, text/plain, application/zip, */*'

// проверяет доступность сервера
// async function testServer () {
//   return new Promise((resolve) => {
//     Vue.http({url: httpStore.getters.host + httpStore.getters.value('testServer'), method: 'get'}).then((response) => {
//       if (response.body.DatabaseBackend === 'working') {
//         store.dispatch('setValue', {key: 'serverAlive', value: true})
//         resolve(true)
//       } else {
//         store.dispatch('setValue', {key: 'serverAlive', value: false})
//         resolve(false)
//       }
//     }).catch((err) => {
//       store.dispatch('setValue', {key: 'serverAlive', value: false})
//       console.log('test has been failed catch', err)
//       resolve(false)
//     })
//   })
// }

async function logout () {
  Vue.http.post(httpStore.state.host + httpStore.state.auth + '/logout/').then(response => {
    console.log('Logout: ' + response.status)
    Vue.http.headers.common['Authorization'] = ''
  }).catch(error => {
    console.log(error.status)
  })
}

// удаляет данные и делает logout
async function clearUserData (callback) { // callback это next() из роутера
  await logout()
  Vue.cookie.delete('rrdtkn')
  Vue.cookie.delete('username')
  Vue.cookie.delete('userid')
  Vue.cookie.delete('email')
  Vue.cookie.delete('messages')
  Vue.cookie.delete('sessionid')
  // Vue.cookie.delete('company')
  userStore.dispatch('setValue', {key: 'company', value: ''})
  store.dispatch('setValue', {key: 'authorized', value: false})
  callback()
}

// проверка первого входа(второго если быть точнее XD)
function secondTime () { // чек первого входа
  let _secondTime = window.localStorage.getItem('didYouSeeOurLanding')
  if (_secondTime === undefined || _secondTime === null) {  // не видел лендинга, нет флага
    document.location.href = httpStore.getters.host.replace('api.', '') + '/welcome'  // редирект на приветсвенную страницу
  }
}

// проверяет имеющийся токен. Если он устраивает сервер, возвращает тру, иначе фолс
function checkToken () {
  return new Promise((resolve) => {
    if (Vue.cookie.get('rrdtkn') !== '' && Vue.cookie.get('rrdtkn') !== undefined && Vue.cookie.get('rrdtkn') !== null) { // есть ли вобще токен
      let _token = '{ "token": "' + Vue.cookie.get('rrdtkn') + '"}'
      Vue.http.post(httpStore.getters.host + httpStore.getters.value('verifyToken'), JSON.parse(_token)).then(response => {
        if ((response.status === 200) && (response.body.token === Vue.cookie.get('rrdtkn'))) {
          resolve(true)
        } else {
          resolve(false)
        }
      }).catch(error => {
        console.log(error)
        resolve(false)
      })
    } else {
      resolve(false)
    }
  })
}

async function setAuthorized () {
  let flag = await checkToken() // ждем ответа о валидности токена
  // console.log('flag ' + flag)
  // console.log(Vue.cookie.get('email'))
  // console.log(settingsStore.state.DEMO_IS_ACTIVE)
  if (flag) { // если токен ОК
    if (Vue.cookie.get('email') !== null && Vue.cookie.get('email') === 'demo@rrdoc.ru' && settingsStore.state.DEMO_IS_ACTIVE) {  // если при валидном токене он оказался демо и демо можно
      Vue.http.headers.common['Authorization'] = 'Bearer ' + Vue.cookie.get('rrdtkn')
      userStore.dispatch('setDemoMode', true)
      store.dispatch('setValue', {key: 'authorized', value: true})
    } else {
      if (Vue.cookie.get('email') !== null && Vue.cookie.get('email') !== 'demo@rrdoc.ru') {  // если токен ок и он не демо
        Vue.http.headers.common['Authorization'] = 'Bearer ' + Vue.cookie.get('rrdtkn')
        userStore.dispatch('setDemoMode', false)
        store.dispatch('setValue', {key: 'authorized', value: true})
      } else {
        router.push({name: 'SignsItem'})
      }
    }
  } else {
    if (settingsStore.state.DEMO_IS_ACTIVE) {
      await authDemo()
    } else {
      userStore.dispatch('setDemoMode', false)
      store.dispatch('setValue', {key: 'authorized', value: false})
      router.push({name: 'SignsItem'})
    }
  }
}

// выполняется на default в роутере
async function onDefault (callback) {
  let flag = await checkToken()
  if (!flag) {
    router.push({name: 'SignsItem'})
  } else {
    callback()
  }
}

// авторизация демо юзера
function authDemo () {
  return new Promise((resolve) => {
    store.dispatch('setValue', {key: 'loginLoading', value: true})
    Vue.cookie.delete('messages')
    Vue.cookie.delete('sessionid')
    let demoData = {}
    demoData['email'] = 'demo@rrdoc.ru'
    demoData['password'] = 'mLxkElOQ9mmw'
    Vue.http.post(httpStore.getters.host + httpStore.getters.value('login'), demoData).then(response => {
      Vue.cookie.set('rrdtkn', response.data.token)
      Vue.http.headers.common['Authorization'] = 'Bearer ' + response.data.token
      Vue.cookie.set('username', response.data.user.username)
      Vue.cookie.set('userid', response.data.user.pk)
      Vue.cookie.set('email', response.data.user.email)
      // Vue.cookie.set('company', response.data.user.company.name)
      Vue.cookie.delete('messages')
      Vue.cookie.delete('sessionid')
      store.dispatch('setValue', {key: 'authorized', value: true})
      userStore.dispatch('setValue', {key: 'company', value: response.data.user.company.name})
      userStore.dispatch('setDemoMode', true)
      store.dispatch('setValue', {key: 'loginLoading', value: false})
      resolve(true)
    }).catch((error) => {
      store.dispatch('setValue', {key: 'loginLoading', value: false})
      store.dispatch('setValue', {key: 'authorized', value: false})
      console.log(error.status)
      resolve(false)
    })
  })
}

// проверяет блокировку тарифа. Если заблокирован, выводит сообщение
function checkTarrifIsActive () {
  return new Promise(resolve => {
    if (!userStore.getters.value('demoMode') && userStore.state.authorized) {
      Vue.http.get(httpStore.getters.host + httpStore.getters.value('user')).then(response => {
        if (!response.body.tariff_is_active) {
          alertStore.dispatch('showAlert', {type: 'fullscreen', title: 'Тариф заблокирован', text: 'На вашем счету недостаточно средств для совершения ежедневной оплаты. Вам доступны только базовые действия. Для разблокировки пополните баланс.', showAbort: false})
        }
        resolve(response.body.tariff_is_active)
      })
    } else {
      resolve(false)
    }
  })
}

function getRegularPaymentPriceInfo () {
  return new Promise(resolve => {
    if (!userStore.getters.value('demoMode')) {
      Vue.http.get(httpStore.getters.host + httpStore.getters.value('getRegularPaymentPrice')).then(response => {
        resolve(response.data.result)
      }).catch(error => {
        resolve(null)
        console.log(error.status)
      })
    } else {
      resolve(null)
    }
  })
}

function getBalance () {
  return new Promise(resolve => { //  http://host:port/api/v1/billing/balance/
    if (!userStore.getters.value('demoMode')) {
      Vue.http.get(httpStore.state.host + httpStore.state.api + '/billing/balance/').then(response => {
        resolve(response.body.amount_balance)
      }).catch(error => {
        console.log(error.status)
        resolve(null)
      })
    } else {
      resolve(null)
    }
  })
}

function changeTitle (to) {
  document.title = to.meta.title
  store.state.currentTitle = to.meta.title
  store.state.currentHeadTitle = to.meta.headTitle
  store.state.flagRequestData = to.name // переменная для запроса дынных юзера. Меняется ПРИ КАЖДОМ ПЕРЕХОДЕ
  if (to.name !== 'SignsItem' && to.name !== 'reset-password1' && to.name !== 'reset-password2' && to.name !== 'confirm_email_success' && to.name !== 'accounts_profile' && to.name !== 'term_of_use') {
    // console.log('triggered')
    store.state.currentRouteName = to.name
  }
}

async function checkDaysLeft () {
  let daysToAlert = 2
  let regular = await getRegularPaymentPriceInfo()
  let balance = await getBalance()
  if (regular !== null && balance !== null) {
    let daysLeft = Math.floor(balance / regular)
    if (daysLeft <= daysToAlert) {
      let _text = 'Ваших средств хватит еще на ' + daysLeft
      switch (daysLeft) {
        case 0:
          _text += ' дней.'
          break
        case 1:
          _text += ' день. '
          break
        case 2:
          _text += ' дня.'
          break
        default:
          _text += ' дней.'
          break
      }
      _text += ' Не забудьте своевременно пополнить баланс'
      alertStore.dispatch('showAlert', {type: 'error', title: 'Приближение к нулю', text: _text})
    }
  }
}

// инициализирует приложение
async function initApplication () {
  // window.localStorage.setItem('didYouSeeOurLanding', true) // раскомментить если при разработке постоянно перекидывает с welcome на rrdoc.itt
  // await testServer()  // проверка доступности сервера
  httpStore.state.host = process.env.VUE_APP_HOST
  secondTime()  // проверка первого входа
  await setAuthorized() // проверка токена, если есть(валидный) авторизовать, если нет, авторизовать как демо
  let tariffIsActive = await checkTarrifIsActive()  // проверка активности тарифа. Если заблокирован - оповещение
  if (tariffIsActive) { // если тариф заблокирован, то смотреть оставшиеся дни не нужно
    await checkDaysLeft() // сколько дней осталось. Если мало, то оповещение
  }
  /* eslint-disable no-new */
  new Vue({
    router,
    settingsStore,
    render: h => h(App),
  }).$mount('#app')
}

router.beforeEach((to, from, next) => {
  changeTitle(to)
  switch (to.name) {
    case 'reset-password1': {  // есть два пути, и это просто странное поведение бекэнда
      clearUserData(next)
      // next()
      break
    }

    case 'reset-password2': {  // есть два пути, и это просто странное поведение бекэнда
      clearUserData(next)
      // next()
      break
    }

    case 'SignsItem': {
      clearUserData(next)
      // next()
      break
    }

    case 'confirm_email_success': {
      clearUserData(next)
      // next()
      break
    }

    case 'accounts_profile': {  // редирект на логин после пополнения счета
      clearUserData(next({name: 'confirm_email_success'}))
      // next({name: 'confirm_email_success'})
      break
    }

    case 'page404': {
      next({name: 'documentList'})
      break
    }

    case 'term_of_use': {
      next()
      break
    }

    case 'PersonalCabinet': {
      if (!userStore.getters.value('demoMode')) {
        next()
      } else {
        next({name: 'documentList'})
      }
      break
    }

    default: {
      if (from.name === 'SignsItem') {
        // если переход с логина, то пустить. Можно перейти двумя способами:
        // 1) ввести в адресную строку путь. Тогда приложение инициализируется заного и логинится
        // 2) с миксина logIn который переводит тебя туда программно
        // нужно для того, чтобы элемент signsItem не "мигал" при логине
        next()
      } else {  // если переход стандартный то смотрим токен
        onDefault(next) // отдельная функция чтобы сделать её async и точно дождаться ответа сервера о годности токена
      }
      break
    }
  }
})

flatpickr.localize(Russian)
flatpickr('flat-pickr')
flatpickr.setDefaults({
  dateFormat: 'd.m.Y',
  altFormat: 'j F, Y',
  altInput: true
})

initApplication()
