import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    tariffsInfo: null,
    companyEmployeesInfo: [],
    companyEmployeesInfoIndex: 0,
    regularPaymentPriceInfo: null
  },
  mutations: {
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    }
  },
  actions: {
    setValue ({commit}, keyValue) {
      commit('setValue', keyValue)
    }
  },
  getters: {
    value: state => key => {
      return state[key]
    }
  }
})

export default store
