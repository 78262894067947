<template>
  <div class="main-personal-cab">
    <div class="profile-loading" v-if="profileAllDataLoading"><img src="../../assets/img/rrd_logo_test.svg" alt="загрузка личного кабинета"></div>
    <div class="profile-page" v-else>

      <div class="profile-menu">
        <div class="profile-menu-wrapper">
          <div class="profile-menu-elem">
            <p @click="scrollToBlock('user')">Пользователь</p>
          </div>
          <div class="profile-menu-elem">
            <p @click="scrollToBlock('billing')">Биллинг</p>
          </div>
          <div v-if="company !== null && company !== '' && company !== undefined" class="profile-menu-elem">
            <p @click="scrollToBlock('company')">Компания</p>
          </div>
          <div v-if="isBoss" class="profile-menu-elem">
            <p @click="scrollToBlock('tariff')">Тарифы</p>
          </div>
          <div class="profile-menu-elem">
            <p @click="beforeExit()">Выйти</p>
          </div>
        </div>
      </div>
      <div class="profile-content">
        <div class="profile-content-wrapper">
          <div class="profile-content-elem profile-content-user">
            <p class="profile-content-item-title">Пользователь</p>
            <div class="profile-content-item-content" id="user">
              <div class="profile-content-item-elem">
                <p>E-mail: <span>{{email}}</span></p>
                <button @click="showChangePasswordPopup()" class="button button-primary">сменить пароль</button>
              </div>
              <div v-if="isBoss" class="profile-content-item-elem">
                <p>Тариф: <span>{{userTariffHumanName}}</span><span v-if="!userTariffIsActive">&nbsp;(Заблокирован)</span></p>
                <button v-if="isBoss" class="button button-primary" @click="scrollToBlock('tariff')">сменить тариф</button>
                <button v-if="isBoss && !userTariffIsActive" class="button button-primary" @click="unblockTariff()">Разблокировать</button>
              </div>
              <div class="profile-content-item-elem">
                <p v-if="currentPermissions.length !== 0">Доступные действия:</p>
                <div class="current-permissions">
                  <p v-for="(currentPermission, index) in currentPermissions" :key="index">
                    <span><div class="point"></div></span><span class="permis-with-help-dialog" @mouseenter="showInfo('havePrm_' + currentPermission.name, currentPermission.description)" :id="'havePrm_' + currentPermission.name" @mouseleave="hideInfo()">{{currentPermission.human_name}}</span>
                  </p>
                </div>
              <div class="profile-content-item-elem">
                <p>Идентификатор: <span class="user-guid" @click="copyGuid()">{{guid}}</span></p><img @click="copyGuid()" class="copy-img" src="../../assets/img/copy.svg" alt="копировать guid">
              </div>
              </div>
            </div>
            <div class="divide"></div>
          </div>
          <div class="profile-content-elem profile-content-billing">
            <p class="profile-content-item-title">Биллинг</p>
            <div class="profile-content-item-content" id="billing">
              <div>
                <p>Баланс: <span>{{userBalance | money}} руб.</span></p>
              </div>
              <p v-if="isBoss">Ежедневное списание по тарифу: <span>{{this.regularPaymentPriceInfo | money}} руб.</span></p>
              <button class="button button-trust button-fill-balance" @click="showFillUpBalance()" v-if="permissionFillBalance">Пополнить</button><br>
              <div class="profile-content-item-elem">
              <p v-if="bonusesInfo.length !== 0">Бонусы:</p>
              <div class="bonuses">
                <div class="bonuses-item" v-for="(bonus, index) in bonusesInfo" :key="index">
                  <p>
                    <span><div class="point"></div></span>{{bonus.permission.human_name}}: <span>{{bonus.total}}</span>
                  </p>
                </div>
              </div>
              </div>
            </div>
            <div class="divide"></div>
          </div>
          <div v-if="company !== null && company !== '' && company !== undefined" class="profile-content-elem profile-content-company">
            <p class="profile-content-item-title">Компания</p>
            <div class="profile-content-item-content" id="company">
              <p>Компания: 
                <span v-if="companyNameEdit == false">{{ company }}</span>
                <span v-if="companyNameEdit == false && !loadingUpdateCompany"><img v-if="isBoss" @click="changeCompanyName()" src="../../assets/img/edit.svg"/></span>
                <span v-if="companyNameEdit">
                  <input id="companyNameInput" class="company-input" type="text" maxlength="100" :placeholder="company" v-model="companyName" @blur="companyInfoChange()" @keyup.enter="blurField('companyNameInput')"/>
                </span>
                <span v-if="loadingUpdateCompany"><img src="../../assets/img/rrd_logo_test.svg" class="loading-update-company" alt="загрузка изменения названия компании"></span>
              </p>
              <button @click="beforeGetExitFromCompany()" v-if="!isBoss" class="button button-primary leave-company-button">Покинуть компанию</button>
              <p v-if="isBoss && userTariffCompanyAllow"><span>Вы руководитель компании</span></p>
              <p v-if="isBoss && userTariffCompanyAllow">Добавить сотрудника: <span>
                <input class="guid-input" type="text" placeholder="идентификатор пользователя" v-model="newUserGuid" @keyup.enter="addToCompany()"/>
                </span></p>
              <p v-if="isBoss && userTariffCompanyAllow">Сотрудники ({{companyEmployeesInfo == undefined ? 0 : companyEmployeesInfo.length}}):</p>
              <div class="table-utils">
                <div class="table-utils-search-input" v-if="isBoss && userTariffCompanyAllow"><input class="input" v-model="tableSearchInput" placeholder="поиск в таблице" type="text"></div>
                <div class="table-utils-button-delete" v-if="isBoss && userTariffCompanyAllow"><button class="button button-accent" :class="{'button-disabled': selectedEmployees.length < 1}" :disabled="selectedEmployees.length < 1" @click="deleteSelected()">Удалить выбранных: {{ selectedEmployees.length }}</button></div>
              </div>
              <div v-if="isBoss && userTariffCompanyAllow" class="employees-list-wrap">
                
                <div class="employees-list-table-wrapper">
                  <div class="profile-loading-emploee-table" v-if="profileLoadingEmploeeTable || loadingUserCompanyAction"><img src="../../assets/img/rrd_logo_test.svg" alt="загрузка таблицы прав"></div>
                  <table class="employees-list-table table table-striped scroll" id="employees-table-body" v-else>
                    <thead>
                      <tr>
                        <td class="td-select">
                          <div class="checkbox-wrapper">
                              <div class="table-checkbox" @click="selectAllEmployees()">
                                <div class="fill-checkbox" v-show="allSelectedFlag"></div>
                              </div>
                          </div>
                        </td>
                        <td class="username-td">e-mail</td>
                        <td class="td-permis" v-for="(currentPermission, index) in currentPermissions" :key="index">
                          {{currentPermission.human_name}}
                        </td>
                        <td class="delete-td"></td>
                      </tr>
                    </thead>
                    <tbody class="employees-list-table-body">
                      <tr v-for="(employee, index) in companyEmployeesInfo" :key="index" :id="'employ-number-' + index" class="class-name-for-scroll" v-show="search(tableSearchInput, employee)">
                        <td class="td-select">
                          <div class="checkbox-wrapper">
                            <div v-if="!employee.is_boss" class="table-checkbox" @click="selectEmployees(employee)">
                              <div class="fill-checkbox" v-show="selectedEmployees.includes(employee.username)"></div>
                            </div>
                            <div v-else class="table-checkbox" style="cursor: default">
                              <div class="fill-checkbox-boss"></div>
                            </div>
                          </div>
                        </td>
                        <td class="username-td"><span>{{employee.username}}</span></td>
                        <td class="td-permis" v-for="(currentPermission, curPermIndex) in currentPermissions" :key="curPermIndex">
                          <div class="checkbox-wrapper">
                              <div v-if="!employee.is_boss" class="table-checkbox" @click="updateEmployeePermissions(employee, currentPermission.code, index)">
                                <div v-for="(perm, permIndex) in employee.permissions" :key="permIndex" class="fill-checkbox" v-show="perm.code == currentPermission.code"></div>
                              </div>
                              <div v-if="employee.is_boss" class="table-checkbox" style="cursor: default;">
                                <div v-for="(perm, permIndex) in employee.permissions" :key="permIndex" class="fill-checkbox-boss" v-show="perm.code == currentPermission.code"></div>
                              </div>
                          </div>
                        </td>
                        <td class="delete-td">
                          <p v-if="!employee.is_boss" class="red-text" @click="deleteFromCompany(employee)">удалить</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                </div>
            </div>
            <div class="divide"></div>
          </div>
          
          <div v-if="isBoss" class="profile-content-elem profile-content-tarrifs">
            <p class="profile-content-item-title" id="tariff">Тарифы</p>
            <div class="profile-content-item-content profile-tarif">
              <div class="profile-content-tarrifs-item" v-for="tariff in tariffsInfo" :key="tariff.id">
                <div class="tariffs-item-wrap">
                  <p><span class="tariff-name-span">{{tariff.human_name | tariffFilter}}</span></p>
                  <p class="tariff-permission-wrapp">
                    <span v-for="permis in tariff.permissions" :key="permis.code" v-show="permis.code !== 200">
                      <span><div class="point"></div></span><span class="permis-with-help-dialog" @mouseenter="showInfo('tarifName_' + tariff.name + 'tarifPrm_' + permis.name, permis.description)" :id="'tarifName_' + tariff.name + 'tarifPrm_' + permis.name" @mouseleave="hideInfo()">{{permis.human_name}}</span><br>
                    </span>
                    <span v-if="tariff.company_allow">
                      <div class="point"></div>
                      <span class="permis-with-help-dialog" @mouseenter="showInfo('createCompanyPermission', createCompanyDescription)" @mouseleave="hideInfo()" id="createCompanyPermission">Создание компании</span>
                    </span><br>
                  </p>
                  <div class="container-price-tarif">
                    <span class="permissions-in-tarif">{{ Math.round(tariff.price * 30) }} &#8381; / мес</span>
                    <span class="permissions-in-tarif-small">({{ tariff.price }} &#8381; / день)</span>
                    <p v-if="!tariff.company_allow" class="permissions-in-tarif">&nbsp;</p>
                    <p v-if="tariff.company_allow" class="permissions-in-tarif">за сотрудника</p>
                  </div>
                  <div class="container-button-accept-tarif">
                    <span>
                      <button @click="beforeUpdateTariff(tariff)" class="button button-trust" :disabled="tariff.disable" :class="{'button-disabled': tariff.disable}">
                        <img src="../../assets/img/spinner-white.svg" alt="загрузка смены тарифа" class="loading-update-tariff" v-if="(newTariffName === tariff.name) && loadingUpdateTariffMix">
                        Перейти
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="divide"></div>
          </div>
        </div>
      </div>

      <div class="password-change-popup" v-if="changePasswordFlag">
        <h4>Изменение пароля</h4>
        <div class="password-change-content">
          <div>
            <input type="password" class="input password-change-input" @keyup="checkInput()" v-model="newPassword" minlength="8" placeholder="Новый пароль">
          </div>
          <div>
            <input type="password" class="input password-change-input" @keyup="checkInput()" v-model="newPasswordRepeat" minlength="8" placeholder="Повторите новый пароль">
            <img src="../../assets/img/select.svg" alt="пароли совпадают" class="password-requirements-succeess-second" v-if="requirements[1].ok === true">        
          </div>
        </div>
        <div class="password-requirements">
          <div class="password-requirements-item">пароль должен содержать не менее 8 знаков</div>
          <div class="password-requirements-item">пароль не должен состоять только из цифр</div>
        </div>
        <div class="change-password-buttons">
          <button class="button"  v-bind:class="{'button-disabled': !passwordsOK,  'button-primary': passwordsOK}" :disabled="!passwordsOK" @click="changePassword()">Изменить</button>
          <button class="button button-accent" @click="changePasswordFlag = false">Закрыть</button>
        </div>
      </div>

      <div class="password-change-overlay" v-if="changePasswordFlag" @click="changePasswordFlag = false"></div>
  </div>
  </div>
</template>

<script>
// storage facilities
import httpStore from '../../store/httpStore'
import store from '../../store/store'
import alertStore from '../../store/AlertStore'
import userStore from '../../store/userStore'
import profileStore from '../../store/profileStore'
import balanceStore from '../../store/BalanceStore'
import apiDataStore from '../../store/apiDataStore'

export default {
  data () {
    return {
      tariffs: 'base',
      changePasswordFlag: false,
      companyNameEdit: false,
      companyName: '',
      newUserGuid: '',
      newPassword: '',  // смена пароля
      newPasswordRepeat: '', // смена пароля
      requirements: [ // смена пароля
        {id: 'length', ok: false, text: 'Длина пароля должна быть больше 8 символов'},
        {id: 'equals', ok: false, text: 'Пароли не совпадают'}
      ],
      createCompanyDescription: 'Работайте в едином информационном пространстве. Единая база документов (выписки ЕГРН, межевые и технические планы) для сотрудников организации, едниая система управления финансами',
      helpDialogTimer: null,
      tableSearchInput: '', // поиск по таблице
      selectedEmployees: [],
      allSelectedFlag: false,
      loadingUpdateCompany: false,
      newTariffName: ''
    }
  },
  mounted: async function () {
    await this.getAllProfileData().then(() => {
      store.state.activeIcon = {type: 'profile', id: 8} // смена иконки в меню слева
      // if (this.$route.query !== undefined && this.$route.query !== null && this.$route.query.scrollTo !== undefined && this.$route.query.scrollTo !== null && this.$route.query.scrollTo !== '') { // скролл до тарифа если нжали на кнопку тариф
      //   this.scrollToBlock(this.$route.query.scrollTo)
      // }
      if (localStorage.getItem('wantToSeeTariffs') !== null) {
        localStorage.removeItem('wantToSeeTariffs')
        this.scrollToBlock('tariff')
      }
    })
  },
  computed: {
    loadingUpdateTariffMix () {
      return apiDataStore.getters.value('loadingUpdateTariffMix')
    },
    email () {
      return userStore.getters.value('email')
    },
    username () {
      return userStore.getters.value('username')
    },
    guid () {
      return userStore.getters.value('guid')
    },
    userTariff () {
      return userStore.getters.value('tariffName')
    },
    userTariffHumanName () {
      return userStore.getters.value('tariffHumanName')
    },
    userTariffCompanyAllow () {
      return userStore.getters.value('tariffCompanyAllow')
    },
    userTariffIsActive () {
      return userStore.getters.value('tariffIsActive')
    },
    isBoss () {
      return userStore.getters.value('isBoss')
    },
    userBalance () {
      let balance = balanceStore.state.amountBalance
      if (typeof (balance) === 'number') {
        return balance.toFixed(2)
      } else {
        return balance
      }
    },
    company () {
      /* eslint-disable-next-line */
      this.companyName = userStore.getters.value('company')
      return userStore.getters.value('company')
    },
    currentPermissions () {
      return userStore.getters.value('currentPermissions')
    },
    permissionFillBalance () {
      return userStore.getters.permission('fill')
    },
    tariffsInfo () {
      return profileStore.getters.value('tariffsInfo')
    },
    companyEmployeesInfo () {
      return profileStore.getters.value('companyEmployeesInfo')
    },
    companyEmployeesInfoIndex () {
      return profileStore.getters.value('companyEmployeesInfoIndex')
    },
    regularPaymentPriceInfo () {
      let regularPayment = profileStore.getters.value('regularPaymentPriceInfo')
      if (typeof (regularPayment) === 'number') {
        return regularPayment.toFixed(2)
      } else {
        return regularPayment
      }
    },
    bonusesInfo () {
      return balanceStore.getters.value('bonusesInfo')
    }
  },
  filters: {
    allowCompany: function (val) {
      return (val === true) ? 'Разрешено' : 'Запрещено'
    },
    limit: function (val) {
      return (val === -1) ? '\u221E' : val
    },
    tariffFilter: function (val) { // переименование Про в Стандартный
      return (val === 'Про') ? 'Стандартный' : val
    },
    money (val) {
      let strVal = String(val)
      let afterDot = strVal.substring(strVal.indexOf('.') + 1)  // подстрока после точки
      if (afterDot === '00') {
        return strVal.substring(0, strVal.indexOf('.'))
      } else {
        return strVal
      }
    }
  },
  methods: {
    showFillUpBalance () {
      balanceStore.dispatch('setValue', {key: 'showPopupBalanceAddFlag', value: true})
    },
    blurField (fieldName) {
      document.getElementById(fieldName).blur()
    },
    selectAllEmployees () {
      if (!this.allSelectedFlag) {  // был false станет true(выбрали всех)
        for (let i = 0; i < this.companyEmployeesInfo.length; i++) {
          if (!this.companyEmployeesInfo[i].is_boss && this.search(this.tableSearchInput, this.companyEmployeesInfo[i])) { //  если не босс и в поиске
            let index = this.selectedEmployees.indexOf(this.companyEmployeesInfo[i].username)
            if (index === -1) {
              this.selectedEmployees.push(this.companyEmployeesInfo[i].username)
            }
          }
        }
      } else {
        this.selectedEmployees.splice(0, this.selectedEmployees.length)
      }
      this.allSelectedFlag = !this.allSelectedFlag
    },
    deleteSelected: async function () {
      let flag = await this.deleteSelectedFromCompanyMix(this.selectedEmployees)
      // console.log(flag)
      if (flag) {
        alertStore.dispatch('showAlert', {type: 'success', title: 'Успешно', text: 'Выбранные сотрудники успешно удалены. Ежедневное списание изменено.'})
      } else {
        alertStore.dispatch('showAlert', {type: 'error', title: 'Ошибка при удалении', text: 'Возможно, некоторые из пользователей не были удалены. Обновите страницу, и повторите попытку.'})
      }
      this.selectedEmployees.splice(0, this.selectedEmployees.length)
    },
    selectEmployees (employee) {
      let index = this.selectedEmployees.indexOf(employee.username)
      if (index === -1) {
        this.selectedEmployees.push(employee.username)
      } else {
        this.selectedEmployees.splice(index, 1)
      }
    },
    search (inp, employee) {  // возвращает флаг, если username совпадает с введенным(поик подстроки), то тру
      if (employee.username.indexOf(this.tableSearchInput) !== -1) {
        return true
      }
      return false
    },
    hideInfo () { // удаляет подсказку после того, как уводишь курсор
      clearTimeout(this.helpDialogTimer)
      let generatedBlock = document.getElementById('generatedHelpBlock')
      if (generatedBlock !== undefined && generatedBlock !== null) {
        document.body.removeChild(generatedBlock)
      }
    },
    showInfo (elemId, discription) {
      if (discription !== null && discription !== undefined && discription !== '') {
        this.helpDialogTimer = setTimeout(() => {
          let permElem = document.getElementById(elemId)  // сам элемент с названием права
          let permElemX = permElem.getBoundingClientRect().x  // его координаты
          let permElemY = permElem.getBoundingClientRect().y
          let generatedBlock = document.createElement('div')  // новый элемент(сама подсказка)
          generatedBlock.style.cssText = 'max-width: 250px; font-size: 11pt; background-color: white; text-align: center; color: black; border-radius: 2px; padding: 5px 10px; box-shadow: 1px 1px 7px 1px rgba(51, 51, 51, 0.2);' // стили "с нуля"
          generatedBlock.style.position = 'fixed' // стили что перезаписывают имеющиеся
          generatedBlock.style.top = permElemY - 10 + 'px'
          generatedBlock.style.left = permElemX - 270 + 'px'
          generatedBlock.id = 'generatedHelpBlock'
          generatedBlock.innerHTML = discription
          document.body.appendChild(generatedBlock) // добавление в структуру
        }, 200)
      }
    },
    copyGuid () {
      let guid = this.guid
      navigator.clipboard.writeText(guid)
      .then(() => {
        alertStore.dispatch('showAlert', {type: 'success', title: 'Успешно', text: 'Ваш идентификатор скопирован.'})
      })
      .catch(err => {
        console.log('Something went wrong', err)
      })
    },
    beforeUpdateTariff (tarif) {
      this.newTariffName = tarif.name
      let textForAlert = 'Вы уверены, что хотите поменять тариф на '  // переименование Про в Стандартный
      textForAlert += tarif.human_name === 'Про' ? 'Стандартный' : tarif.human_name
      textForAlert += '?'
      alertStore.dispatch('showAlert', {type: 'fullscreen', title: textForAlert, text: 'testestes', emitName: 'updateTarif', emitParams: [tarif.name], buttonAccept: 'Да', buttonAbort: 'Нет', showText: false})
      // this.$forceUpdate()
    },
    beforeGetExitFromCompany () {
      alertStore.dispatch('showAlert', {type: 'fullscreen', title: 'Вы уверены, что хотите покинуть компанию?', text: 'Вы уверены, что хотите покинуть компанию?', emitName: 'getExitFromCompany', buttonAccept: 'Да', buttonAbort: 'Нет', showText: false})
    },
    beforeExit () {
      store.dispatch('setValue', {key: 'signIn', value: true})
      alertStore.dispatch('showAlert', {type: 'fullscreen', title: 'Вы уверены, что хотите выйти?', text: 'Вы уверены, что хотите выйти?', emitName: 'logOut', buttonAccept: 'Да', buttonAbort: 'Нет', showText: false})
    },
    exit: function (val) {
      if (val) {
        this.logout()
        this.menuVisible = false
      }
    },
    addToCompany () {
      if (this.newUserGuid !== '') {
        this.addUserToCompany(this.newUserGuid, true)
        this.newUserGuid = ''
      }
    },
    deleteFromCompany (employee) {
      let index = this.companyEmployeesInfo.indexOf(employee)
      this.deleteUserFromCompany(employee.username, index)
      if (index > 0) {
        this.scrollToBlock('employ-number-' + (this.companyEmployeesInfo.indexOf(employee) - 1))
      }
    },
    companyInfoChange () {
      this.loadingUpdateCompany = true
      this.companyNameEdit = false
      this.updateCompany(this.companyName).then(() => {
        this.getUserInfo()
        this.loadingUpdateCompany = false
      })
    },
    changeCompanyName () {
      this.companyNameEdit = true
    },
    updateEmployeePermissions: async function (employee, permissionCode, index) {
      let existPermissions = employee.permissions
      let newCodes = []
      let checkAddPerm = true
      for (let existPerm of existPermissions) {
        if (existPerm.code !== permissionCode) {
          newCodes.push(existPerm.code)
        } else {
          checkAddPerm = false
        }
      }
      if (checkAddPerm) {
        newCodes.push(permissionCode)
      }
      let newCodesString = newCodes.join()
      let _id = 'employ-number-' + index
      this.setEmployeePermissions(employee.username, newCodesString, this.companyEmployeesInfo.indexOf(employee)).then(() => {
        let elem = document.getElementById(_id)
        console.log(elem)
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      })
      // let rows = document.getElementsByClassName('class-name-for-scroll')[0]
      // setTimeout(() => {
      //   let elem = document.getElementById(_id)
      //   console.log(elem)
      //   elem.scrollIntoView(true)
      // }, 5000)
    },
    showChangePasswordPopup () {
      this.changePasswordFlag = true
    },
    changePassword () {
      let _urlPassChange = httpStore.state.host + httpStore.state.changePassword  //  /rest-auth/password/change/
      let dataPassChange = {
        email: this.email,
        new_password1: this.newPassword,
        new_password2: this.newPasswordRepeat
      }
      this.$http.post(_urlPassChange, JSON.stringify(dataPassChange)).then(response => {
        if (response.body.detail !== undefined) {
          alertStore.dispatch('showAlert', {type: 'success', title: 'Успешно', text: response.body.detail})
        } else {
          if (response.body.new_password2 !== undefined) {
            alertStore.dispatch('showAlert', {type: 'error', title: 'Не удалось сменить пароль', text: response.body.new_password2[0]})
          } else {
            alertStore.dispatch('showAlert', {type: 'error', title: 'Не удалось сменить пароль', text: 'Не удалось сменить пароль. Возможно, пароли не совпадают'})
          }
        }
      }).catch(error => {
        alertStore.dispatch('showAlert', {type: 'error', title: 'Не удалось сменить пароль', text: error.body.new_password2[0]})
      })
      this.changePasswordFlag = false
    },
    checkInput () {
      if (this.newPassword.length < 8) {
        this.requirements[0].ok = false
      } else {
        this.requirements[0].ok = true
      }
      if ((this.newPassword !== this.newPasswordRepeat) || (this.newPassword === '')) {
        this.requirements[1].ok = false
      } else {
        this.requirements[1].ok = true
      }
      this.passwordsOK = true
      for (let i = 0; i < this.requirements.length; i++) {
        if (this.requirements[i].ok === false) {
          this.passwordsOK = false
        }
      }
    },
    scrollToBlock (idElem) {
      // console.log(idElem)
      if (idElem !== 'tariff') {
        document.getElementById(idElem).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      } else {
        document.getElementById(idElem).scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/vue2-animate.scss";
@import "../../assets/sass/varibles.scss";

.main-personal-cab {
  padding: 0 30px 30px 30px;
}
.profile-menu {
  width: 250px;
  height: 100%;
  padding-top: 7%;
  padding-left: 50px;
  position: fixed;
}
.profile-menu-wrapper {
  width: 100%;
  height: auto;
  border-right: 1px solid rgba($color: $color-first, $alpha: .5);
}
.profile-menu-elem p {
  font-size: 14pt;
  color: $color-first;
}
.profile-menu-elem:hover {
  cursor: pointer;
}
.profile-menu-elem:hover p{
  font-weight: 600;
}
.profile-content {
  width: calc(100% - 300px);
  max-width: 1100px;
  margin-left: 300px;
  float: left;
}
.profile-content-wrapper {
  width: 100%;
  height: calc(100vh - 7vh - 20px);
  padding-top: 7vh;
  padding-left: 50px;
  padding-right: 20px;
}

.profile-content-wrapper::-webkit-scrollbar {
    width: 10px;
    height: 80%;
}

.profile-content-wrapper::-webkit-scrollbar-track {
    border: 0px;
    margin-top:50px;
}

.profile-content-wrapper::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.1);
}

.profile-content-item-title {
  font-size: 16pt;
}
.profile-content-elem {
  width: 100%;
}
.employees-list-wrap {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: left;
  margin-left: 20px;
  // display: block;
}
.employees-list-table-wrapper {
  // max-width: inherit;
  width: 100%;
  max-height: 500px;
  overflow-x: auto;
  text-align: left;
}
.employees-list-table-body tr:hover{
  background-color: #e8e8e8;
  cursor: default;
}
.employees-list-table-body p {
  padding: 0;
  margin: 0;
  padding-left: 5px;
}
///////////////////////////////////

.scroll {
  border: 0;
  border-collapse: collapse;
  width: 100%;
}

.scroll tr {
  display: flex;
  background-color: white!important;
}

.scroll td {
  padding: 3px;
  flex: 1 auto;
  // border: 1px solid #aaa;
  width: 100px;
  word-wrap: break;
}

// .scroll thead tr:after {
//   content: '';
//   overflow-y: scroll;
//   visibility: hidden;
//   height: 0;
// }

.scroll thead td {
  width: 100px;
}

.scroll tbody {
  display: block;
  // width: 1900px;
  // width: 90%;
  overflow: auto;
  // height: 400px;
}
.scroll tbody td {
  width: 100px;
}
.scroll thead td {
  width: 100px;
}
.td-select {  // первая колонка
  width: 40px!important;
  margin: 0!important;
  padding: 0!important;
}
.td-permis {
  padding: 0 10px!important;
  text-align: center;
}
////////////////////////////////////
.profile-content-item-content {
  padding: 20px 10px 20px 0;
  font-weight: 300;
}
.profile-content-item-content span {
  font-weight: 400;
}
.profile-tarif {
  display: flex;
}
.profile-content-tarrifs-item {
  min-width: 230px;
  width: calc((100% - 100px)/3);
  margin-right: 20px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  // display: flex;
  // flex-direction: row;
}
.profile-content-tarrifs-item:hover {
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
  cursor: default;
}
.tariffs-item-wrap {
  width: 100%;
  height: 100%;
}
.permissions-in-tarif {
  padding-left: 20px;
}
.permissions-in-tarif-small {
  font-size: 10pt;
  color: gray;
}
.container-price-tarif {
  text-align: center;
  margin-right: 15px;
  padding: 0;
  margin-bottom: 20px;
}
.container-button-accept-tarif {
  display: flex;
  justify-content: center;
}
.float-left {
  float: left;
}
.profile-content-item-elem {
  width: 100%;
  float: left;
}
.user-guid {
  cursor: pointer;
}
.profile-content-item-elem p, button {
  float: left;
}
.profile-content-item-elem p {
  margin-top: 12px;
  margin-right: 20px;
}
.divide {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  float: left;
  margin-top: 40px;
  margin-bottom: 40px;
}
.current-permissions {
  float: left;
  width: 100%;
  margin-left: 40px;
}
.current-permissions p {
  font-weight: 400;
  cursor: default;
}
.bonuses {
  float: left;
  width: 100%;
  margin-left: 40px;
}
.bonuses-item{
  width: 100%;
  float: left;
}
.point {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid $color-first;
  float: left;
  margin-right: 20px;
  margin-top: 7px;
}
.current-permissions p {
  width: 100%;
  float: left;
}
.table-checkbox {
  // display: block;
  // float: left;
  // margin-left: 30px;
  width: 15px;
  height: 15px;
  // margin-top: 0px;
  border: 1px solid rgba($color: $color-second, $alpha: 1);
  border-radius: 2px;
  cursor: pointer;
}
.fill-checkbox{
  width: 100%;
  height: 100%;
  background-color: $color-trust;
}
.fill-checkbox-boss {
  width: 100%;
  height: 100%;
  background-color: $color-second;
}
.checkbox-wrapper {
  margin-top: 15px;
  margin-left: calc(50% - 7px);
  // width: 20px;
}
#company img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-left: 20px;
  margin-top: -4px;
  cursor: pointer;
}
#company input {
  cursor: text;
  border-bottom: 1px solid gray;
  text-align: center;
  // width: auto;
  // padding: 0 5px;
}
.company-input {
  width: auto;
  min-width: 250px;
  padding: 0 10px;
}
.red-text {
  color: rgba($color-accent, $alpha: 0.6);
  cursor: pointer;
}
.red-text:hover {
  color: $color-accent
}
.guid-input {
  width: 300px;
}
.username-td {
  text-align: left;
  width: 250px!important;
  word-break: break-all;  // need test
  padding-top: 12px!important;
  height: auto!important;
}
.delete-td {
  text-align: left;
  padding-right: 20px;
  min-width: 100px;
}
.delete-td p {
  margin-top: 11px;
}
.button-disabled {
  background-color: rgba($color: #000000, $alpha: .5);
  cursor: default;
  padding: 7px 10px;
  font-size: 12pt;
}
.button-disabled:hover {
  background-color: rgba($color: #000000, $alpha: .5);
  cursor: default;
  padding: 7px 10px;
  font-size: 12pt;
}
.tariff-permission-wrapp {
  height: 200px;
}
.tariff-name-span {
  font-size: 16pt;
}
.leave-company-button {
  margin-left: 0;
}
.profile-loading img {
  width: 100%;
  margin-left: -5%;
  margin-top: 7%;
}
.profile-loading-emploee-table {
  width: 100%;
  text-align: center;
  overflow-y: auto;
}
.profile-loading-emploee-table img {  // все импотант т.к. свойства #company "сильнее"
  height: auto!important;
  width: 270px!important;
}
.copy-img {
  width: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.permissions-info-block {
  position: fixed;
  top: 50vh;
  left: 45vw;
  z-index: 95;
}
.permis-with-help-dialog {
  cursor: help;
}
.table-utils { 
  width: 100%;
  display: block;
  height: 100px;
  padding: 20px 0;
}
.table-utils-search-input {
  width: 300px;
  font-weight: 400;
  float: left;
}
.table-utils-button-delete {
  float: right;
}
.button-fill-balance {
  margin: 0;
}
.loading-update-company {
  width: 45px!important;
  height: 45px!important;
  position: absolute;
  margin: -10px 0 0 0!important;
}
</style>
